// @ts-nocheck
/*
 *  This file http-service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerErrorHandler } from './error-handler/server-error-handler.service';
import { ConfigurationService } from './configuration.service';
import { SnackbarAlertService } from './snackbar-alert.service';
import { TLoginTokenResponse } from '../models/common.models';

@Injectable()
export class HttpService  {
  updateSignalRAuthTokenSubject = new Subject<{token:TLoginTokenResponse,isLogout:boolean}>();
  isOnline:boolean;

  constructor(
    private _httpClient: HttpClient,
    private _serverError: ServerErrorHandler,
    public _config: ConfigurationService,
    public _snackBar:SnackbarAlertService
  ) {

  }


  getHeaders() {
    const authlogin = JSON.parse(localStorage.getItem('authlogin'));
    const accessToken = authlogin ? authlogin.access_token : '';
    let dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._config.domainCode;
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'domainCode': dc,
      'Authorization': 'Bearer ' + accessToken,
      'AccessMode': 'W'
    });

  }
  chatHeader() {
    const authlogin = JSON.parse(localStorage.getItem('authlogin'));
    const accessToken = authlogin ? authlogin.access_token : '';
    let dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._config.domainCode;
    return new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken,
    });

  }
  getHeadersIfCaptcha(captcha) {
    const authlogin = JSON.parse(localStorage.getItem('authlogin'));
    const accessToken = authlogin ? authlogin.access_token : '';
    const dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._config.domainCode;
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'domainCode': dc,
      'Authorization': 'Bearer ' + accessToken,
      'AccessMode': 'W',
      'g-Recaptcha-Response':captcha
    });

  }


  getHeadersUnauthorized() {
    let dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._config.domainCode
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'domainCode': dc,
      'AccessMode': 'W'
    });

  }

  getHeadersUnauthorizedWithAppId(apiKey, domainCode) {
    return new HttpHeaders({
      'domainCode': domainCode,
      'apiKey': apiKey,
      'AccessMode': 'W'
    });

  }
  getHeadersUnauthorizedWithAppIdIfCaptcha(apiKey, domainCode,captcha) {
    return new HttpHeaders({
      'domainCode': domainCode,
      'apiKey': apiKey,
      'AccessMode': 'W',
      'g-Recaptcha-Response':captcha
    });

  }

  getHeadersForFileUpload() {
    const authlogin = JSON.parse(localStorage.getItem('authlogin'));
    const accessToken = authlogin ? authlogin.access_token : '';
    let dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._config.domainCode

    return new HttpHeaders({
      'domainCode': dc,
      'Authorization': 'Bearer ' + accessToken,
      'AccessMode': 'W'
    });
  }

  public Post(url, param): Observable<any> {
    return this._httpClient.post(this._config.base_url + url, param, { headers: this.getHeaders() })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  public GetMicroservice(url): Observable<any> {
    return this._httpClient.get(this._config.microservice_base_url + url, { headers: this.chatHeader() })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }
  public PostMicroservice(url, param): Observable<any> {
    return this._httpClient.post(this._config.microservice_base_url + url, param, { headers: this.chatHeader() })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  public DeleteMicroservice(url): Observable<any> {
    return this._httpClient.delete(this._config.microservice_base_url + url, { headers: this.getHeaders() })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  public PostWithoutException(url, param): Observable<any> {
    return this._httpClient.post(this._config.base_url + url, param, { headers: this.getHeaders() })
      .pipe(
        catchError(err =>
          this.handleServerError(err)
        )
      );
  }

  handleServerError(err) {
    return throwError(err);
  }

  public Put(url, param): Observable<any> {
    return this._httpClient.put(this._config.base_url + url, param, { headers: this.getHeaders() })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  public Patch(url, param): Observable<any> {
    return this._httpClient.patch(this._config.base_url + url, param, { headers: this.getHeaders() })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  PutUnauthorizedWithAppId(url, appId, domainCode, param): Observable<any> {
    const headers = this.getHeadersUnauthorizedWithAppId(appId, domainCode);
    return this._httpClient.put(this._config.base_url + url, param, { headers: headers })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  PutUnauthorizedMethod(url, param): Observable<any> {
    const headers = this.getHeadersUnauthorized();
    return this._httpClient.put(this._config.base_url + url, param, { headers: headers })
    .pipe(
      catchError(err =>
        this._serverError.handleServerError(err)
      )
    );
  }
  public PostUnAuthorize(url, param): Observable<any> {
    const headers = this.getHeadersUnauthorized();
    return this._httpClient.post(this._config.base_url + url, param, { headers: headers })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err, 'isUnauthorize')
        )
      );
  }

  public PostUnauthorizedWithAppId(url, appId, domainCode, param,captcha?:string): Observable<any> {
    const headers = captcha ? this.getHeadersUnauthorizedWithAppIdIfCaptcha(appId, domainCode,captcha) : this.getHeadersUnauthorizedWithAppId(appId, domainCode);
    return this._httpClient.post(this._config.base_url + url, param, { headers: headers })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err, 'isUnauthorize')
        )
      );
  }

  public Get(url): Observable<any> {
    return this._httpClient.get(this._config.base_url + url, { headers: this.getHeaders() })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  public GetWithoutHeader(url): Observable<any> {
    return this._httpClient.get(this._config.base_url + url)
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }


  public GetLocal(url): Observable<any> {
    return this._httpClient.get(url).pipe(catchError(err => this._serverError.handleServerError(err)));
  }

  public Delete(url, param?): Observable<any> {
    return this._httpClient.delete(this._config.base_url + url, { headers: this.getHeaders() })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  public GetUnAuthorize(url, param?): Observable<any> {
    const headers = this.getHeadersUnauthorized();
    return this._httpClient.get(this._config.base_url + url, { headers: headers })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  public GetUnauthorizedWithAppId(url, apiKey, domainCode,captcha?:string): Observable<any> {
    const headers = captcha ? this.getHeadersUnauthorizedWithAppIdIfCaptcha(apiKey, domainCode,captcha): this.getHeadersUnauthorizedWithAppId(apiKey, domainCode);
    return this._httpClient.get(this._config.base_url + url, { headers: headers })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  public PostFile(url, param): Observable<any> {
    const headers = this.getHeadersForFileUpload();
    headers.delete('Accept');
    return this._httpClient.post(this._config.base_url + url, param,
      { headers: headers })
      .pipe(catchError(err => this._serverError.handleServerError(err)));
  }

  public PostFileUpload(url, param): Observable<any> {
    const headers = this.getHeadersForFileUpload();
    headers.delete('Accept');
    return this._httpClient.post(this._config.microservice_base_url + url, param,
      { headers: headers })
      .pipe(catchError(( err : HttpErrorResponse) => {
        if (err.status === 400 && err.url.includes('api/File/UploadUserFile')) {
          let allError = '';
          if (err.error?.File) {
            err.error?.File?.forEach(element => {
              allError = element + allError;
            });
          }
          if (err.error?.EmployeeCode) {
            err.error?.EmployeeCode?.forEach(element => {
              allError = element + allError;
            });
          }
          if (err.error?.FolderId) {
            err.error?.FolderId?.forEach(element => {
              allError = element + allError;
            });
          }
          this._snackBar.Alert(allError, '2');
          return throwError(err);
        } else {
          this._serverError.handleServerError(err);
        }
      }
      ));
  }

  public PostExternalFile(url, apiKey, domainCode, param): Observable<any> {
    const headers = this.getHeadersUnauthorizedWithAppId(apiKey, domainCode);
    headers.delete('Accept');
    return this._httpClient.post(this._config.base_url + url, param,
      { headers: headers })
      .pipe(catchError(err => this._serverError.handleServerError(err)));
  }

  public UploadFile(url, param): Observable<any> {
    const headers = this.getHeadersForFileUpload();
    headers.delete('Accept');
    return this._httpClient.post(this._config.base_url + url, param,
      { headers: headers, reportProgress: true, observe: 'events' })
      .pipe(catchError(err => this._serverError.handleServerError(err)));
  }
  // forgot Password
  public PostUnAuthorizeForForgot(url, param, isAlertReq = true,captcha?:string): Observable<any> {
    const headers = new HttpHeaders({
      'g-Recaptcha-Response': captcha
    });
    return captcha ? this._httpClient.post(this._config.base_url + url, param,{headers}) : this._httpClient.post(this._config.base_url + url, param)
      .pipe(
        catchError(err => {
          if (isAlertReq) {
            this._serverError.handleServerError(err, 'isUnauthorize');
          }
          return throwError(err);
        })
      );
  }




  public stripePost(url, data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'AccessMode': 'W'
    });
    return this._httpClient.post(this._config.stripe_base_url + url, data,
      { headers: headers })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }


  // ...........stripe Get Api function.................
  public stripeGet(url): Observable<any> {
    const headers = new HttpHeaders({
      'AccessMode': 'W'
    });
    return this._httpClient.get(this._config.stripe_base_url + url, { headers: headers })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }


  public stripePut(url, param): Observable<any> {
    return this._httpClient.put(this._config.stripe_base_url + url, param, {
      headers: new HttpHeaders({
        'AccessMode': 'W'
      })
    })
      .pipe(
        catchError(err =>
          this._serverError.handleServerError(err)
        )
      );
  }

  public getIpAddress() {
    const base = this._config.base_url.split(':');
    return this._httpClient.get(`${base[0]}://api.ipify.org/?format=json`);
  }
}
