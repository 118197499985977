// @ts-nocheck
/*
 *  This file common.models.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { HttpEvent } from "@angular/common/http";
import { FormControl } from "@angular/forms";

export const PAGE_SIZE = 15;

export class PaginationModel {
  constructor(
    public pageNumber = 1,
    public pageSize = PAGE_SIZE
  ) { }
}

export const booleanFlag = {
  true: true,
  false: false
};

export interface EmployeeSearchModel {
  EmployeeCode: string;
  EmployeeName: string;
  Name: string;
}

export class LinksModel {
  constructor(
    public href?: string,
    public relation?: string,
    public method?: string
  ) { }
}


export interface RequestWorkflowAndStatusModel {
  isInvalid: boolean;
  reason: string;
}

export type RequestSourceType = 'E' | 'T' | 'A';

export const monthDaysTill28 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

export const monthlyDaysList = [
  { key: 1, value: '1' },
  { key: 2, value: '2' },
  { key: 3, value: '3' },
  { key: 4, value: '4' },
  { key: 5, value: '5' },
  { key: 6, value: '6' },
  { key: 7, value: '7' },
  { key: 8, value: '8' },
  { key: 9, value: '9' },
  { key: 10, value: '10' },
  { key: 11, value: '11' },
  { key: 12, value: '12' },
  { key: 13, value: '13' },
  { key: 14, value: '14' },
  { key: 15, value: '15' },
  { key: 16, value: '16' },
  { key: 17, value: '17' },
  { key: 18, value: '18' },
  { key: 19, value: '19' },
  { key: 20, value: '20' },
  { key: 21, value: '21' },
  { key: 22, value: '22' },
  { key: 23, value: '23' },
  { key: 24, value: '24' },
  { key: 25, value: '25' },
  { key: 26, value: '26' },
  { key: 27, value: '27' },
  { key: 28, value: '28' },
  { key: 31, value: 'Last day' },
];

export const monthlyDaysListFromTill31 = [
  { key: 10, value: '10' },
  { key: 11, value: '11' },
  { key: 12, value: '12' },
  { key: 13, value: '13' },
  { key: 14, value: '14' },
  { key: 15, value: '15' },
  { key: 16, value: '16' },
  { key: 17, value: '17' },
  { key: 18, value: '18' },
  { key: 19, value: '19' },
  { key: 20, value: '20' },
  { key: 21, value: '21' },
  { key: 22, value: '22' },
  { key: 23, value: '23' },
  { key: 24, value: '24' },
  { key: 25, value: '25' },
  { key: 26, value: '26' },
  { key: 27, value: '27' },
  { key: 28, value: '28' },
  { key: 31, value: 'Last day' },
  { key: 0, value: 'First day of next month' },
];
export const monthlyDaysListTill28Days = [
  { key: 1, value: '1' },
  { key: 2, value: '2' },
  { key: 3, value: '3' },
  { key: 4, value: '4' },
  { key: 5, value: '5' },
  { key: 6, value: '6' },
  { key: 7, value: '7' },
  { key: 8, value: '8' },
  { key: 9, value: '9' },
  { key: 10, value: '10' },
  { key: 11, value: '11' },
  { key: 12, value: '12' },
  { key: 13, value: '13' },
  { key: 14, value: '14' },
  { key: 15, value: '15' },
  { key: 16, value: '16' },
  { key: 17, value: '17' },
  { key: 18, value: '18' },
  { key: 19, value: '19' },
  { key: 20, value: '20' },
  { key: 21, value: '21' },
  { key: 22, value: '22' },
  { key: 23, value: '23' },
  { key: 24, value: '24' },
  { key: 25, value: '25' },
  { key: 26, value: '26' },
  { key: 27, value: '27' },
  { key: 28, value: '28' }
];


export interface LogonUserDetailModel {
  employeeId: number,
  employeeCode: string,
  salutation: number,
  salutationName: string,
  employeePhoto: string,
  employeeName: string,
  businessUnit: string,
  department: string,
  subDepartment: string,
  grade: string,
  designation: string,
  branch: string,
  subBranch: string,
  level: string,
  company: string,
  regionCode: string,
  region: string,
  imageVirtualPath: string,
  signatureFileName: string,
  signatureFilePath: string,
  signatureText: string,
  firstName: string,
  middleName: string,
  lastName: string,
  companyCode: string,
  businessUnitCode: string,
  designationCode: string,
  departmentCode: string,
  subDepartmentCode: string,
  gradeCode: string,
  levelCode: string,
  branchCode: string,
  subBranchCode: string,
  maritalId: string,
  personalEmail: string,
  officialEmail: string,
  staffId: string,
  gender: number,
  finalized: string,
  suspended: string,
  employeeStatusId: number,
  employeeStatus: string,
  employeeTypeId: number,
  employeeType: string,
  thumbnailFileName: string,
  uploadedFileName: string,
  mobileNo: string,
  isSeparationPending: string,
  dateOfBirth: Date | string,
  dateOfJoining: Date | string,
  dateOfMarriage: Date | string,
  domainCode: string,
  isEmailBlocked : boolean,
  identifier: {
    organizationKeyOne: number,
    organizationKeyTwo: number,
  },
  chatFileVirtualBasePath: string,
  kommunicateAppId: string,
  companySocialHandle: Array< {
    handleName: string,
    handleLink: string,
    handleImage: string,
    companyCode: string
  }>,
  firestoreDocumentId: string
  companyLogoPath? : string
}

export interface TokenInfoModel {
  unique_name: string;
  domainCode: string;
  companyDomainCode: string;
  loginUser: string;
  UserLogOnId: string;
  Password: string;
  IsFirstTimeLogOn: string;
  UserLanguage: string;
  UserTimeZone: string;
  LogOnId: string;
  UserName: string;
  ClientBasePath: string;
  ChatAppId: string;
  GatewayCustomerCode: string;
  Uid: string;
  LogOnType: string;
  IsAuthenticate: string;
  Message: string;
  DomainCode: string;
  CompanyDomainCode: string;
  iss: string;
  aud: string;
  exp: string;
  nbf: string;
}

export interface IdentifiersModel {
  organizationKeyOne: number;
  organizationKeyTwo: number;
}

export interface EmployeeModel {
  employeeId: number;
  employeeCode: string;
  salutationName: string;
  employeePhoto: string;
  employeeName: string;
  businessUnit: string;
  department: string;
  subDepartment: string;
  grade: string;
  designation: string;
  branch: string;
  subBranch: string;
  level: string;
  company: string;
  imageVirtualPath: string;
  firstName: string;
  middleName: string;
  lastName: string;
  personalEmail: string;
  officialEmail: string;
  employeeStatus: string;
  employeeType: string;
  thumbnailFileName: string;
  uploadedFileName: string;
}


export const monthNames = {
  short: {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  }
};


export const TaskStatus = {
  PENDING: 'PENDING',
  EXTENDED: 'EXTENDED',
  CONFIRMED: 'CONFIRMED',
  COMPLETED: 'COMPLETED',
  TERMINATED: 'TERMINATED',
  REJECTED: 'REJECTED',
};

export const monthDaysTill31 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
   18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];


export interface RecentImport {
  clientQueueId: string,
  importName: string,
  importType: string,
  domainCode: string,
  importOutputFile: string,
  importStatus: number,
  createTime: string,
  startTime: string,
  endTime: string,
  totalRecords: number,
  successCount: number,
  errorCount: number,
  runByAzureFunction: boolean,
  validatedFilePath: string,
  processedRecordCount: number,
  statusMessage: string
}

export type TLoginTokenResponse = {
  access_token: string,
  token_type: string,
  expires_in: number,
  refresh_token:string,
  userName: string,
  client_id: string,
  ".issued":string|Date,
  ".expires":string|Date,
}

export enum EMessageStatus {
  SUCCESS = "0",
  FAILED = "3",
}

export type TMessageResponse = {
    message: string,
    validationType: EMessageStatus,
    messageCode: null
}


export type HronePrimeNgFileUploadEvent<T=any> = {
  originalEvent:HttpEvent<T>
  files:File[]
}
export type HronePrimeNgFileRemoveEvent<T=any> = {
  originalEvent:HttpEvent<T>
  file:File
}

export type TKeyValue<K=number,V=string> = {key:K,value:V};
export type HroneModelToTypeFormGroupItems<K> = {[id in keyof K]?:FormControl<K[id]>};
export type TypeToRequiredFormGroup<K> = {[id in keyof K]:FormControl<K[id]>};


export type TFileDownloadVirtualUrl = {
  folderName:      string;
  fileDbName:      string;
  fileVirtualPath: string;
  domainCode:      string;
  fileName:        string;
  requestSource:   string;
}
