/*
*  This file environment.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
*  CopyrightYear: 2023
*  (C) 2015-2023. All Right reserved. Uneecops Workplace Solution PVT. LTD.
*
*/

import { workFlowIds } from '../app/global/constants/workflow.constant';
import { formIds } from '../app/global/constants/forms.contant';
import { ouIds } from '../app/global/constants/organization-units.constant';
import { moduleIds } from '../app/global/constants/modules.contant';

const placeholders = {
  ph1: '/assets/images/image-placeholder.jpg',
  ph2: './assets/images/employee.png',
  ph3: './assets/images/no-image.png'
};

export const environment = {
  firebase: {
    projectId: 'hronev5-pushnotification-prod',
    appId: '1:848700737335:web:3967e516e688eabcc57cb2',
    databaseURL: 'https://hronev5-pushnotification-prod.firebaseio.com',
    storageBucket: 'hronev5-pushnotification-prod.appspot.com',
    locationId: 'asia-south1',
    apiKey: 'AIzaSyAdxbG2m9sAY8Ct-sUO7iuTTORaN_in4qI',
    authDomain: 'hronev5-pushnotification-prod.firebaseapp.com',
    messagingSenderId: '848700737335',
    measurementId: 'G-8T7RXEPLZZ',
  },
  firebaseChat: {
    apiKey: "AIzaSyBgZD40av4g7ato94eJC6RT04-Y6UsT0Gg",
    authDomain: "hrone-firebase-dev.firebaseapp.com",
    projectId: "hrone-firebase-dev",
    storageBucket: "hrone-firebase-dev.appspot.com",
    messagingSenderId: "399184642875",
    appId: "1:399184642875:web:c0fd3cc3a87b6a20afed1e"
    },
  production: false,
  envName: 'production',
  domainCode: 'dgn5',
  workFlowIds: workFlowIds,
  formIds: formIds,
  ouIds: ouIds,
  moduleIds: moduleIds,
  placeholders: placeholders,
  placeholder_xs: '/assets/images/image-placeholder.jpg',
  placeholder_sm: '/assets/images/image-placeholder.jpg',
  placeholder_md: '/assets/images/image-placeholder.jpg',
  apiVersion: 'v1',
  typingEvent: [91, 8, 9],
  users: {
    skip: 0,
    limit: 10
  },
  messages: {
    skip: 0,
    limit: 15
  }
  ,

  base_url: "https://app.dev.hrone.work",
  base_auth_url: "https://gateway.dev.hrone.work",
  stripe_base_url: "https://hronedev-subscription.azurewebsites.net",
  isDeployOnGT: false,
  power_bi_url: "https://powerbi.dev.hrone.work",
  microservice_base_url: "https://gateway.dev.hrone.work",
  broadcastUrl: "https://broadcast.dev.hrone.work/hubs/presence",
  hropsDeploymentEnable: true,
  isKBEnable: true,
  isGDPRPointerEnabled: true,
  isChatEnabled: true,
  oneAIGPTUrl: "https://oneai.dev.hrone.work/login",
  isVariablePaymentPlanEnable : true,
  apiDocumentUrl:"https://devhroneapimstatic.z29.web.core.windows.net"
};



/*
* In development mode, to ignore zone related error stack frames such as
* `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
* import the following file, but please comment it out in production mode
* because it will have performance impact when throw error
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
