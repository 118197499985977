/*
 *  This file enterprise-wall.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2023
 *  (C) 2015-2023. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Routes } from "@angular/router";
import { PaginationModel } from "src/app/global/models/common.models";

export class IMarkAttendanceResponse {

  constructor(
    public checkInOutPolicyId: number = 0,
    public attendancePolicyId: number = 0,
    public enableMobileCheckIn: boolean = true,
    public isPhotoRequired: boolean = true,
    public numberOfPhotos: number = 0,
    public allowGeoFencing: boolean = true,
    public geoFencingLocationRadius: number = 0,
    public isLocationRequired: boolean = true,
    public enableWebCheckIn: boolean = true,
    public lastLogOnPunch: Date = new Date(),
    public punchSource: string = '',
    public loading: boolean = true,
    public whenLastPuchedText: string = 'Today',
  ) { }

}

export const markAttendancePayload: MarkAttendancePayload = {
  requestType: 'A',
  employeeId: 0,
  latitude: '',
  longitude: '',
  geoAccuracy: '',
  geoLocation: '',
  punchTime: '',
  remarks: '',
  uploadedPhotoOneName: '',
  uploadedPhotoOnePath: '',
  uploadedPhotoTwoName: '',
  uploadedPhotoTwoPath: '',
  attendanceSource: 'W',
  attendanceType: 'Online',
  officeId: null,
  workplaceId: null,
  cityCode: null
};
type MarkAttendancePayload = {
  requestType: string;
  employeeId: number;
  latitude: string;
  longitude: string;
  geoAccuracy: string;
  geoLocation: string;
  punchTime: string;
  remarks: string;
  uploadedPhotoOneName: string;
  uploadedPhotoOnePath: string;
  uploadedPhotoTwoName: string;
  uploadedPhotoTwoPath: string;
  attendanceSource: string;
  attendanceType: string;
  officeId?: number | null;
  workplaceId?: number | null;
  cityCode?: number | null;
};

export interface IEngageCard {
  id?:number,
  text: string,
  iconsrc: string,
  src?: string,
  hasRight: boolean,
  callback: <T>(ref: T,payload?:TEditPayload) => void,
  ref: string,
  backgroundColor: 'transparent';
  editReference?:"HRPost" | "Points" | "Badge"

}
export type TReqDataBadgeSubmitPayload = {
  badgeId: number;
  employeeId: number;
  remarks: string;
  activeStatus: number;
  customReason?: any;
  customRewardPoint?: any;
  uploadedFileName?: any;
  uploadedFilePath?: any;
  letterTemplateId?: any;
}
export interface IBadgeLimitResponse {
  current: number;
  maxLimit: number;
  isApiCalled?: boolean
}
export interface IPointsLimitResponse {
  totalPoint: number;
  usedPoints: number;
  givenPoints: number;
  maxAllowedPoints: number;
  isApiCalled: boolean
}
export interface IEmployeeDetail {
  employeeId: number;
  employeeCode: string;
  salutation: number;
  salutationName: string;
  employeePhoto: string;
  employeeName: string;
  businessUnit: string;
  department: string;
  subDepartment: string;
  grade: string;
  designation: string;
  branch: string;
  subBranch: string;
  level: string;
  company: string;
  regionCode: string;
  region: string;
  imageVirtualPath: string;
  signatureFileName: string;
  signatureFilePath: string;
  signatureText: string;
  firstName: string;
  middleName: string;
  lastName: string;
  companyCode: string;
  businessUnitCode: string;
  designationCode: string;
  departmentCode: string;
  subDepartmentCode: string;
  gradeCode: string;
  levelCode: string;
  branchCode: string;
  subBranchCode: string;
  maritalId: string;
  personalEmail: string;
  officialEmail: string;
  staffId: string;
  gender: number;
  finalized: string;
  suspended: string;
  employeeStatusId: number;
  employeeStatus: string;
  employeeTypeId: number;
  employeeType: string;
  thumbnailFileName: string;
  uploadedFileName: string;
  mobileNo: string;
  isSeparationPending: string;
  firestoreDocumentId: string;
}
export interface IHroneGenericResponse {
  message: string,
  validationType: string,
  messageCode: string
}
export interface IFeedCommentCheeredResponse {
  imageVirtualPath: string;
  employeeName: string;
  employeeCode: string;
  date: Date | string;
  emojiType : number;
}


export enum WishTypeEnum {
  BIRTHDAY = 1,
  JOININGANNIVERSARY = 2,
  WEDDINGANNIVERSARY = 3,
  BADGE = 4,
  ENDORSEMENT = 5,
  POINTS = 6,
}
export interface ILeaveDetailItem {
  leaveCode: string;
  leaveFromDate: Date | string;
  leaveToDate: Date | string;
  leaveNoOfDays: number;
  fromDateHalfDayType: string;
  toDateHalfDayType: string;
  isOnLeave: boolean;
  isSameDay: boolean;
  employeeDetails: IEmployeeDetail;
}
export type TTabType = { type: TWallOfFameType, icon: 'stars' | 'thumbs-up' | 'gift-card' };


export type RequestKeyType = 'summary' | 'all'

export interface IWishlistStorage {
  employeeId: number,
  wishesTypeId: number,
  date: number,
  myId: number,
}
export type attendanceStatusType = "P" | "A" | "-";
export interface ICalendarResponse {
  employeeId: number,
  shiftId: number,
  timeIn: string,
  timeout: string,
  presentStatus: string,
  shiftColor: string,
  firstHalfStatusColor: string,
  secondHalfStatusColor: string,
  shiftStartDate: Date,
  shiftEndDate: Date,
  oddEvenIndication: string,
  oddEvenCount: number,
  isShiftVisible: boolean,
  showAttendanceStatusOnCalendar: boolean,
  showWorkingHoursOnCalendar: boolean,
  calculatedWorkingHours: string,
  compOffStatus: string,
  expireDate: Date,
  shiftStartTime: string,
  shiftEndTime: string,
  shiftCode: string,
  attendanceDate: Date,
  updatedFirstHalfStatus: attendanceStatusType,
  updatedSecondHalfStatus: attendanceStatusType,
}

export interface IWishActionPayload {
  wishesTypeId: WishTypeEnum,
  wishesType: string,
  wishesMessage: string,
  employeeId: number
}

export class SocialWallLeaveWidgetModel {
  constructor(
    public fromDate: string = '',
    public toDate: string = '',
    public searchValue: string = '',
    public pagination: PaginationModel = new PaginationModel(1, 5),
    public leaveIds?: string
  ) { }
}
export interface IImageAndVideoList {
  feedId: number;
  path: string;
  imageOrVideo: "I" | "V";
  reference: string;
  compressImagePath: string;
}

export interface IWelcomJoineeAnswerQuestion {
  feedId: number;
  questions: string;
  answers: string;
}
export type widgetModeType = "O" | "E";
export type TWidgetSequnceWidgetType = "CBT" | "SDA" | "TAC" | "CVLS" | "WOFE" | "WOFR" | "WOFB" | "INB" | "YAT" | "RFL" | "WISH" | "PRC" | "TLW" | "INTV" | "GOAL" | "OGL" | "CON" | "TLB" | "OPS" | "WOF" | "TOT" | "UE";

export interface IWidgetSequnceResponse {
  hidden: boolean;
  widgetName: string;
  widgetId: number;
  widgetMode: widgetModeType;
  sequenceNo: number;
  isActive: boolean;
  userInteracted?: boolean;
  widgetType: TWidgetSequnceWidgetType;
}

export interface IFeedResponse {
  markForHightlight?: boolean;
  imageAndVideoList: Array<IImageAndVideoList>,
  submitted: number,
  isShowDeleteButton: number,
  socialProfile: IEmployeeDetail & { questionAnswerList: Array<IWelcomJoineeAnswerQuestion> },
  feedId: number,
  employeeId: number,
  senderId: number,
  cheersCount: number,
  commentsCount: number,
  reference: keyof typeof EFeedCardType,
  referenceTypeId: number,
  remarks: string,
  employeeName: string,
  senderName: string,
  employeeImage: string,
  senderImage: string,
  sendDate: Date,
  feedImage: string,
  points: number,
  senderUploadedImage: string,
  employeeUploadedImage: string,
  customStatus: boolean,
  badgeName: string,
  featurePost: boolean,
  isAttachment: boolean,
  isCommentRestricted: boolean;
  socialNetworkDetail?: Array<SocialNetworkDetailItem>
  isEdited:boolean,
  emojiType:number,
  reactionClass : string | undefined,
  cheerText : string | undefined,
  additionalHRPostUrl : string | undefined

}

export type IFeedResponseExtended = IFeedResponse & { skill?: string, headerImage?: string, color?: string }
export interface SocialNetworkDetailItem {
  employeeId: number,
  socialNetworkDetail: Array<SocialNetworkItem>
}
export interface SocialNetworkItem {
  socialNetworkId: number;
  socialNetworkName: string;
  socialNetworkLink: string;
  socialNetworkFileName: string;
  socialNetworkFilePath: string;
}
export interface IDownloadAttachement {
  uploadedFileName: string;
  uploadedFilePath: string;
  virtualFilePath: string;
}
export const SOCIAL_URLS = {
  attendance: '/api/timeoffice/attendance/Daywise/',
  getAllSkills: '/api/workforce/setup/Skills/Details',
  calendarOnWall: "/api/timeoffice/attendance/Calendar",
  downloadAttachedBageFile: "/api/Engage/Badge/DownloadFile/",
  downloadCustomAttachedBageFile: "/api/Engage/Badge/DownloadCustomFile/",
  wishes: '/api/core/Dashboard/Wishes/All/',
  wishAction: '/api/core/Dashboard/Wishes',
  perWishes: '/api/core/Dashboard/Wishes/User/',
  wishedMe: '/api/core/Dashboard/Personal/Wishes',
  taskCount: '/api/core/Dashboard/InboxSummary',
  notiCount: '/api/core/Dashboard/Notification/count',
  notification: '/api/core/Dashboard/DashboardAlert/',
  clearNoti: '/api/core/Dashboard/DashboardAlert',
  widgets: '/api/core/Dashboard/Widgets',
  badgesDd: '/api/Engage/Badge/Setting/1/false',
  badgeList: `/api/Engage/Badge/Setting/ForAll/1`,
  skillsList: `/api/workforce/PersonalSkills/PersonalSkills/`,
  badgeSubmit: '/api/Engage/Badge/BadgesForEmployee',
  badgeLimit: '/api/Engage/Badge/BadgeLimit/',
  feeds: '/api/Engage/Badge/Feed',
  hidePost: '/api/Engage/Badge/Admin/Hide/',
  feedCount: '/api/Engage/Badge/FeedCount',
  feedComments: "/api/Engage/Badge/FeedComments",
  feedCommentsList: "/api/Engage/Badge/FeedCommentDetail",
  feedCommentReaction: "/api/Engage/Badge/CommentReaction",
  feedCommentDelete: "/api/Engage/Badge/Delete/FeedComments/",
  commentDeleteByAdmin: "/api/Engage/Badge/Admin/Comments/",
  cheer: '/api/Engage/Badge/Cheers',
  pointsSubmit: '/api/Engage/RewardPoints/EmployeeRewardPoints',
  pointLimit: '/api/Engage/RewardPoints/GivenPoints/',
  profile: '/api/core/Dashboard/Profile/Completion',
  scheduleOne: '/api/pms/oneonone/request',
  initiative: '/api/pms/initiative/Request',
  hrPost: '/api/Engage/HRPost/HRPost',
  cheeredBy: '/api/Engage/Badge/FeedCheersDetails?feedId=',
  cheeredCommentBy: '/api/Engage/Badge/CommentReactionDetails/',
  deleteFeed: '/api/Engage/Badge/Inactive/Feed/',
  leaveWidget: '/api/shortcut/widget/LeaveDetails',
  shortcutWidget: '/api/shortcut/widget',
  deleteShortcut: '/api/shortcut/widget/',
  leaveBalaceWidget: '/api/timeoffice/Employee/leaveBalance/EnterpriseWall',
  jobOpening: `/api/recruitment/position/JobOpeningForEmployeeProfile`,
  plannedLeaveCount: `/api/timeoffice/Leave/Planned/Count/Publicaly`,
  leaveWidgetPlanned: `/api/timeoffice/Leave/Planned/Publicaly`,
  fundraiser: "/api/engage/covid/fundraiser/Master/Details",
  markAttendance: "/api/timeoffice/mobile/checkin/Attendance/Request",
  unFeatureHrPost: "/api/Engage/Badge/Feature/HrPost/",
  widgetSequence: "/api/SocialWall/v2/Widget",
  feedBadgeCount: "/api/SocialWall/v2/FeedCount",
  contest: "/api/SocialWall/v2/Contest",
  orgWidgetHeader: "/api/socialwall/v2/organizationalwidgets",
  coreValues: '/api/engage/coreValue/Enterprise',
  wallOfFame: '/api/Engage/WallOfFame/RankDetail',
  badgeLinkedinDetails: '/api/Engage/linkedin/Badge',
  todoList: '/api/project/task/widget'
};

export type feedcardtype = 'HRPost' | 'Points' | 'Badge' | 'Endorsement' | 'SocialProfile' | 'GoalAchievement' | "";
export const categoryList: Array<{ id: feedcardtype, text: string }> = [
  {
    id: 'Badge',
    text: "Badges"
  },
  {
    id: 'Endorsement',
    text: "Endorsement"
  },
  {
    id: 'Points',
    text: "Rewards"
  },
  {
    id: "HRPost",
    text: "HR post"
  },
  {
    id: "SocialProfile",
    text: "New join intro"
  },
  {
    id: "GoalAchievement",
    text: "Achievement"
  }
];
export enum EFeedCardType {
  HRPost = "HR post",
  Points = "Rewards",
  Badge = "Badges",
  Endorsement = "Endorsement",
  SocialProfile = "New join intro",
  GoalAchievement = "GoalAchievement"
}

export const widgetRightsMapEnum: Record<string, TWidgetSequnceWidgetType> = {
  "Team Leave": 'TLW',
  "Wish": 'WISH',
  "Core Values": 'CVLS',
  "Referrals": 'RFL',
  "Organisation Leave": 'OGL',
  "Calendar": 'TAC',
  "Goal": 'GOAL',
  "Inbox": 'INB',
  "Initiative": 'INTV',
  "Leave balance": 'TLB',
  "Open position": 'OPS',
  "Yesterday attendance": 'YAT',
  "Contest": "CON",
  "Continuous feedback": 'SDA',
  "Profile completion": "PRC",
  "WOF Badge": "WOFB", // WOF -> Wall Of Fame
  "WOF Endrosement": "WOFE",
  "WOF Reward": "WOFR",
  "WOF": "WOF",
  "TAST OF TODAY": "TOT",
  "UPCOMING EVENTS":"UE"
}
export interface ILeaveResponse {
  leaveDetails: Array<ILeaveDetailItem>,
  totalCount: number
}
export interface IOrgLeaveResponse {
  leaves: Array<ILeaveDetailItem>,
  count: number
}

export type TSocialWallContest = {
  contestId: number,
  contestName: string,
  contestType: string,
  contestIconName: string,
  contestIconImage: string,
  contestStartDate: Date,
  contestEndDate: Date,
}
export interface IContestResponse {
  contest: TSocialWallContest,
  result: ContestResult,
  upcomingMatch: Array<ContestMatch>
}

export interface ContestResult {
  totalWin: number,
  totalLoss: number,
  totalDraw: number,
  lastUpdated: Date,
  rank: number,
  overallRank: number,
  shareImage: string
}
interface ContestMatch {

  teamOne: string,
  teamOneName: string,
  teamTwo: string,
  teamTwoName: string,
  competitionDate: Date,
  startDate: Date,
  endDate: Date
}
export type TCoreValueItem =
  {
    policyCoreValueId: number,
    coreValueName: string,
    coreValueDescription: string,
    coreValueFileName: string,
    coreValueFilePath: string,
    badgeId: string,
    fileVirtualPath: string
  }
export interface IPersonalWishCard {
  src: string;
  srcAlt: string;
  headerText: string;
  subText: string;
  wishText: string;
  subSrc: string;
  subSrcAlt: string;
  wishedWhen: string;
}
export const WishCardState: { [id: number]: IPersonalWishCard } = {
  1: {
    src: "assets/images/socialwall/Birthday.gif",
    srcAlt: "Birthday Icon",
    headerText: "Happy Birthday !",
    subText: " people wished you",
    wishText: "We wish you a day full of happiness and a year filled with joy. Enjoy your special day!",
    subSrc: "assets/images/socialwall/people.svg",
    subSrcAlt: "People Icon",
    wishedWhen: "Today",
  },
  2: {
    src: "assets/images/socialwall/anniversaryGif.gif",
    srcAlt: "Work Icon",
    headerText: "Happy Joining Anniversary !",
    subText: " people wished you",
    wishText: "Your dedication, hard work, and contributions have played an integral role in our success, here's to many more years of shared achievements and memorable moments!",
    subSrc: "assets/images/socialwall/people.svg",
    subSrcAlt: "People Icon",
    wishedWhen: "Today",
  },
  3: {
    src: "assets/images/socialwall/anniversaryGif.gif",
    srcAlt: "Engagement Ring",
    headerText: "Happy Wedding Anniversary !",
    subText: " people wished you",
    wishText: "We wish you a day full of happiness and a year filled with joy. Enjoy your special day!",
    subSrc: "assets/images/socialwall/people.svg",
    subSrcAlt: "People Icon",
    wishedWhen: "Today",
  },
  4: {
    src: "assets/images/socialwall/badge_star.svg",
    srcAlt: "Engagement Ring",
    headerText: "Received a badge",
    subText: "",
    wishText: "",
    subSrc: "",
    subSrcAlt: "",
    wishedWhen: "Today",
  },
  5: {
    src: "assets/images/socialwall/endrosement_thums_up.svg",
    srcAlt: "Thumbs Up",
    headerText: "Received endorsement",
    subText: "",
    wishText: "",
    subSrc: "",
    subSrcAlt: "",
    wishedWhen: "Today",
  },
  6: {
    src: "assets/images/socialwall/points_credit_card.svg",
    srcAlt: "Credit Card",
    headerText: "Received reward point",
    subText: "",
    wishText: "",
    subSrc: "",
    subSrcAlt: "",
    wishedWhen: "Today",
  },

}

export type TFeedCountResponse = {
  feedCount: number,
  badgeCount: number,
  endorseCount: number,
  rewardPoints: number,
  badgeReceivedDate: Date | string,
  rewardReceivedDate: Date | string,
  endorseReceivedDate: Date | string,

}
export class FeedPayloadState {

  constructor(
    public reference: feedcardtype = "",
    public sortOnUpdate: boolean = false,
    public postFor: number = 0,
    public postBy: number = 0,
    public pagination: PaginationModel = new PaginationModel(1, 10),
    public feedId?: number
  ) { }
}

export interface IFeedComment {
  employeeId: number;
  commentId: number;
  feedId: number;
  comments: string;
  reactionCount: number;
  employeeCode: string;
  employeeName: string;
  imageVirtualPath: string;
  date: Date;
  showDeleteButton: boolean;
  reactionSubmitted: boolean;
  parsedDate: number;
  organizationKeyOne?: string;
  organizationKeyTwo?: string;
}

export const ENTERPIRSE_SEARCH_AS_CHILD : Routes = [
  {
    path:'search',loadChildren:()=>import('src/app/main/enterprise-search/enterprise-search.module').then(m=>m.EnterpriseSearchModule)
  }
];


export type TEmployeeCardResponse = {
  employeeId: number,
  isPrivateProfile: boolean,
  employeeFullName: string,
  employeeCode: string,
  email: string,
  mobileNo: string,
  lastLogOn: string | Date,
  identifierOne: string,
  identifierTwo: string,
  imagePath: string
}

export type TEmployeeCard = TEmployeeCardResponse & { lastLogOnParsed: number };
export const THEME_LIST: Array<{ [key: string]: string }> = [
  { lightTheme: '#02563D' },
  { steelBlueTheme: '#237DD1' },
  { oxfordBlueTheme: '#1D2435' },
  { ultraVioletTheme: '#645788' },
  { purpureusTheme: '#AF42AE' },
  { cyanGreenTheme: '#12C4AC' },
  { crayolaTheme: '#e6793b' }

];

export type TUserThemeInStorage = { default: number, user: number, hr: number };


export type TSettingWorkPlanPolicy = {
  policyId: number;
  isEditShift: boolean;
  isEditWorkMode: boolean;
  isEditOffice: boolean;
  isEditLocation: boolean;
  workTypeId: number;
}

export type TSettingExtensions = {
  settingName: string;
  isActive: boolean;
  controlType: string;
  multipleUpdate: boolean;
  controlDataType: string;
  controlDataTypeSize: number;
  generalSettingId: number;
  status: string;
}





export interface TNotification {
  routeId: number;
  route: string;
  deepLink: string;
  routeReferenceId: number;
  alertMessage: string;
  thumbnailFileName: string;
  alertDate: string;
  imageVirtualPath: string;
  fullName: string;
  alertMessageId: number;
}


export type TWallOfFamePayload = {
  logOnUserCode?: string;
  domainCode?: string;
  logOnUserId?: number;
  logOnUserName?: string;
  currentUserLogOnType?: string;
  enterpriseCode?: string;
  companyCode?: string;
  companyName?: string;
  businessUnitCode?: string;
  departmentCode?: string;
  departmentName?: string;
  subDepartmentCode?: string;
  gradeCode?: string;
  designationCode?: string;
  designationName?: string;
  levelCode?: string;
  branchCode?: string;
  subBranchCode?: string;
  regionCode?: string;
  employeeCode?: number;
  employeeName?: string;
  employeeStatus?: string;
  employeeType?: string;
  employeeId?: number;
  personalEmail?: string;
  officialEmail?: string;
  employeeSearchId?: number | string;
  isIncludeUpcomingEmployee?: boolean;
  isExternalEmployee?: number;
  reportingManagerEmployeeId?: number;
  functionalManagerEmployeeId?: number;
  types: TWallOfFameType;
  fromDate: Date;
  toDate: Date;
  filterTypes?: number | "";
  pagination?: PaginationModel;
}

export type TWallOfFameType = "B" | "E" | "R";
export enum EWallOfFameType {
  BADGE = "B",
  ENDORSEMENT = "E",
  REWARD = "R",
}
export type TWallOfFameResponseItem = {
  rank: number;
  employeeName: string;
  profilePicture: string;
  totalCount: number;
  identifier1: string;
  identifier2: string;
  employeeId: number;
}

export type TBageListResponse = {
  badgesPerMonth: number;
  badgeList: TBadgeList[];
}

export type TBadgeList = {
  color: string;
  imageId: string;
  badgeId: number | '0';
  badgeName: string;
  activeStatus: boolean;
  imagePath: string;
  customStatus: boolean;
  frequencyType: FrequencyType;
  isCustomBadgePublic: boolean;
  numberOfCustomBadges: number;
  badgesFrequencyTypeId: number;
  isCustomBadgeRestrictForEmployee: boolean;
  employeeFrequencyTypeId: number | null;
  isMandatoryReason: boolean;
  isMandatoryAttachment: boolean;
  isLinkedReward: boolean;
  rewardAmount: number;
  isRewardShowOnWall: boolean;
  letterTemplateId: number | null;
  badgesFrequencyType:string;
}
export type TSelectedBadge =  {
  imagePath: string;
  customStatus: boolean;
  badgeName: string;
  color: string;
  badgesFrequencyType: string;
  rewardAmount: number;
  isRewardShowOnWall: boolean;
};
enum FrequencyType {
  Month = "Month",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
}


export type TWorkForcePersonalSkillsResponse = {
  employeeOrganizationDetails: EmployeeOrganizationDetail[];
  employeePersonalDetails: EmployeePersonalDetail[];
}

type EmployeeOrganizationDetail = {
  skillId: number | '0';
  proficiencyId: number;
  skillName: string;
  proficiencyName: string;
  isPrimary: boolean;
}

type EmployeePersonalDetail = EmployeeOrganizationDetail & {
  personalSkillId: number;
  documentFileName: string;
  uploadedFileName: string;
  fileVirtualPath: string;
  skillType: string;
}

export type TSetupSkillsDetails = {
  skillMasterId: number | "0";
  skillMasterName: string;
  activeStatus: boolean;
}

export type TGeneralEngageSettingResponse = {
  settingId: number;
  unit: number;
  wallApplicability: boolean;
  currencyCode: string;
  valuePerUnit: number;
  availablePoints: number;
  currentDeposit: number;
  activeStatus: boolean;
  isCustomBadgesPeriodCalendar: boolean;
  isCustomBadgesAdded: boolean;
  isShowSelfRedeemPoint: boolean;
  pointRedeemType: string[];
  isPeriodOnceAdd: boolean;
  isShowAttachment: boolean;
  restrictedCommentTypeString: string;
  restrictedCommentType: string[];
  showFestiveTheme: boolean;
  linkedinClientId: string;
  handbookDownloadAllowed: boolean;
  badgeShareAllowed:boolean;
}

export type TEmployeeWish = IEmployeeDetail & {
  date: string;
  wishesTypeId: WishTypeEnum;
  wishesType: string;
  message: string;
  yearCount: number;
  replied: boolean;
  isWished?: boolean;
}

export type TEmployeeWishExtended = Partial<TEmployeeWish> & {
  wishesTypeId?: WishTypeEnum;
  list?: TWishedMeResonseItem[];
  when?: string;
  wishedDate?: string;
  markForDelete?: boolean;
  shownOnce?: boolean;
  isEnableBirthdayPopper? : boolean;
}

export type WishesCount = {
  wishesTypeName: string;
  count: number;
  wishesTypeId: WishTypeEnum;
}

export interface IWishResponse {
  wishes: TEmployeeWish[];
  wishesCount: WishesCount[];
}

export type TSocialWallOverlayEvent = {
  event: Event | null;
  card: TEmployeeCard | null;
  show: boolean;
}


export type TFundRaiserResponseItem = {
  logOnUserCode: string;
  domainCode: string;
  logOnUserId: number;
  logOnUserName: string;
  currentUserLogOnType: string;
  fundraiserId: number;
  campaignName: string;
  campaignPurpose: string;
  fromDate: Date;
  toDate: Date;
  campaignImageFile: string;
  campaignUploadedFileName: string;
  compressedUploadedFileName: string;
  isMappedComponent: boolean;
  payCode: string;
  salaryDeductionMonth: number;
  salaryDeductionYear: number;
  campaignImageVirtualPath: string;
  compressedImageVirtualPath: string;
  isActive: boolean;
  createdById: number;
  createdDate: Date;
  totalDonatedAmount: string;
  employeeDonatedAmount: string;
  noOfCheers: number;
  isCheers: number;
  totalCommentCount: number;
  targetAmount: string;
}



export type TTiemOfficeSettingLeaveTypeResponseItem = {
  logOnUserCode: string;
  domainCode: string;
  logOnUserId: number;
  logOnUserName: string;
  currentUserLogOnType: string;
  leaveCode: string;
  leaveName: string;
  leaveId: number;
  leaveCycle: string;
  leavePaidUnpaid: string;
  isActive: boolean;
  isEncashmentAllowed: boolean;
  allowLeaveDonate: boolean;
  donateLeaveBalance: number;
}

export type TWorkforceSetupMasterSearchResponseItem = {
  logOnUserCode: string;
  domainCode: string;
  logOnUserId: number;
  logOnUserName: string;
  currentUserLogOnType: string;
  officeMasterId: number;
  officeMasterName: string;
  officeAddress: string;
  officeCityCode: string;
  workspaceId: number;
  imageFileName: string;
  imageFilePath: string;
  officeSeatingCapacity: number;
  allowSeatingMoreThanCapacity: boolean;
  noOfSeatMoreThanCapacity: number;
  officeSubBranchCode: string;
  workspaceName: string;
  isActive: boolean;
  officeSubBranchName: string;
  officeCityName: string;
  imageVirtualPath: string;
}
export type TPMSGoalRequestFlowLastApprovedGoalForEditResponse = {
  goalCreationRequestId: number;
  employeeId: number;
  goalCycleStartPeriod: Date;
  goalCycleEndPeriod: Date;
}
export type TWishedMeResonseItem = IEmployeeDetail & {
  date: Date;
  wishesTypeId: number;
  wishesType: string;
  message: string;
  yearCount: number;
  replied: boolean;
  wishesMessage: string;
};

export type TEditPayload = {name:string,remarks:string,employee?:IEmployeeDetail,feedId:number,points:number,color:string,customStatus:boolean,imagePath:string};
export type TRecruitmentReferralPosting =  {
  employeeDetails: IEmployeeDetail,
  positionId: number;
  referralMessage: string;
  createdDate: string;
  jobTitle: string;
  isReferralInternalJobPostingWallPosting: number;
  feedbackId: number;
  feedbackName: string | null;
  isEnableButton: boolean;
  isReferralBonus: boolean;
  currencyId: number;
  amount: number;
  afterJoinPayBonusDay: number;
  isRecoverReferralBonus: boolean;
  afterLeavesRecoverBonusDay: number;
  isSocialWallReferralBonus: string;
  isSeeMore?: boolean;
  shortReferralMessage?: string;
  labelNameSeeMore?: string;
};


export type TLeaveBalanceDetail = {
  logOnUserCode: string;
  domainCode: string;
  logOnUserId: number;
  logOnUserName: string;
  currentUserLogOnType: string;
  employeeId: number;
  leaveId: number;
  leaveBalanceId: number;
  leaveBalance: number;
  floatingLeaveBalance: number;
  leaveCreditDate: Date;
  leaveCreditMonth: number;
  leaveCreditYear: number;
  leaveBalanceStatus: string;
  remarks: string;
}


export type TEnterpriseWallTiemOfficeBalance = IEmployeeDetail & {
  dateOfJoining: Date;
  leaveDetails: TLeaveBalanceDetail[];
}

export interface TODOMODEL {
  projectId: number,
  taskId: number,
  taskName: string,
  taskPriority: string
}

export type CheerTypeListT = {
  emojiType:    number | undefined;
  list:         ListT[] | undefined;
  reactionName: string | undefined;
}

export type ListT = {
  imageVirtualPath: string | undefined;
  employeeName: string | undefined;
  employeeCode: string | undefined;
  date: Date | string;
  emojiType: number | undefined;
  iconClass: string | undefined;
}

export type TShowOverlayPanelEvent = { $event: Event, employeeId: number | string, employeeCode: string, show: boolean };
