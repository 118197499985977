// @ts-nocheck
/*
 *  This file enterprise-wall.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/global/services/shared.service';

import * as _ from 'lodash';
import * as moment from 'moment';

import { HttpService } from 'src/app/global/services/http-service';
import { monthDaysTill31 } from 'src/app/global/models/common.models';
import { SnackbarAlertService } from 'src/app/global/services/snackbar-alert.service';
import { WISH_TYPE, URLS, WISHES_ID, LeaveWidgetModel } from './enterprise-wall.model';
import { SearchEmployeeService } from 'src/app/global/services/search-employee.service';
import { ProgressButtonService } from 'src/app/global/services/progress-button.service';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { displayNamesConstant } from '../recruitment/inbox/card/recruitment.model';

const PAGE_SIZE = 10;

@Injectable({
  providedIn: 'root'
})
export class EnterpriseWallService {

  companyApplicability: boolean;
  isDashBoardCall: boolean;

  attDetails: any;
  attLoading: boolean;
  isAttPresent;

  perWishLoading: boolean;
  perWishList: any = [];

  wishLoading: boolean;
  wishList: any = [];
  wishLoadingSummary: boolean;
  wishListSummary: any = [];
  wishPageNo = 1;
  wishIsMore: boolean;
  isWishing: boolean;
  selectedWishType: WISH_TYPE = 1;
  wishesCount = {
    birthday: 0,
    join_ann: 0,
    wed_ann: 0
  };

  taskLoading: boolean;
  taskCount: number;
  plannedLeaveCount: number;

  notiCount: number;
  notiCountLoading: boolean;

  notiLoading: boolean;
  notiList: any = [];
  notiPageNo = 1;
  // notiIsMore: boolean;

  feedList = [];
  isFeedLoading: boolean;
  feedPageNo: number;
  showMoreFeed: boolean;
  initialFeedCount = 0;
  newFeedCount = 0;

  profilePercent: number;
  isProfileLoading: boolean;

  currentMonth: any;
  currentYear: any;

  // widgetssss
  widgetLoading: boolean;
  widgetList = [];
  tempWidgetList = [];

  initiativesCount = {
    assignByMe: 0,
    assignToTeam: 0,
    assignTome: 0,
  };
  widgetSequence = {
    EDR: {
      sequence: 1,
      flag: false,
      name: 'Directory',
      apiCalled: false
    },
    INTV: {
      sequence: 3,
      flag: false,
      name: 'Initiative',
      apiCalled: false
    },
    PNF: {
      sequence: 4,
      flag: false,
      name: 'Pending notification',
      apiCalled: false
    },
    INB: {
      sequence: 5,
      flag: false,
      name: 'Inbox',
      apiCalled: false
    },
    YAT: {
      sequence: 6,
      flag: false,
      name: 'Yesterday attendance',
      apiCalled: false
    },
    PRC: {
      sequence: 7,
      flag: false,
      name: 'Profile completion',
      apiCalled: false
    },
    SDA: {
      sequence: 8,
      flag: false,
      name: 'Continuous feedback',
      apiCalled: false
    },
    ORG: {
      sequence: 9,
      flag: false,
      name: 'ORG Chart',
      apiCalled: false
    },
    SHT: {
      sequence: 10,
      flag: false,
      name: 'Shortcut',
      apiCalled: false
    },
    TLW: {
      sequence: 11,
      flag: false,
      name: 'Team Leave',
      apiCalled: false
    },
    TLB: {
      sequence: 12,
      flag: false,
      name: 'Leave balance',
      apiCalled: false
    },
    TAC: {
      sequence: 13,
      flag: false,
      name: 'Calendar',
      apiCalled: false
    },
    EHP: {
      sequence: 14,
      flag: false,
      name: 'Goto HROne old version',
      apiCalled: false
    },
    GOAL: {
      sequence: 17,
      flag: false,
      name: 'Goal',
      apiCalled: false
    },
    OPS: {
      sequence: 16,
      flag: false,
      name: 'Open position',
      apiCalled: false
    },
    PLFT: {
      sequence: 20,
      flag: false,
      name: 'Planned leave for today',
      apiCalled: false
    }
  };

  leftColor;
  tempLeftColor;
  rightColor;
  tempRightColor;
  colorList = ['#dcdcdc', '#ebebeb', '#f4f4f4', '#f3eaf2', '#f0f2f8', '#e8f9ff', '#e3f5ec', '#fff8e8', '#ffefe7'];
  rightColorList = ['#dcdcdc', '#ebebeb', '#f4f4f4'];

  // Added by amit;
  globalBadgeList = [];

  isTodayCelebrationEmpty = false;
  isFeedsEmpty = false;

  isBadgeLimitGet = false;
  badgeLimit = {
    maxLimit: 0,
    current: 0
  };
  badgeList = [];

  isPointLimitGet = false;
  allPointsLimit = {
    maxLimit: 0,
    current: 0
  };

  // leave widget
  leaveLoading = false;
  leaveWidgetModel = new LeaveWidgetModel();
  leaveWidgetList = [];
  leaveWidgetOuterList = {
    leaveDetails: [],
    totalCount: 0
  };
  display_leave = false;

  tempLeaveFilter = {
    "employeeLeaveSearch": {},
    "fromDate": "",
    toDate: ''
  };

  count = 0;


  // jobOpning
  totalJobOpning;
  jobPostingLoading = false;
  jobOpeningRequestDetails;
  labelList = [];
  display_refer_flyer = false;
  jobPostingInternlLoading = false;


  // shortcut
  shortcutWidgetList = [];
  shortCutLoader = false;


  // leaveBalance
  leaveBalanceLoading = false;
  leaveBalanceWidgetList = [];
  loader = false;
  hroneV4Link;

  list = [];
  colorObj: any = {};
  monthArry = monthDaysTill31;
  filter: Promise<boolean>;
  CalnderList;

  // goal wigdet
  isApprovedGoalAvailable = false;
  LabelList = [];
  goalViewDetails;
  isGoalLoading = false;

  markAttSetting;
  showMarkAtt: boolean;

  widgetSection: 'self' | 'hr' | 'manager' = 'self';
  managerWidgetAccess: boolean;
  adminWidgetAccess: boolean;

  fundraiserCampaignLoading: boolean;
  fundraiserCampaignList: any = [];

  // Comment
  showHrPostchatInput: boolean;
  commentRequestBody = {
    feedId: 0,
    pagination: {
      pageNumber: 0,
      pageSize: 5
    }
  }


  filterChips: any = {
    "reference": "",
    "sortOnUpdate": '',
    "postFor": '',
    "postBy": '',
  }

  filterObj: any = {
    "reference": "",
    "sortOnUpdate": '',
    "postFor": '',
    "postBy": '',
  }


  openCount: number;
  jobopeningLoading = false;

  isFeatureApiHit = false;
  isCommentFeatureAvailable = false;
  employeeLateCount = 0;
  leaveTypeList: any = [];
  leaveTypeId: any = [];
  forPlannedLeaveToday = false;
  selectedFromDate = '';
  selectedToDate = '';

  constructor(
    public _shared: SharedService,
    public _http: HttpService,
    public _snackBar: SnackbarAlertService,
    public _searchEmployee: SearchEmployeeService,
    public _progressButton: ProgressButtonService,
    public _httpClient: HttpClient
  ) { }

  resetAllData() {
    this.isDashBoardCall = false;
    this.initialFeedCount = 0;
    this.newFeedCount = 0;

    this.isBadgeLimitGet = false;
    this.badgeLimit = {
      maxLimit: 0,
      current: 0
    };
    this.badgeList = [];

    this.isPointLimitGet = false;
    this.allPointsLimit = {
      maxLimit: 0,
      current: 0
    };

    this.widgetSection = 'self';
    this.managerWidgetAccess = false;
    this.adminWidgetAccess = false;
    this.resetFilter();
  }

  setColor() {
    const lColor: any = localStorage.getItem('leftColor');
    const rColor: any = localStorage.getItem('rightColor');
    if (lColor && rColor) {
      this.leftColor = lColor;
      this.rightColor = rColor;
    } else {
      this.leftColor = '#f4f4f4';
      this.rightColor = '#ebebeb';
    }

    this.tempLeftColor = this.leftColor;
    this.tempRightColor = this.rightColor;
  }

  resetWidgets() {
    const arr = Object.keys(this.widgetSequence);
    for (let i = 0; i < arr.length; i++) {
      this.widgetSequence[arr[i]].apiCalled = false;
    }
  }

  getWidgets(): Promise<any> {
    this.resetWidgets();
    return new Promise((resolve, reject) => {

      this.widgetLoading = true;
      this.widgetList = [];
      const url = URLS.widgets;
      this._http.Get(url).subscribe(res => {
        if (res && res.length) {
          this.widgetList = res;
          this.widgetList = this.widgetList.filter(widget => widget.masterWidgetId !== 3);
        }

        this.manipulateWidgets();

        this.widgetLoading = false;
        resolve(true);
      }, err => {
        this.widgetLoading = false;
        reject(new Error());
      });

    });
  }

  manipulateWidgets() {

    this.widgetList = _.sortBy(this.widgetList, [e => e.sequenceNumber]);

    let localList: any = localStorage.getItem('widgetList');
    if (localList) {
      localList = JSON.parse(localList);
    }

    if (localList && localList.length && (localList.length === this.widgetList.length)) {
      let isMatched = true;
      for (let i = 0; i < this.widgetList.length; i++) {
        const fi = localList.findIndex(ele => ele.widgetType === this.widgetList[i].widgetType
          && ele.widgetName === this.widgetList[i].widgetName);
        if (fi < 0) {
          isMatched = false;
          break;
        }
      }

      if (isMatched) {
        this.widgetList = localList;
      } else {
        this.widgetList.forEach(ele => {
          ele.flag = true;
        });
      }
    } else {
      this.widgetList.forEach(ele => {
        ele.flag = true;
      });
    }

    this.tempWidgetList = _.cloneDeep(this.widgetList);
    localStorage.setItem('widgetList', JSON.stringify(this.widgetList));
    this.widgetSequencing();
  }

  widgetSequencing() {
    for (let i = 0; i < this.widgetList.length; i++) {
      const wgt = this.widgetSequence[this.widgetList[i].widgetType];
      if (wgt) {
        wgt.sequence = i + 1;
        wgt.flag = this.widgetList[i].flag;
        wgt.name = this.widgetList[i].widgetName;
      }
    }
  }

  getAttendance() {
    this.isAttPresent = false;
    this.attLoading = true;
    this.attDetails = null;
    const date = moment().subtract(1, 'days').format("YYYY-MM-DD");
    const url = URLS.attendance + this._shared.logOnUserDetails.employeeId + '/' + date;
    this._http.Get(url).subscribe(res => {
      if (res && res.length) {
        this.attDetails = res[0];

        let shiftHours = this.attDetails.shiftHours;
        let workingHours = this.attDetails.workingHours;
        shiftHours = Number(shiftHours.replace(':', '.'));
        workingHours = Number(workingHours.replace(':', '.'));
        if (workingHours >= shiftHours) {
          this.isAttPresent = true;
        }
      }
      this.attLoading = false;
    }, err => {
      this.attLoading = false;
    });
  }

  getPerWishes() {
    this.perWishLoading = true;
    this.perWishList = [];

    const url = URLS.perWishes + '0/0/0';

    const wishes = this._http.Get(url);
    const wished = this._http.Get(URLS.wishedMe);

    forkJoin([wishes, wished]).subscribe(response => {
      console.log('pes wishes ', response);
      if (response[0] && response[0].wishes) {
        this.perWishList = response[0].wishes;
        for (let i = 0; i < this.perWishList.length; i++) {
          this.perWishList[i].list = [];
        }
      }

      if (response[1]) {
        const res = response[1];
        for (let i = 0; i < res.length; i++) {
          const fi = this.perWishList.findIndex(ele => ele.wishesTypeId === res[i].wishesTypeId);
          if (fi >= 0) {
            this.perWishList[fi].list.push(res[i]);
          }
        }
      }

      this.perWishLoading = false;
    }, err => {
      this.perWishLoading = false;
    });

  }

  getWishes(key: 'summary' | 'all', more?) {
    return new Promise((resolve, reject) => {

      if (more) {
        this.wishPageNo += 1;
      } else {
        this.wishList = [];
        this.wishPageNo = 1;
      }

      let typeId = 0;
      if (key === 'all') {
        typeId = this.selectedWishType;
        this.wishLoading = true;
      } else {
        this.wishLoadingSummary = true;
      }

      const url = URLS.wishes + typeId + '/' + this.wishPageNo + '/' + PAGE_SIZE + '/' + true;

      this._http.Get(url).subscribe(result => {
        if (result) {
          const res = result.wishes;

          // check if it is already wished
          let wishedList: any = localStorage.getItem('wishedList');
          if (wishedList) {
            wishedList = JSON.parse(wishedList);
          } else {
            wishedList = [];
          }
          const myId = this._shared.logOnUserDetails.employeeId;
          for (let i = 0; i < wishedList.length; i++) {
            const fi = res.findIndex(ele => ele.employeeId === wishedList[i].employeeId
              && myId === wishedList[i].myId && ele.wishesTypeId === wishedList[i].wishesTypeId);
            if (fi >= 0) {
              res[fi].isWished = true;
            }
          }
          // check if it is already wished

          if (key === 'summary') {
            this.wishListSummary = res;

            if (!this.wishListSummary.length) {
              this.isTodayCelebrationEmpty = true;
            } else {
              this.isTodayCelebrationEmpty = false;
            }

            try {
              const birth = result.wishesCount.find(ele => ele.wishesTypeId === WISHES_ID.birthday);
              const join = result.wishesCount.find(ele => ele.wishesTypeId === WISHES_ID.joining_anniv);
              const wed = result.wishesCount.find(ele => ele.wishesTypeId === WISHES_ID.wedding_anniv);

              this.wishesCount.birthday = birth ? birth.count : 0;
              this.wishesCount.join_ann = join ? join.count : 0;
              this.wishesCount.wed_ann = wed ? wed.count : 0;
            } catch (err) {

            }
          } else {
            if (more) {
              this.wishList = _.concat(this.wishList, res);
            } else {
              this.wishList = res;
            }

            if (!res || (res && res.length < PAGE_SIZE)) {
              this.wishIsMore = false;
            } else {
              this.wishIsMore = true;
            }
          }

        }

        if (key === 'all') {
          this.wishLoading = false;
        } else {
          this.wishLoadingSummary = false;
        }
        resolve(true);
      }, err => {
        if (key === 'all') {
          this.wishLoading = false;
        } else {
          this.wishLoadingSummary = false;
        }
      });
    });
  }

  onWishAction(wish, reqData) {
    this.isWishing = true;
    const url = URLS.wishAction;

    this._http.Post(url, reqData).subscribe(res => {
      this._snackBar.Alert(res.message, res.validationType);

      if (res.validationType === '0') {
        wish.isWished = true;

        // save wished msg in local storage
        let wishedList: any = localStorage.getItem('wishedList');
        if (wishedList) {
          wishedList = JSON.parse(wishedList);
        } else {
          wishedList = [];
        }
        wishedList.push({
          employeeId: wish.employeeId,
          wishesTypeId: wish.wishesTypeId,
          date: (new Date()).getTime(),
          myId: this._shared.logOnUserDetails.employeeId
        });
        localStorage.setItem('wishedList', JSON.stringify(wishedList));
        // end save wished msg in local storage
      }
      this.isWishing = false;
    }, err => {
      this.isWishing = false;
    });
  }

  getTaskCount() {
    this.taskLoading = true;
    this.taskCount = 0;
    const url = URLS.taskCount;
    this._http.Get(url).subscribe(res => {
      if (res) {
        this.taskCount = res.taskCount;
      }
      this.taskLoading = false;
    }, err => {
      this.taskLoading = false;
    });
  }

  getNotiCount() {
    this.notiCountLoading = true;
    this._http.Get(URLS.notiCount).subscribe(res => {
      this.notiCount = Number(res);
      this.notiCountLoading = false;
    }, err => {
      this.notiCountLoading = false;
    });
  }

  getNotification(more?): Promise<any> {
    return new Promise((resolve, reject) => {

      this.notiLoading = true;

      if (more) {
        this.notiPageNo += 1;
      } else {
        this.notiList = [];
        this.notiPageNo = 1;
      }

      const url = URLS.notification + this.notiPageNo + '/' + PAGE_SIZE;

      this._http.Get(url).subscribe(res => {
        if (res) {

          for (let i = 0; i < res.length; i++) {

            const myd = this.dateConverter(res[i].alertDate);

            const fi = this.notiList.findIndex(ele => ele.alertDate === myd);
            if (fi >= 0) {
              this.notiList[fi].list.push(res[i]);
            } else {
              this.notiList.push({
                alertDate: myd,
                list: [res[i]]
              });
            }

          }

        }
        this.notiLoading = false;
        resolve(true);
      }, err => {
        this.notiLoading = false;
      });

    });
  }

  dateConverter(alertDate) {
    const date = moment(alertDate).format('DD/MM/YYYY');
    const today = moment().format('DD/MM/YYYY');
    const yesterday = moment().subtract(1, 'day').format('DD/MM/YYYY');
    let myd;
    if (date === today) {
      myd = 'Today';
    } else if (date === yesterday) {
      myd = 'Yesterday';
    } else {
      myd = date;
    }

    return myd;
  }
  addPropertyForCustomBadge(feed) {
    if (feed.reference === 'Badge') {
      if (feed.customStatus) {
        this._shared.customBadges.find(badge => {
          if (feed.feedImage == badge.imageName) {
            feed.imageId = feed.feedImage;
            feed.feedImage = badge.path;
            feed.color = badge.color;
          }
        });
      }
    }
  }
  addComment(feed, isMore?) {
    if (feed.commentsCount) {
      this.commentRequestBody.feedId = feed.feedId;
      if (feed.commentList.length) {
        this.commentRequestBody.pagination.pageNumber += 1;
        this.commentRequestBody.pagination.pageSize = 5;
      } else {
        this.commentRequestBody.pagination.pageNumber = 1;
        this.commentRequestBody.pagination.pageSize = 5;
        feed.commentList = [];
      }
      this._http.Post('/api/Engage/Badge/FeedCommentDetail', this.commentRequestBody).subscribe(response => {
        if (response && response.length) {
          if (feed.commentList.length) {
            feed.commentList = _.concat(feed.commentList, response);
          } else {
            feed.commentList = response;
          }
          const distinct = _.uniqBy([...feed.commentList], 'commentId');
          feed.commentList = distinct;
          if (feed.commentList.length > 0) {
            feed.viewComment = response.length >= 5;
          }
        }
      }, error => { });
    }
  }
  getFeeds(more?): Promise<any> {
    return new Promise((resolve, reject) => {

      this.isFeedLoading = true;

      if (more) {
        this.feedPageNo += 1;
      } else {

        this.feedList = [];
        this.feedPageNo = 1;
        this.showMoreFeed = false;

        this.initialFeedCount = 0;
        this.newFeedCount = 0;

      }

      const url = URLS.feeds;
      this.makeFilterReq();
      const reqData = {

        reference: this.filterObj.reference ? this.filterObj.reference.id : '',
        "sortOnUpdate": this.filterObj.sortOnUpdate ? this.filterObj.sortOnUpdate.id === 'SOU' ? true : false : false,
        "postFor": this.filterObj.postFor ? this.filterObj.postFor.id : 0,
        "postBy": this.filterObj.postBy ? this.filterObj.postBy.id : 0,
        userCompanyId: this._shared.logOnUserDetails && this._shared.logOnUserDetails.companyCode ? Number(this._shared.logOnUserDetails.companyCode) : 0,
        pagination: {
          pageNumber: this.feedPageNo,
          pageSize: PAGE_SIZE
        }
      };


      this._http.Post(url, reqData).subscribe(response => {
        const res = response;
        if (res) {

          // make image list and video list
          for (let i = 0; i < res.length; i++) {
            res[i].imageList = [];
            res[i].videoList = [];
            res[i].commentList = [];
            res[i].showChatInput = false;
            if (res[i].commentsCount) {
              res[i].viewComment = true;
            }
            this.addPropertyForCustomBadge(res[i]);
            if (res[i].imageAndVideoList && res[i].imageAndVideoList.length) {
              for (let j = 0; j < res[i].imageAndVideoList.length; j++) {
                if (res[i].imageAndVideoList[j].imageOrVideo == 'I') {
                  res[i].imageList.push({
                    source: res[i].imageAndVideoList[j].path,
                    thumbnail: res[i].imageAndVideoList[j].compressImagePath ?
                      res[i].imageAndVideoList[j].compressImagePath : res[i].imageAndVideoList[j].path
                  });
                } else {
                  res[i].videoList.push({
                    source: res[i].imageAndVideoList[j].path
                  });
                }
              }
            }
          }
          // end image list and video list

          // check if it is already cheered
          // let cheeredList: any = localStorage.getItem('cheeredList');
          // if (cheeredList) {
          //     cheeredList = JSON.parse(cheeredList);
          // } else {
          //     cheeredList = [];
          // }
          // const myId = this._shared.logOnUserDetails.employeeId;
          // for (let i = 0; i < cheeredList.length; i++) {
          //     const fi = res.findIndex(ele => myId === cheeredList[i].myId && ele.feedId === cheeredList[i].feedId);
          //     if (fi >= 0) {
          //         res[fi].isCheered = true;
          //     }
          // }
          // check if it is already cheered


          if (more) {
            this.feedList = _.concat(this.feedList, res.map(feed => {
              if (feed.reference === 'Endorsement') {
                return this.handleSkillSplitting(feed);
              }
              return feed;
            }));
          } else {
            this.feedList = res.map(feed => {
              if (feed.reference === 'Endorsement') {
                return this.handleSkillSplitting(feed);
              }
              return feed;
            });
            if (!this.feedList.length) {
              this.isFeedsEmpty = true;
            } else {
              this.isFeedsEmpty = false;
            }
          }
          if (res.length === PAGE_SIZE) {
            this.showMoreFeed = true;
          } else {
            this.showMoreFeed = false;
          }
        }
        this.isFeedLoading = false;
        resolve(true);
      }, err => {
        this.isFeedLoading = false;
      });
    });
  }
  handleSkillSplitting(feed: any):any {
    const arr = feed.remarks.split('~');
    feed.skill = arr.slice(1).join("~");
    feed.remarks = arr[0];
    return feed;
  }

  getFeedCount(initial?) {
    this._http.Get(URLS.feedCount).subscribe(res => {
      if (res) {
        if (initial || !this.initialFeedCount) {
          this.initialFeedCount = res;
        } else {
          this.newFeedCount = res - this.initialFeedCount;
        }
      }

      { { this.initialFeedCount } }
      { { this.newFeedCount } }
      { { res } }
    });
  }


  getProfileCompletion() {
    this.isProfileLoading = true;
    this._http.Get(URLS.profile).subscribe(res => {
      if (res) {
        this.profilePercent = res;
      }
      this.isProfileLoading = false;
    }, err => {
      this.isProfileLoading = false;
    });
  }

  getCompApplicability() {
    this.companyApplicability = this._shared.getDirectoryFilter == 1 ? true : false;
  }

  getBadgeLimit() {
    this.badgeLimit = {
      maxLimit: 0,
      current: 0
    };
    this._http.Get(URLS.badgeLimit + this._shared.logOnUserDetails.employeeId).subscribe(res => {
      if (res) {
        this.badgeLimit = res;
      }
      this.isBadgeLimitGet = true;
    }, err => {
      this.isBadgeLimitGet = true;
    });
  }

  getPointLimit() {
    this.allPointsLimit.maxLimit = 0;
    this.allPointsLimit.current = 0;

    const url = URLS.pointLimit + 0;

    this._http.Get(url).subscribe(res => {
      if (res) {
        this.allPointsLimit.maxLimit = res.totalPoint;
        this.allPointsLimit.current = res.usedPoints;
      }
      this.isPointLimitGet = true;
    }, err => {
      this.isPointLimitGet = true;
    });
  }

  getLeaveWidget(more?, fromFilter?): Promise<any> {
    return new Promise((resolve, reject) => {

      this.leaveLoading = true;

      if (more) {
        this.leaveWidgetModel.pagination.pageNumber += 1;
      } else {
        this.leaveWidgetList = [];
        if (!fromFilter) {
          this.leaveWidgetOuterList.leaveDetails = [];
        }
        this.leaveWidgetModel.pagination.pageNumber = 1;
      }

      this._http.Post(URLS.leaveWidget, this.leaveWidgetModel).subscribe(res => {
        if (res) {
          res.leaveDetails.forEach(element => {
            const date1 = moment(element.leaveFromDate);
            const date2 = moment(element.leaveToDate);
            if (date1.isSame(date2)) {
              element['isSameDay'] = true;
            } else {
              element['isSameDay'] = false;
            }
          });
          if (more) {
            this.leaveWidgetList = this.leaveWidgetList.concat(res.leaveDetails);
          } else {
            this.leaveWidgetList = res.leaveDetails;
            if (!fromFilter) {
              this.leaveWidgetOuterList.leaveDetails = res.leaveDetails;
              this.leaveWidgetOuterList.totalCount = res.totalCount;
            }

          }
          console.log(this.leaveWidgetList);
        }
        this.leaveLoading = false;
        resolve(true);
      }, err => {
        this.leaveLoading = false;
      });

    });
  }

  onSelectLEaveFilter(event) {
    console.log(this.tempLeaveFilter.employeeLeaveSearch);
  }

  // job opning
  getTotalJobOpning() {
    this.jobPostingLoading = true;
    const req_data = {
      "positionId": 0,
      "pagination": {
        "pageNumber": 1,
        "pageSize": 1000
      }
    };
    this._http.Post('/api/recruitment/referralposting', req_data).subscribe(response => {
      this.totalJobOpning = response;
      if (this.totalJobOpning && this.totalJobOpning.length) {
        this.totalJobOpning.forEach(element => {
          if (element.referralMessage && element.referralMessage.length >= 30) {
            element['shortReferralMessage'] = element.referralMessage.slice(0, 30);
            element['isSeeMore'] = true;
            element['labelNameSeeMore'] = 'See more';
          } else {
            element['shortReferralMessage'] = element.referralMessage;
            element['isSeeMore'] = false;
          }
        });
      }
    }, err => {
      this.jobPostingLoading = false;
      this.totalJobOpning = [];
    });
  }

  getFundraiserCampaignList() {
    this.fundraiserCampaignLoading = true;
    const req_data = {
      "fundraiserId": 0,
      "pagination": {
        "pageNumber": 1,
        "pageSize": 1000
      }
    };
    this._http.Post('/api/engage/covid/fundraiser/Master/Details', req_data).subscribe(response => {
      this.fundraiserCampaignList = response ? response : [];
      this.fundraiserCampaignLoading = false;
    }, err => {
      this.fundraiserCampaignLoading = false;
      this.fundraiserCampaignList = [];
    });
  }


  openForm(requestData) {
    this.jobPostingInternlLoading = true;
    this.getLabelSetting();
    setTimeout(() => {
      this.getJobOpeningDetails(requestData.positionId, requestData.employeeDetails.employeeId);
    }, 100);
  }

  getJobOpeningDetails(requestId, employeeId) {
    const req = {
      "requestId": requestId,
      "requestBy": employeeId,
      "transactionId": null
    };
    const url = '/api/recruitment/JobOpening/Request/Details';
    this._http.Post(url, req).subscribe(res => {
      this.jobOpeningRequestDetails = res;
      this.jobPostingLoading = false;
      this.jobPostingInternlLoading = false;
    }, err => {
      this.jobPostingLoading = false;
    });
  }

  getLabelSetting() {
    const req_data = { "isCarrierPortal": false, "isReferralPost": true, "isCandidate": false, numberOfRows: 4 };
    this._http.Post('/api/recruitment/label/setting', req_data).subscribe(res => {
      this.labelList = res;
    });
  }


  getDisplayName(key) {
    const fi = this.labelList.findIndex(data => data.fieldName.toLowerCase() === key.toLowerCase());
    if (fi >= 0) {
      return this.labelList[fi].displayName;
    } else {
      // tslint:disable-next-line: no-use-before-declare
      return displayNamesConstant[key];
    }
  }

  ifVisible(key) {
    const fi = this.labelList.findIndex(data => data.fieldName.toLowerCase() === key.toLowerCase());
    if (fi >= 0 && this.labelList[fi].isReferralPost) {
      return true;
    } else {
      return false;
    }
  }




  // leave balance
  getLeaveBalanceWidget() {
    this.leaveBalanceLoading = true;
    this._http.Get(URLS.leaveBalaceWidget).subscribe(res => {
      if (res && res[0].leaveDetails.length) {
        res[0].leaveDetails = _.orderBy(res[0].leaveDetails, [leave => leave.leaveBalance], ['desc']);
        this.leaveBalanceWidgetList = res[0].leaveDetails;
        console.log('sadsafsafsdf', res);
      }
      this.leaveBalanceLoading = false;
    }, err => {
      this.leaveBalanceLoading = false;
    });
  }

  // CAlender
  onMonthChange(event) {
    this.loader = true;
    this.filter = Promise.resolve(true);
    this.list = [];
    this.colorObj = {};
    const req_data = {
      attendanceYear: event.year,
      attendanceMonth: event.month,
      employeeId: this._shared.logOnUserDetails.employeeId
    };
    const url = "/api/timeoffice/attendance/Calendar";
    this._http.Post(url, req_data).subscribe(response => {
      if (response) {
        this.CalnderList = response.filter(data => data.oddEvenIndication)
          .map(e => ({ date: e.attendanceDate, punchCount: e.oddEvenCount }));
        this.employeeLateCount = this._shared.getEmployeeLateCount(response);
        console.log('employeeLateCount', this.employeeLateCount);
        this.monthArry.forEach(ele => {
          const datecount = ele;
          const object = response.find(ele1 => ele === moment(ele1.attendanceDate).date());
          if (object) {
            this.colorObj[datecount] = {
              firstHalfStatusColor: object.firstHalfStatusColor,
              secondHalfStatusColor: object.secondHalfStatusColor,
              showAttendanceStatusOnCalendar: object.showAttendanceStatusOnCalendar
            };
            if (
              (object.updatedFirstHalfStatus === "P" && object.updatedSecondHalfStatus === "P") ||
              (object.updatedFirstHalfStatus === "A" && object.updatedSecondHalfStatus === "A")
            ) {
              this.list.push({ date: "NC" });
            } else if (object.updatedFirstHalfStatus === "-" && object.updatedSecondHalfStatus === "-") {
              this.list.push({ date: "DN" });
            } else {
              this.list.push({ date: "" });
              if (!object.showAttendanceStatusOnCalendar) {
                if (object.updatedFirstHalfStatus === "P" || object.updatedFirstHalfStatus === "A") {
                  this.colorObj[datecount].firstHalfStatusColor = '#00000000';
                } else {
                  this.colorObj[datecount].firstHalfStatusColor = this.colorObj[datecount].firstHalfStatusColor;
                }
                if (object.updatedSecondHalfStatus === "P" || object.updatedSecondHalfStatus === "A") {
                  this.colorObj[datecount].secondHalfStatusColor = '#00000000';
                } else {
                  this.colorObj[datecount].secondHalfStatusColor = this.colorObj[datecount].secondHalfStatusColor;
                }
              } else {
                this.colorObj[datecount].firstHalfStatusColor = this.colorObj[datecount].firstHalfStatusColor;
                this.colorObj[datecount].secondHalfStatusColor = this.colorObj[datecount].secondHalfStatusColor;
              }
            }
          } else {
            this.list.push({ date: "" });
          }
        });
      } else {
        this.list = [];
        this.colorObj = {};
        this.loader = false;
      }
      this.loader = false;
      this.filter = Promise.resolve(false);
    }, err => {
      this.list = [];
      this.colorObj = {};
      this.loader = false;
    });
  }

  getHroneV4Link() {
    const authlogin = JSON.parse(localStorage.getItem('authlogin'));
    const accessToken = authlogin ? authlogin.access_token : '';
    this._http.Get('/api/core/Enterprise').subscribe(res => {
      if (res && res.length) {
        this.hroneV4Link = res[0].hrOneV4BasePath + accessToken;
      }
    });
  }

  getPerformanceInititiveCount() {
    const url = `/api/pms/initiative/widget/Count/${this._shared.logOnUserDetails.employeeId}`;
    this._http.Get(url).subscribe(response => {
      console.log('response', response);
      if (response) {
        this.initiativesCount = response;
      }
    }, error => {
    });
  }

  // goal widget
  getLAstApprovedData() {
    this.isApprovedGoalAvailable = true;
    this.isGoalLoading = true;
    this._http.Get(`/api/pms/goal/requestFlow/LastApprovedGoalForEdit/${this._shared.logOnUserDetails.employeeId}`).subscribe(res => {
      if (res) {
        const event = {
          requestId: res.goalCreationRequestId,
          level: 0,
          employeeId: this._shared.logOnUserDetails.employeeId
        };
        this.getGoalDetails(event);
      } else {
        this.isApprovedGoalAvailable = false;
        this.isGoalLoading = false;
      }
    }, err => {
      this.isGoalLoading = false;
      this.isApprovedGoalAvailable = false;
    });
  }

  getGoalDetails(event?) {
    this.getLableList();
    if (event) {
      setTimeout(() => {
        this._http.Get(`/api/pms/goal/requestFlow/${event['requestId']}/${event['level']}`).subscribe(data => {
          this.goalViewDetails = data;
          this.isGoalLoading = false;
        }, err => {
          this.isGoalLoading = false;
        });
      }, 100);
    } else {
      console.log('event', event);
    }
  }

  getLableList() {
    this._http.Get('/api/core/performance/setting/LabelName').subscribe(response => {
      this.LabelList = response ? response : [];
    });
  }

  getGoalDisplayName(key) {
    if (key && this.LabelList && this.LabelList.length) {
      const fi = this.LabelList.findIndex(data => data.systemName.toLowerCase() === key.toLowerCase());
      if (fi >= 0) {
        return this.LabelList[fi].displayName;
      } else {
        return displayNamesConstant[key];
      }
    }
  }

  getcommentCheers(com) {
    return new Promise((resolve, reject) => {
      this._http.Get(`/api/Engage/Badge/CommentReactionDetails/${com.commentId}`).subscribe(response => {
        if (response) {
          resolve(response);
        }
      }, error => { reject(error) });
    }).catch(err => { })
  }

  getJobOpeningeWidget() {
    this.openCount = 0;
    this.jobopeningLoading = true;
    this._http.Get(`/api/recruitment/position/Open/Close/Count/${this._shared.logOnUserDetails.employeeId}`).subscribe((res: any) => {
      this.jobopeningLoading = false;
      if (res) {
        this.openCount = res.openCount;

      }
    });
  }

 
  makeFilterReq() {
    this.filterChips.reference = this.filterObj.reference;
    this.filterChips.sortOnUpdate = this.filterObj.sortOnUpdate;
    this.filterChips.postFor = this.filterObj.postFor;
    this.filterChips.postBy = this.filterObj.postBy;

  }

  resetFilter() {
    this.filterObj.reference = '';
    this.filterObj.sortOnUpdate = '';
    this.filterObj.postFor = '';
    this.filterObj.postBy = '';
  }

  getPlannedLeaveCount() {
    this.taskLoading = true;
    this.plannedLeaveCount = 0;
    const url = URLS.plannedLeaveCount;
    this._http.Get(url).subscribe(res => {
      if (res) {
        this.plannedLeaveCount = res;
      }
      this.taskLoading = false;
    }, err => {
      this.taskLoading = false;
    });
  }

  getTodayLeaveWidget(more?, fromFilter?) {
    return new Promise((resolve, reject) => {
      const type = '';
      this.leaveLoading = true;

      if (more) {
        this.leaveWidgetModel.pagination.pageNumber += 1;
      } else {
        this.leaveWidgetList = [];
        if (!fromFilter) {
          this.leaveWidgetOuterList.leaveDetails = [];
        }
        this.leaveWidgetModel.pagination.pageNumber = 1;
      }

      this._http.Post(URLS.leaveWidgetPlanned, this.leaveWidgetModel).subscribe(res => {
        if (res && res.leaves.length) {
          res.leaves.forEach(element => {
            const date1 = moment(element.leaveFromDate);
            const date2 = moment(element.leaveToDate);
            if (date1.isSame(date2)) {
              element['isSameDay'] = true;
            } else {
              element['isSameDay'] = false;
            }
          });
          if (more) {
            this.leaveWidgetList = this.leaveWidgetList.concat(res.leaves);
          } else {
            this.leaveWidgetList = res.leaves;
            if (!fromFilter) {
              this.leaveWidgetOuterList = res.leaves;
              this.leaveWidgetOuterList.totalCount = res.totalCount;
            }
          }
        }
        this.leaveLoading = false;
        resolve(true);
      }, err => {
        this.leaveLoading = false;
      });

    });
  }

  getLeavetype() {
    const url = '/api/timeoffice/setting/leave/Type';
    this._http.Get(url).subscribe(response => {
      this.leaveTypeList = response ? response : [];
      if (response && response.length) {
        this.leaveTypeList = response.map((e) => {
          return {
            key: e.leaveId,
            value: e.leaveName
          }
        })
      } else {
        this.leaveTypeList = [];
      }
    }, error => {
      this.leaveTypeList = [];
    });
  }
}
