/*
 *  This file workflow.constant.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

export const workFlowIds = {
    snapshot: 49,
    other: 73,
    personal: 74,
    family: 75,
    professional: 76,
    healthInsurance: 77,
    previousEmployerDetails: 78,
    document: 79,
    confirmation: 7,
    separation: 28,
    cancelSeparation: 72,
    assignSalaryStructure: 50,
    fbpSalaryStructure: 94,
    reimbursement: 10,
    loan: 42,
    processLoan: 24,
    bank: 81,
    proposedInvestment: 32,
    confirmedInvestment: 80,
    ArearRegularization: 2,
    onDuty: 4,
    restrictedHoliday: 37,
    restrictedHolidayCancel: 15,
    AttendanceRegularization: 2,
    onDutyCancel: 48,
    AttendanceRegularizationCancel: 47,
    leave: 1,
    leaveCancel: 9,
    shortLeave: 69,
    overtime: 83,
    compoff: 19,
    shortLeaveCancel: 82,
    settlement: 67,
    markAttendance: 3,
    location: 71,
    policy360nomination: 86,
    goalcreationapproval: 11,
    goalreview: 12,
    expenseAdvanceApproval: 87,
    jobOpening: 88,
    expenseReport: 89,
    exceptionalExpnseReport: 90,
    exceptionalLeave: 91,
    offerletter: 92,
    transfer: 93,
    fbpApproval: 94,
    reimbursementCancel: 95,
    pip: 13,
    variable: 98,
    travel: 96,
    travelCancel: 97,
    asset: 99,
    pipEval: 14,
    induction: 100,
    inductionEval: 101,
    initiateInduction: 102,
    timesheetrequest:105,
    timesheetapproval: 15,
    skills: 106,
    milestoneApprover:108,
    projectApprover:109
};


export const inputTypeIds = {
    onDuty: 11,
    restrictedHoliday: 12,
    AttendanceRegularization: 10,
    onDutyCancel: 40,
    AttendanceRegularizationCancel: 39,
    leave: 9,
    leaveCancel: 41,
    shortLeave: 13,
    overtime: 17,
    compoff: 16,
    shortLeaveCancel: 42,
    markAttendance: 49,
    exceptionalLeave: 79,
    location: 50,
    pipEvaluationLetter: 102,
    pipLetter : 115,
    restrictedHolidayCancel:116,
    offer : 78
};
