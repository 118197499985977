// @ts-nocheck
/*
 *  This file hrone-network.interceptor.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 20203
 *  (C) 2015-20203. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HroneNetworkHelperService } from './hrone-network-helper.service';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { HttpService } from '../http-service';
import { TLoginTokenResponse } from '../../models/common.models';




@Injectable()
export class HroneNetworkInterceptor implements HttpInterceptor {
  tokenSubject: BehaviorSubject<TLoginTokenResponse | null> = new BehaviorSubject(this._helper.getAuthLogin());


  constructor(
    private _helper: HroneNetworkHelperService,
    private _httpService: HttpService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(request.headers.get('hrone-refresh-header')){
          const newError = {...error};
          newError.status = 5999;
          return throwError(newError);
        }
        if (error.status === 403 || ((error?.url||"").includes(this._helper._config.microservice_base_url) && error.status === 401) ) {
          return this.handle403Error(request, next);
        }
        return throwError(error);
      },)
    );
  }


  handle403Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    if (!this.getIsRefreshing()) {

      this.setIsRefreshing();
      this.tokenSubject.next(null);

      return this._helper.getRefreshToken().pipe(
        switchMap((newToken: TLoginTokenResponse) => {
          this._helper.setLoginAuth(newToken);
          this._httpService.updateSignalRAuthTokenSubject.next({token:newToken,isLogout:false});
          this.tokenSubject.next(newToken);

          const authlogin = JSON.parse(localStorage.getItem('authlogin'));
          const accessToken = authlogin ? authlogin.access_token : '';
          const dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._httpService._config.domainCode
          const modifiedHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken || "",
            'domainCode': dc,
            'AccessMode': 'W'
          });
          request.clone().headers.keys().forEach(key => {
            key !== 'Authorization' && modifiedHeaders.set(key, request.clone().headers.get(key))
          });

          const newRequest = request.clone({ headers: modifiedHeaders });
          return next.handle(newRequest);
        }
        ),
        catchError((err: HttpErrorResponse) => {

          if (err.url.includes("/oauth2/token")) {

            this.removeIsRefreshing()
            this._helper.logout();
            const newError = {...err};
            newError.status = 5999;
            return throwError(newError);
          }
          return throwError(err);
        }),
        finalize(() => {

          this.removeIsRefreshing()
        })
      );


    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {

          const headers = this._httpService.getHeaders();
          const newRequest = request.clone({ headers });
          return next.handle(newRequest);
        })
      );
    }
  }

  removeIsRefreshing():void{
    localStorage.removeItem("isRefreshingToken");
  }

  setIsRefreshing():void{
    localStorage.setItem("isRefreshingToken",JSON.stringify(true));
  }

  getIsRefreshing():boolean {
    return JSON.parse(localStorage.getItem("isRefreshingToken"));
  }

}


