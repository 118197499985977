
/*
 *  This file moment.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2023
 *  (C) 2015-2023. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */



import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

type MomentFormat =
  | 'YYYY-MM-DD'
  | 'DD-MM-YYYY'
  | 'MM/DD/YYYY'
  | 'YYYY/MM/DD'
  | 'YYYY-MM-DDTHH:mm:ss'
  | 'YYYY-MM-DD HH:mm:ss'
  | 'MM-DD-YYYY'
  | 'DD/MM/YYYY'
  | 'MMM DD, YYYY'
  | string;

type MomentUnit =
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks'
  | 'months'
  | 'quarters'
  | 'years';

@Injectable({
  providedIn: 'root'
})
export class MomentService {

  getMomentFromDate(date: string | Date | moment.Moment): Moment {
    return moment(date)
  }

  addToDate(date: string | Date, quantity: moment.DurationInputArg1, unit: moment.unitOfTime.DurationConstructor): Moment {
    return this.getMomentFromDate(date).add(quantity, unit);
  }

  subractFromDate(date: string | Date, quantity: moment.DurationInputArg1, unit: moment.unitOfTime.DurationConstructor): Moment {
    return this.getMomentFromDate(date).subtract(quantity, unit);
  }

  isDateToday(date: Date): boolean {
    if (!date) return false;
    return moment(new Date(date)).isSame(moment(new Date()), 'day');
  }

  isSameMonth(date: Date): boolean {
    if (!date) return false;
    return moment(new Date(date)).isSame(moment(new Date()), 'month');
  }

  isSame(date1: Date | string, date2: Date | string, arg: moment.unitOfTime.DurationConstructor): boolean {
    if (!date1 && !date2) return false;
    return this.getMomentFromDate(date1).isSame(this.getMomentFromDate(date2), arg);
  }

  getFirstDateOfWeek(date: string | Date): Moment {
    return moment(date).startOf('isoWeek');
  }
  getFirstDateOfYear(date: moment.Moment | Date): Moment {
    return moment(date).startOf('year')
  }
  getLastDateOfYear(date: moment.Moment | Date): Moment {
    return moment(date).endOf('year')
  }

  getFirstDateOfPrevYear(date: moment.Moment | Date): Moment {
    return moment(date).subtract(1, 'year');
  }

  getFirstDateOfNextYear(date: moment.Moment | Date): Moment {
    return moment(date).add(1, 'year');
  }

  getLastDateOfWeek(date: string | Date): Moment {
    return moment(date).endOf('isoWeek');
  }
  getFirstDateOfNextWeek(date: string | Date): Moment {
    return moment(date).add(1, 'week').startOf('isoWeek');
  }
  getFirstDateOfPrevWeek(date: string | Date): Moment {
    return moment(date).subtract(1, 'week').endOf('isoWeek');
  }
  getFirstDateOfNextMonth(date: string | Date | moment.Moment): Moment {
    return moment(date).add(1, 'month');
  }
  getFirstDateOfPrevMonth(date: string | Date | moment.Moment): Moment {
    return moment(date).subtract(1, 'month');
  }
  getFirstDateOfMonth(date: moment.Moment | Date): Moment {
    return moment(date).startOf('month');
  }

  getLastDateOfMonth(date: string | Date): Moment {
    return moment(date).endOf('month');
  }

  checkIFFirstDateIsBeforeSecond(first: Date | string, second: Date | string): boolean {
    return this.getMomentFromDate(first).isBefore(this.getMomentFromDate(second));
  }

  checkIFFirstDateIsAfterSecond(first: Date | string, second: Date | string): boolean {
    return this.getMomentFromDate(first).isAfter(this.getMomentFromDate(second));
  }
  getUTCconvertedTime(date: string | Date, utcOffset: string): Moment {
    return moment(date).utcOffset(utcOffset);
  }

  getCurrentDateOfMonth(): Moment {
    return moment().startOf('month');
  }

  checkIfInBetween(from: Date | string, to: Date | string, dateToCheck: Date | string): boolean {
    return moment(dateToCheck).isBetween(from, to, undefined, '[]');
  }

  getFormatedDate(date: Date, format: 'YYYY-MM-DD' | 'HH:mm' = 'YYYY-MM-DD'): string {
    return this.getMomentFromDate(date).format(format);
  }
  getParticularDateFormat(
    date: string | Date | moment.Moment,
    outputFormat: 'DD/MM/YYYY' | 'YYYY/MM/DD'
  ): string {
    const inputFormats = ['DD/MM/YYYY', 'YYYY/MM/DD','YYYY-MM-DD','DD-MM-YYYY','DD.MM.YYYY',
      'YYYY.MM.DD'];
    const momentDate = moment(date, inputFormats, true);

    if (!momentDate.isValid()) {
      return '';
    }

    return momentDate.format(outputFormat);
  }

  convertToDateObject(date: string | Date, format: MomentFormat = 'YYYY-MM-DD'): Date {
    return moment(date, format).toDate();
  }

  calculateDateDifference(
    toDate: string | Date,
    fromDate: string | Date,
    unit: MomentUnit = 'days',
    format: MomentFormat = 'YYYY-MM-DD'
  ): number {
    const toMoment = moment(toDate, format);
    const fromMoment = moment(fromDate, format);
    return toMoment.diff(fromMoment, unit);
  }

  isMoment(value: any): boolean {
    return moment.isMoment(value)
  }
  isTimeBetweenStartAndEnd(startHour:number,endHour:number,startMinute:number,endMinute:number,granularity:moment.unitOfTime.StartOf,inclusivity?: "()" | "[)" | "(]" | "[]"): boolean {
    const now = this.getMomentFromDate(new Date());
    const start = this.getMomentFromDate(new Date()).hour(startHour).minute(startMinute);
    const end = this.getMomentFromDate(new Date()).hour(endHour).minute(endMinute);
    return now.isBetween(start, end, granularity, inclusivity);
  }

}
