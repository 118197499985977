/*
 *  This file global.reducer.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2024
 *  (C) 2015-2024. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */


import { createReducer, on } from "@ngrx/store"
import { enterpriseEnableDisableSuccess, employeecontestEnableDisableSuccess, loadBadgeFeedSuccess, refreshPageAccessRightSuccess, subscribeModuleSuccess, updateGlboalStateFromStorage, validateFeatureSuccess, workforceSettingSuccess, loadFeedCountSuccess, showRefreshFeed } from "./global.action";
import { EGlobalStateProcessId, EGlobalStateStoreNames, TContestEnableDisableResponse, TCoreFeatureValidate, TFeedData, TPageAccessRightStoreData, TWorkforceSetupSettingResponse } from "./global.constants";
import { TSubscribeModuleResponse } from "src/app/modules/team/team-dashboards/team-dashboards.model";



export type TGlobalStateProcessVersion<T> = T  & {
  versionId?:string,
  processId : number,
  storename:string,
  employeeId?:number,
  domainCode?:string,
  date?:Date|string
}


export type TGlobalState = {
  aux:{
    refreshFeed:boolean,
    processId:number
  },
  [EGlobalStateStoreNames.Feed]: TGlobalStateProcessVersion<{
    data: TFeedData,
    loading: boolean,
  }>,
  [EGlobalStateStoreNames.EnterpriseContest]: TGlobalStateProcessVersion<{
    data: TContestEnableDisableResponse | null,
    loading: boolean,
  }>,
  [EGlobalStateStoreNames.EmployeeContest]: TGlobalStateProcessVersion<{
    data: {disabled:boolean} | null,
    loading: boolean,
  }>,
  [EGlobalStateStoreNames.PageAccessRights]: TGlobalStateProcessVersion<{
    data: TPageAccessRightStoreData,
    loading: boolean,
  }>,
  [EGlobalStateStoreNames.SubscriberModules]: TGlobalStateProcessVersion<{
    data: TSubscribeModuleResponse | null,
    loading: boolean,
  }>,
  [EGlobalStateStoreNames.WorkforceSetupSetting]: TGlobalStateProcessVersion<{
    data:  Map<number, TWorkforceSetupSettingResponse>,
    loading: boolean,
  }>,
  [EGlobalStateStoreNames.ValidateFeature]: TGlobalStateProcessVersion<{
    data: Map<number, TCoreFeatureValidate>,
    loading: boolean,
  }>,
};


export const IGlobalState: TGlobalState = {
  aux:{
    refreshFeed:false,
    processId:-9999,
  },
  [EGlobalStateStoreNames.Feed]: {
    data: {
      countData:{
        versionId:"1",
        countInfo:null,
      },
      feedCards:{
        cards:[],
        versionId:"1"
      }
    },
    loading: false,
    processId: EGlobalStateProcessId.Feed,
    storename: EGlobalStateStoreNames.Feed,
  },
  [EGlobalStateStoreNames.EnterpriseContest]: {
    data: null,
    loading: false,
    processId: EGlobalStateProcessId.EnterpriseContest,
    storename: EGlobalStateStoreNames.EnterpriseContest,
  },
  [EGlobalStateStoreNames.EmployeeContest]: {
    data: {disabled:false},
    loading: false,
    processId: EGlobalStateProcessId.EmployeeContest,
    storename: EGlobalStateStoreNames.EmployeeContest,
  },
  [EGlobalStateStoreNames.PageAccessRights]: {
    data: {
      menuAccess:null,
      menuAction:null,
      menuList:null,
      refresh:false
    },
    loading: false,
    processId: EGlobalStateProcessId.PageAccessRights,
    storename: EGlobalStateStoreNames.PageAccessRights,
  },
  [EGlobalStateStoreNames.SubscriberModules]: {
    data: null,
    loading: false,
    processId: EGlobalStateProcessId.SubscriberModules,
    storename: EGlobalStateStoreNames.SubscriberModules,
  },
  [EGlobalStateStoreNames.ValidateFeature]: {
    data: new Map<number, TCoreFeatureValidate>(),
    processId: EGlobalStateProcessId.ValidateFeature,
    loading: false,
    storename: EGlobalStateStoreNames.ValidateFeature,
  },
  [EGlobalStateStoreNames.WorkforceSetupSetting]: {
    data:   new Map<number, TWorkforceSetupSettingResponse>(),

    processId: EGlobalStateProcessId.WorkforceSetupSetting,
    loading: false,
    storename: EGlobalStateStoreNames.WorkforceSetupSetting,
  },
};


export const hroneGlobalReducer = createReducer(
  IGlobalState,
  on(updateGlboalStateFromStorage,(istate,{state}):TGlobalState=>{
      const newState = structuredClone(IGlobalState);
      state.forEach((s:any)=>{
        newState[s.storename as keyof TGlobalState] = s;
      })
      return state.length > 0 ? {...istate,...newState} : {...istate}
  }),
  on(loadBadgeFeedSuccess,(state,{data,versionId,employeeId,domainCode}):TGlobalState=>{
      return {...state,feed:{...state.feed,data:{countData:state.feed.data.countData,feedCards:{cards:data,versionId}},loading:false,processId:EGlobalStateProcessId.Feed,versionId,employeeId,domainCode},aux:{...state.aux,refreshFeed:false}}
  }),
  on(loadFeedCountSuccess,(state,{data,versionId,employeeId,domainCode}):TGlobalState=>{
      return {...state,feed:{...state.feed,data:{feedCards:state.feed.data.feedCards,countData:{countInfo:data,versionId}},loading:false,processId:EGlobalStateProcessId.Feed,versionId,employeeId,domainCode}}
  }),
  on(refreshPageAccessRightSuccess,(state,{data,versionId,employeeId,domainCode}):TGlobalState=>{

      return {...state,pageaccessrights:{...state[EGlobalStateStoreNames.PageAccessRights],data,loading:false,processId:EGlobalStateProcessId.PageAccessRights,versionId,employeeId,domainCode}}
  }),
  on(enterpriseEnableDisableSuccess,(state,{data,versionId,employeeId,domainCode}):TGlobalState=>{
      return {...state,enterprisecontest:{...state[EGlobalStateStoreNames.EnterpriseContest],data,loading:false,processId:EGlobalStateProcessId.EnterpriseContest,versionId,employeeId,domainCode}}
  }),
  on(employeecontestEnableDisableSuccess,(state,{data,versionId,employeeId,domainCode}):TGlobalState=>{
      return {...state,employeecontest:{...state[EGlobalStateStoreNames.EmployeeContest],data,loading:false,processId:EGlobalStateProcessId.EmployeeContest,versionId,employeeId,domainCode}}
  }),
  on(subscribeModuleSuccess,(state,{data,versionId,employeeId,domainCode}):TGlobalState=>{
      return {...state,subscribermodules:{...state[EGlobalStateStoreNames.SubscriberModules],data,loading:false,processId:EGlobalStateProcessId.SubscriberModules,versionId,employeeId,domainCode}}
  }),
  on(validateFeatureSuccess,(state,{data,versionId,employeeId,domainCode}):TGlobalState=>{
      return {...state,validatefeature:{...state[EGlobalStateStoreNames.ValidateFeature],data,loading:false,processId:EGlobalStateProcessId.ValidateFeature,versionId,employeeId,domainCode}}
  }),
  on(workforceSettingSuccess,(state,{data,versionId,employeeId,domainCode}):TGlobalState=>{
      return {...state,workforcesetupsetting:{...state[EGlobalStateStoreNames.WorkforceSetupSetting],data,loading:false,processId:EGlobalStateProcessId.WorkforceSetupSetting,versionId,employeeId,domainCode}}
  }),
  on(showRefreshFeed,(state,{show}):TGlobalState=>{
      return {...state,aux:{...state.aux,refreshFeed:show}}
  }),


);
