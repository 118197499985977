// @ts-nocheck
/*
 *  This file server-error-handler.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { throwError, forkJoin, EMPTY, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { LogErrorsService } from './log-errors';
import { SnackbarAlertService } from '../snackbar-alert.service';
import { ConfigurationService } from '../configuration.service';
import { first, tap, catchError } from 'rxjs/operators';
import { RequestMenuModel } from '../../models/request-menu.model';
import { menuNavigationModel } from '../../constants/routing-constant';

@Injectable()
export class ServerErrorHandler {
    showAnaltyicsDashboard = false;

    constructor(
        private _router: Router,
        private _logError: LogErrorsService,
        private _snackBar: SnackbarAlertService,
        public _config: ConfigurationService,
        private _httpClient: HttpClient,
    ) {

    }

    handleServerError(err: HttpErrorResponse, isUnauthorize?) {
        const isLogin = localStorage.getItem('authlogin');
        if (navigator.onLine) {
            this._logError.logError(err);
            if (isLogin || isUnauthorize) {
                if (err instanceof HttpErrorResponse) { // Changed by shaily after discussed with raju sir
                    if (err.status === 0) {
                        this._snackBar.Alert('You are in low internet bandwidth. Please check and try again', '2');
                        // changed message by shaily after discussed with raju sir
                    } else if (err.status === 426) {
                        if (typeof err.error === 'string') {
                            this._snackBar.Alert(err.error, '2');
                        }
                        this.onRefreshLogon();
                    } else if (err.status !== 306) {
                        console.log('Server', err);
                        if (err.error && err.error.message && err.error.validationType && err.error.validationType == '3') {
                            this._snackBar.Alert(err.error.message, '3');
                        } else if (err.error && err.error.message) {
                            this._snackBar.Alert(err.error.message, '2');
                        } else if (err.error && err.error.error_description) {
                            this._snackBar.Alert(err.error.error_description, '2');
                        } else if (err.error && err.error.error) {
                            if (err.error.error != 'invalid_grant') {
                                this._snackBar.Alert(err.error.error, '2');
                            }
                        } else if (typeof err.error === 'string') {
                            if (!(this.showAnaltyicsDashboard)) {
                                this._snackBar.Alert(err.error, '2');
                            }
                        } else {
                            this._snackBar.Alert('An error occurred: ' + err.name, '2');
                        }
                    }

                } else {
                    if ((err as any).status === 5999) {
                        console.log({ err })
                    }
                    else {
                        this._snackBar.Alert(`Unknown error`, '2');
                    }
                }
            }
        } else {
            this._snackBar.Alert(`Please check your internet connection`, '2');
        }

        return throwError(err);
    }

    onRefreshLogon(): Observable<boolean> {
        if (localStorage.getItem('authlogin')) {
            const url = this._config.base_url + '/api/LogOnUser/refresh/LogOnUser/PageAccessRight/W';
            const headers = { headers: this.getHeaders() };
            return this._httpClient.get<boolean>(url, headers);
        }
        return EMPTY;
    }

    refreshMenus() {
        this.getMenusAndRights().then(() => {
            location.reload();
        });
    }

    getMenusAndRights() {

        return new Promise((resolve, reject) => {

            const MenuModelObject = {
                menuType: 'P',
                parentKey: 9999,
                menuId: 0,
                includeMenu: true
            };

            const MenuModelObject2 = {
                menuType: 'A',
                parentKey: 9999,
                menuId: 0,
                includeMenu: true
            };

            const urlMAR = this._config.base_url + '/api/core/MenuAccess';
            const urlMenus = this._config.base_url + '/api/core/MenuAccess';
            const leftPinUrl = this._config.base_url + '/api/core/MenuAccess/LeftPin';
            const urlShortcutMenus = this._config.base_url + '/api/core/MenuAccess';
            const headers = { headers: this.getHeaders() };

            const menuAction = this._httpClient.get(urlMAR, headers);
            const menuList = this._httpClient.post(urlMenus, MenuModelObject, headers);
            const pinnedMenus = this._httpClient.get(leftPinUrl, headers).pipe(catchError(err => of([])));
            const shortcutMenus = this._httpClient.post(urlShortcutMenus, MenuModelObject2, headers).pipe(catchError(err => of([])));

            forkJoin([menuAction, menuList, pinnedMenus, shortcutMenus])
                .pipe(
                    first(),
                    tap((data: any) => {
                        localStorage.removeItem('recentParentMenusList');
                        if (data[0].menuPermissionRights && data[0].menuPermissionRights.length) {
                            data[0].menuPermissionRights = data[0].menuPermissionRights.map(menu => {
                                menu.navigationCode = (menu.navigationCode || '').toLowerCase();
                                return menu;
                            });
                        }
                        localStorage.setItem('rawMenusWithActions', JSON.stringify(data[0].menuPermissionRights));

                        // Modify raw menus
                        if (data[1].length) {
                            data[1] = data[1].map(menu => {
                                menu.navigationCode = (menu.navigationCode || '').toLowerCase();
                                menu['pinType'] = ['1', '2', '3', '7'].includes(menu.menuId.toString()) ? 'fixed' : 'unpin';
                                menu['isMenuVisibleWithinL0'] = ['1', '2', '3'].includes(menu.menuId.toString()) ? 'true' : menu.menuId == 7 ? '' : 'false';
                                return menu;
                            });
                        }
                        const rawMenus = data[1];
                        try { rawMenus.find(e => e.menuId === 64).sequenceNumber = 6; } catch (e) { }
                        const rawMenusNew = RequestMenuModel.concat(rawMenus);
                        menuNavigationModel.forEach(item => {
                            try {
                                rawMenusNew.find(e => e.navigationCode === item.code).navigationPath = item.path;
                            } catch (e) { }
                        });
                        localStorage.setItem('rawMenus', JSON.stringify(rawMenusNew));
                        // End modify raw menus
                        data[2] = data[2].map(menu => {
                            menu.navigationCode = (menu.navigationCode || '').toLowerCase();
                            menu['pinType'] = 'pinned';
                            menu['isMenuVisibleWithinL0'] = 'true';
                            menu['iconName'] = this.getModuleIconNames(menu.moduleId);
                            return menu;
                        });
                        let pinnedMenusList = data[2];
                        if (!pinnedMenusList.length && data[1].length) {
                            const filteredMenus = data[1].filter(menu => ['6', '5', '4'].includes(menu.menuId.toString()));
                            pinnedMenusList = filteredMenus.map(filteredMenu => {
                                filteredMenu.navigationCode = (filteredMenu.navigationCode || '').toLowerCase();
                                filteredMenu['pinType'] = 'pinnedauto';
                                filteredMenu['isMenuVisibleWithinL0'] = 'true';
                                return filteredMenu;
                            });
                        }
                        menuNavigationModel.forEach(item => {
                            try {
                                pinnedMenusList.find(e => e.navigationCode === item.code).navigationPath = item.path;
                            } catch (e) { }
                        });
                        localStorage.setItem('pinnedMenusList', JSON.stringify(pinnedMenusList));
                        if (data[3].length) {
                            data[3] = data[3].map(menu => {
                                menu.navigationCode = (menu.navigationCode || '').toLowerCase();
                                return menu;
                            });
                        }
                        localStorage.setItem('newAddedMenu', JSON.stringify(data[3]));
                        resolve(true);

                    })
                ).toPromise().then(data => { }).catch(err => {
                    reject(false);
                });

        });

    }

    getHeaders() {
        const authlogin = JSON.parse(localStorage.getItem('authlogin'));
        const accessToken = authlogin ? authlogin.access_token : '';
        const dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._config.domainCode;
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'domainCode': dc,
            'Authorization': 'Bearer ' + accessToken,
            'AccessMode': 'W'
        });

    }

    getModuleIconNames(moduleId: string | number): { icon: string, activeIcon: string } {
        switch (moduleId.toString()) {
            case '20': return { icon: 'fa-light fa-party-horn acc-icon', activeIcon: 'fa-duotone fa-party-horn acc-icon' };
            case '1': return { icon: 'fa-light fa-user-group acc-icon', activeIcon: 'fa-duotone fa-user-group acc-icon' };
            case '2': return { icon: 'fa-light fa-calendars acc-icon', activeIcon: 'fa-duotone fa-calendars acc-icon' };
            case '3': return { icon: 'fa-light fa-money-bills-simple acc-icon', activeIcon: 'fa-duotone fa-money-bills-simple acc-icon' };
            case '4': return { icon: 'fa-light fa-chart-line-up-down acc-icon', activeIcon: 'fa-duotone fa-chart-line-up-down acc-icon' };
            case '5': return { icon: 'fa-light fa-receipt acc-icon', activeIcon: 'fa-duotone fa-receipt acc-icon' };
            case '6': return { icon: 'fa-light fa-headphones acc-icon', activeIcon: 'fa-duotone fa-headphones acc-icon' };
            case '7': return { icon: 'fa-light fa-laptop-mobile acc-icon', activeIcon: 'fa-duotone fa-laptop-mobile acc-icon' };
            case '9': return { icon: 'fa-light fa-handshake acc-icon', activeIcon: 'fa-duotone fa-handshake acc-icon' };
            default: return { icon: 'fa-light fa-user acc-icon', activeIcon: 'fa-duotone fa-user acc-icon' };
        }
    }
}
