/*
 *  This file global-state.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2024
 *  (C) 2015-2024. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */


import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { EGlobalStateProcessId, EGlobalStateStoreNames, GLOBAL_API_URLS, hroneIndexDbConfig, TContestEnableDisableResponse, TCoreFeatureValidate, TFeedData, TWorkforceSetupSettingResponse } from './global.constants';
import { HttpService } from 'src/app/global/services/http-service';
import { FeedPayloadState, IFeedResponseExtended, TFeedCountResponse } from 'src/app/modules/enterprise-wall/social-enterpise-wall/social-wall.model';
import { TSubscribeModuleResponse } from 'src/app/modules/team/team-dashboards/team-dashboards.model';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { TGlobalState, TGlobalStateProcessVersion } from './global.reducer';
import { catchError, filter, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { enterpriseEnableDisableSuccess, employeecontestEnableDisableSuccess, loadBadgeFeedSuccess, subscribeModuleSuccess, validateFeatureSuccess, workforceSettingSuccess, loadFeedCountSuccess, showRefreshFeed } from './global.action';
import { LogonUserDetailModel } from 'src/app/global/models/common.models';
import { MomentService } from 'src/app/global/services/moment.service';


@Injectable({
  providedIn: 'root'
})
export class HroneGlobalStateService {
  validateHash = new Map<number, TCoreFeatureValidate>();
  deleteDatabaseSuccess = false;
  versionhash = new Map<number, string>();
  private readonly workforceSetupSettings$: Subject<TWorkforceSetupSettingResponse[] | null> = new Subject();
  private _isWorkforceSettingCalled = false;
  public get isWorkforceSettingCalled() {
    return this._isWorkforceSettingCalled;
  }
  public set isWorkforceSettingCalled(value) {
    this._isWorkforceSettingCalled = value;
  }
  readonly VERSION_DETAIL = '/api/core/DataVersioning/VersionDetail' as const;
  constructor(
    private readonly http: HttpService,
    public indexdb: NgxIndexedDBService,
    public store: Store<{hroneGlobal:TGlobalState}>,
    private _moment: MomentService,
  ) { }

  handleVersionDetails(value: { currentVersion: string; processId: number; }[]) {
    value.forEach(({ currentVersion, processId }) => {
      this.versionhash.set(processId, currentVersion);
    });

  }

  getVersionDetails(): Observable<Array<{ currentVersion: string, processId: number }>> {
    const bs = new BehaviorSubject([] as Array<{ currentVersion: string, processId: number }>);
    const authStr = localStorage.getItem('authlogin')
    return authStr ? this.http.Get(this.VERSION_DETAIL) : bs.asObservable();
  }
  private fetchEnterPriseContestEnableDisable(): Observable<TContestEnableDisableResponse> {
    return this.http.Get(GLOBAL_API_URLS[EGlobalStateProcessId.EnterpriseContest].CONTEST_ENABLE_DISABLE);
  }
  private fetchEmployeeContestEnableDisable(id: number): Observable<{ disabled: boolean }> {
    return this.http.Get(`${GLOBAL_API_URLS[EGlobalStateProcessId.EmployeeContest].EMPLOYEECONTESTENABLEDISABLESTATUS}/${id}`);
  }


  private fetchBadgeFeed(payloadState: FeedPayloadState): Observable<Array<IFeedResponseExtended>> {
    return this.http.Post(GLOBAL_API_URLS[EGlobalStateProcessId.Feed].BADGE_FEED, payloadState);
  }
  private fetchFeedCount(): Observable<TFeedCountResponse> {
    return this.http.Get(GLOBAL_API_URLS[EGlobalStateProcessId.Feed].FEED_COUNT);
  }


  private fetchSubscriberModules(): Observable<TSubscribeModuleResponse> {
    return this.http.Get(GLOBAL_API_URLS[EGlobalStateProcessId.SubscriberModules].SUBSCRIBE_MODULE);
  }


  private fetchWorkforceSetupSetting(settingId: number, activeStatus: string): Observable<TWorkforceSetupSettingResponse> {
    return this.http.Get(`${GLOBAL_API_URLS[EGlobalStateProcessId.WorkforceSetupSetting].WORKFORCE_SETUP_SETTING}/${settingId}/${activeStatus}`);
  }


  private fetchAllWorkforceSetupSettings(): Observable<Array<TWorkforceSetupSettingResponse>> {
    this.isWorkforceSettingCalled = true;
    return this.http.Get(`${GLOBAL_API_URLS[EGlobalStateProcessId.WorkforceSetupSetting].WORKFORCE_SETUP_SETTING_ALL}`).pipe(
      tap(() => {
        this.isWorkforceSettingCalled = false;
      })
    );
  }


  private fetchFeatureValidation(featureId: number): Observable<TCoreFeatureValidate> {
    return this.http.Get(`${GLOBAL_API_URLS[EGlobalStateProcessId.ValidateFeature].VALIDATE_FEATURE}${featureId}`);
  }

  contestEnableDisable(): Observable<TContestEnableDisableResponse> {
    const { employeeId, domainCode } = this.logOnUserDetails();
    return this.getEntryByStoreNameAndKey<TGlobalStateProcessVersion<{
      data: TContestEnableDisableResponse,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.EnterpriseContest).pipe(
      switchMap(
        (cache) => {
          if (cache && this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date)) {
            return of(cache.data)
          }
          return this.fetchEnterPriseContestEnableDisable().pipe(
            tap(data => {
              this.store.dispatch(enterpriseEnableDisableSuccess({ data, storename: EGlobalStateStoreNames.EnterpriseContest, versionId: this.versionhash.get(EGlobalStateProcessId.EnterpriseContest) ?? "1", domainCode, employeeId }))
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchEnterPriseContestEnableDisable().pipe(
          tap(data => {
            this.store.dispatch(enterpriseEnableDisableSuccess({ data, storename: EGlobalStateStoreNames.EnterpriseContest, versionId: this.versionhash.get(EGlobalStateProcessId.EnterpriseContest) ?? "1", domainCode, employeeId }))
          })
        );
      })
    )

  }
  contestEmployeeEnableDisable(id: number): Observable<{ disabled: boolean }> {
    const { employeeId, domainCode } = this.logOnUserDetails();
    return this.getEntryByStoreNameAndKey<TGlobalStateProcessVersion<{
      data: { disabled: boolean },
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.EmployeeContest).pipe(
      switchMap(
        (cache) => {
          if (cache && this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date) && 'disabled' in cache.data) {
            return of(cache.data)
          }
          return this.fetchEmployeeContestEnableDisable(id).pipe(
            tap(data => {
              this.store.dispatch(employeecontestEnableDisableSuccess({ data, storename: EGlobalStateStoreNames.EmployeeContest, versionId: this.versionhash.get(EGlobalStateProcessId.EmployeeContest) ?? "1", domainCode, employeeId }))
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchEmployeeContestEnableDisable(id).pipe(
          tap(data => {
            this.store.dispatch(employeecontestEnableDisableSuccess({ data, storename: EGlobalStateStoreNames.EmployeeContest, versionId: this.versionhash.get(EGlobalStateProcessId.EmployeeContest) ?? "1", domainCode, employeeId }))
          })
        );
      })
    )

  }
  getBadgeFeed(payloadState: FeedPayloadState, employeeId: number, domainCode: string, refreshFeedSignalR: boolean | undefined): Observable<Array<IFeedResponseExtended>> {
    if (refreshFeedSignalR) {
      return this.fetchBadgeFeed(payloadState).pipe(
        tap(data => {
          this.store.dispatch(loadBadgeFeedSuccess({ data, storename: EGlobalStateStoreNames.Feed, versionId: this.versionhash.get(EGlobalStateProcessId.Feed) ?? "1", domainCode, employeeId }))
        }),
      );
    }
    return this.getEntryByStoreNameAndKey<TGlobalStateProcessVersion<{
      data: TFeedData,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.Feed).pipe(
      switchMap(
        (cache) => {
          const versionStr = this.versionhash.get(cache.processId) ?? "1.0";
          const cacheVersionStr = cache?.data?.feedCards?.versionId ?? "1.0";
          const [currMajor,currMinor] = versionStr.split(".").map(item=>Number(item));
          const [cacheMajor,cacheMinor] = cacheVersionStr.split(".").map(item=>Number(item));
          const minorDiff = Math.abs(currMinor-cacheMinor);
          const isCacheValid = currMajor === cacheMajor && minorDiff<5;
          const isGeneralShiftStartTime = this._moment.isTimeBetweenStartAndEnd(8,11,0,0,'minutes','[]')
          this.store.dispatch(showRefreshFeed({show:minorDiff > 0 && (isCacheValid || isGeneralShiftStartTime)}));
          if (cache && cache?.data?.feedCards?.cards?.length && cache.employeeId === employeeId &&
            domainCode === cache.domainCode && (isCacheValid || isGeneralShiftStartTime) ) {
            return of(cache.data.feedCards.cards)
          }

          return this.fetchBadgeFeed(payloadState).pipe(
            tap(data => {
              this.store.dispatch(loadBadgeFeedSuccess({ data, storename: EGlobalStateStoreNames.Feed, versionId: this.versionhash.get(EGlobalStateProcessId.Feed) ?? "1", domainCode, employeeId }))
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchBadgeFeed(payloadState).pipe(
          tap(data => {
            this.store.dispatch(loadBadgeFeedSuccess({ data, storename: EGlobalStateStoreNames.Feed, versionId: this.versionhash.get(EGlobalStateProcessId.Feed) ?? "1", domainCode, employeeId }))
          })
        );
      })
    )

  }
  getFeedCount(): Observable<TFeedCountResponse> {
    const { employeeId, domainCode } = this.logOnUserDetails();
    return this.getEntryByStoreNameAndKey<TGlobalStateProcessVersion<{
      data: TFeedData,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.Feed).pipe(
      switchMap(
        (cache) => {
          const majorVersionMatch = this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date);
          const subVersionMatch = this.shouldGetFromCache(cache?.data.countData?.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date);
          if (cache && cache?.data?.countData?.countInfo && majorVersionMatch && subVersionMatch) {
            return of(cache.data.countData.countInfo)
          }
          return this.fetchFeedCount().pipe(
            tap(data => {
              this.store.dispatch(loadFeedCountSuccess({ data, storename: EGlobalStateStoreNames.Feed, versionId: this.versionhash.get(EGlobalStateProcessId.Feed) ?? "1", domainCode, employeeId }))
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchFeedCount().pipe(
          tap(data => {
            this.store.dispatch(loadFeedCountSuccess({ data, storename: EGlobalStateStoreNames.Feed, versionId: this.versionhash.get(EGlobalStateProcessId.Feed) ?? "1", domainCode, employeeId }))
          })
        );
      })
    );
  }

  subscribeModule(): Observable<TSubscribeModuleResponse> {
    const { employeeId, domainCode } = this.logOnUserDetails();
    return this.getEntryByStoreNameAndKey<TGlobalStateProcessVersion<{
      data: TSubscribeModuleResponse,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.SubscriberModules).pipe(
      switchMap(
        (cache) => {

          if (cache && this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date)) {

            return of(cache.data)
          }
          return this.fetchSubscriberModules().pipe(
            tap(data => {
              this.store.dispatch(subscribeModuleSuccess({ data, storename: EGlobalStateStoreNames.SubscriberModules, versionId: this.versionhash.get(EGlobalStateProcessId.SubscriberModules) ?? "1", domainCode, employeeId }))
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchSubscriberModules()
      })
    )

  }


  logOnUserDetails(): LogonUserDetailModel {
    const userInLocalStoratge = localStorage.getItem('logOnUserDetails');
    return userInLocalStoratge ? JSON.parse(userInLocalStoratge) : { employeeId: null, domainCode: null };
  }

  getWorkforceSetupSetting(settingId: number, activeStatus: '0,1' | '0' | '1' = '0,1'): Observable<TWorkforceSetupSettingResponse | null> {

    const { employeeId, domainCode } = this.logOnUserDetails();
    const sortedActiveStatus = activeStatus ? activeStatus
      .split(',')
      .sort((a, b) => Number(a) - Number(b))
      .join(',') : null;

    return this.getEntryByStoreNameAndKey<TGlobalStateProcessVersion<{
      data: Map<number, TWorkforceSetupSettingResponse>,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.WorkforceSetupSetting).pipe(
      switchMap(cache => {
        if (cache && this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date)) {
          const activeInactive = cache.data.get(settingId);
          if (!activeInactive) {
            return this.fetchWorkforceSetupSetting(settingId, activeStatus)
          }
          else {

            if (sortedActiveStatus === '0,1') {
              return of(activeInactive || null);
            } else if (sortedActiveStatus === '0') {
              const result = activeInactive?.isActive ? null : activeInactive;
              return of(result);
            } else if (sortedActiveStatus === '1') {
              const result = activeInactive?.isActive ? activeInactive : null;
              return of(result);
            }
          }

          return of(null);
        }

        return this.fetchAndCacheAllWorkforceSetupSettings().pipe(
          map((data: TWorkforceSetupSettingResponse[]) => {
            const newData = new Map<number, TWorkforceSetupSettingResponse>();
            let setting: TWorkforceSetupSettingResponse | null = null;

            data.forEach(s => {
              newData.set(s.settingId, s);
              if (s.settingId === settingId) {
                setting = s;
              }
            });

            this.store.dispatch(workforceSettingSuccess({
              data: newData,
              storename: EGlobalStateStoreNames.WorkforceSetupSetting,
              versionId: this.versionhash.get(EGlobalStateProcessId.WorkforceSetupSetting) ?? "1",
              domainCode,
              employeeId
            }));

            return setting;
          }),
          catchError(() => {
            return this.fetchAllWorkforceSetupSettings().pipe(
              map((data: TWorkforceSetupSettingResponse[]) => {
                this.workforceSetupSettings$.next(data);
                this.isWorkforceSettingCalled = false;
                const newData = new Map<number, TWorkforceSetupSettingResponse>();
                let setting: TWorkforceSetupSettingResponse | null = null;

                data.forEach(s => {
                  newData.set(s.settingId, s);
                  if (s.settingId === settingId) {
                    setting = s;
                  }
                });

                this.store.dispatch(workforceSettingSuccess({
                  data: newData,
                  storename: EGlobalStateStoreNames.WorkforceSetupSetting,
                  versionId: this.versionhash.get(EGlobalStateProcessId.WorkforceSetupSetting) ?? "1",
                  domainCode,
                  employeeId
                }));

                return setting;
              }),
            )
          })
        );
      }),
      catchError(() => {
        return this.fetchAndCacheAllWorkforceSetupSettings().pipe(
          map((data: TWorkforceSetupSettingResponse[]) => {
            const newData = new Map<number, TWorkforceSetupSettingResponse>();
            let setting: TWorkforceSetupSettingResponse | null = null;

            data.forEach(s => {
              newData.set(s.settingId, s);
              if (s.settingId === settingId) {
                setting = s;
              }
            });

            this.store.dispatch(workforceSettingSuccess({
              data: newData,
              storename: EGlobalStateStoreNames.WorkforceSetupSetting,
              versionId: this.versionhash.get(EGlobalStateProcessId.WorkforceSetupSetting) ?? "1",
              domainCode,
              employeeId
            }));

            return setting;
          })
        );
      })
    );

  }


  validateFeature(featureId: number): Observable<TCoreFeatureValidate> {
    const { domainCode, employeeId } = this.logOnUserDetails();
    return this.getEntryByStoreNameAndKey<TGlobalStateProcessVersion<{
      data: Map<number, TCoreFeatureValidate>,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.ValidateFeature).pipe(
      mergeMap(
        (cache) => {
          this.validateHash = cache.data ?? new Map<number, TCoreFeatureValidate>();

          const validity = cache.data.get(featureId);
          const shouldCall =  this.shouldGetFromCache(cache.versionId, EGlobalStateProcessId.ValidateFeature, cache.employeeId, cache.domainCode, cache.date);
          if (validity && shouldCall) {
            return of(validity)
          }
          if(!shouldCall){
            this.validateHash = new Map<number, TCoreFeatureValidate>();
          }
          return this.fetchFeatureValidation(featureId).pipe(
            tap(result => {

              this.validateHash.set(featureId, result);
              this.store.dispatch(validateFeatureSuccess({ data: this.validateHash, storename: EGlobalStateStoreNames.ValidateFeature, versionId: this.versionhash.get(EGlobalStateProcessId.ValidateFeature) ?? "1", domainCode, employeeId }))
            }),
            catchError(err => {
              return throwError(() => err);
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchFeatureValidation(featureId).pipe(
          tap(result => {
            this.validateHash = new Map<number, TCoreFeatureValidate>();
            this.validateHash.set(featureId, result);
            this.store.dispatch(validateFeatureSuccess({ data: this.validateHash, storename: EGlobalStateStoreNames.ValidateFeature, versionId: this.versionhash.get(EGlobalStateProcessId.ValidateFeature) ?? '1', domainCode, employeeId }))
          })
        );
      })
    )

  }

  shouldGetFromCache(versionId: string | undefined,
    processID: number | undefined,
    employeeIdcache: number | undefined,
    domainCodecache: string | undefined, date: string | Date | undefined): boolean {
    const { employeeId, domainCode } = this.logOnUserDetails();
    if (!processID || !date) return false;
    return employeeIdcache === employeeId &&
      domainCode === domainCodecache &&
      this.versionhash.get(processID) === versionId
    // asked to comment by kashish sir.
    // &&
    // this._moment.getMomentFromDate(new Date()).diff(this._moment.getMomentFromDate(date),'hours') < 8;
  }
  private fetchAndCacheAllWorkforceSetupSettings(): Observable<TWorkforceSetupSettingResponse[]> {
    if (!this.isWorkforceSettingCalled) {
      this.workforceSetupSettings$.next(null);
      return this.fetchAllWorkforceSetupSettings().pipe(
        map((data) => {
          this.workforceSetupSettings$.next(data);
          return data;
        }),
        finalize(() => {
          this.isWorkforceSettingCalled = false;
        })
      )
    }

    return this.workforceSetupSettings$.pipe(
      filter(v => v !== null),
      take(1),
    ) as unknown as Observable<TWorkforceSetupSettingResponse[]>;
  }



  private checkIfStoreExists(storeName: string): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      const version = hroneIndexDbConfig.version;
      const dbName = hroneIndexDbConfig.name;
      const request = indexedDB.open(dbName, version);

      request.onsuccess = () => {
        const db = request.result;
        const exists = db.objectStoreNames.contains(storeName);
        db.close();
        subscriber.next(exists);
        subscriber.complete();
      };
      request.onupgradeneeded = () => {
        console.error({ onupgradeneeded: "onupgradeneeded" }, new Date())
        const targetSchema = hroneIndexDbConfig.objectStoresMeta.find(meta => meta.store === storeName);
        if (!targetSchema) return;
        const db = request.result;
        if (!db.objectStoreNames.contains(storeName)) {
          const objectStore = db.createObjectStore(storeName, targetSchema.storeConfig);
          targetSchema.storeSchema.forEach((index) => {
            objectStore.createIndex(index.name, index.keypath, index.options || {});
          });
          console.info(`Object store "${storeName}" and its indexes created successfully.`);
        }
      }
      request.onerror = () => {
        console.error('Error checking store existence:', request.error);
        subscriber.error(request.error);
      };
    });
  }

  private ensureStoreExists(storeName: string): Observable<void> {
    return this.checkIfStoreExists(storeName).pipe(
      switchMap((exists) => {
        if (exists) {
          this.deleteDatabaseSuccess = false;

        } else if (!this.deleteDatabaseSuccess) {
          this.indexdb.deleteDatabase().subscribe({
            next: () => {
              this.deleteDatabaseSuccess = true;
            },
            error: (error) => {
              console.error(error);

            }
          });

        }
        return of(void 0);
      }),
      catchError((err) => {
        console.error(`Error ensuring store ${storeName} exists:`, err);
        return throwError(() => new Error(`Failed to ensure store ${storeName} exists`));
      })
    );
  }

  getEntryByStoreNameAndKey<T>(storeName: string, key: string | number): Observable<T> {
    return this.ensureStoreExists(storeName).pipe(
      switchMap(() => this.indexdb.getByID<T>(storeName, key)),
      catchError((err) => {
        console.error(`Error getting entry by key ${key} in store ${storeName}:`, err);
        throw new Error(`Failed to retrieve entry with key ${key} from store ${storeName}`);
      })
    );
  }

  getAllEntries<T = any>(storeName: string): Observable<T[]> {
    return this.ensureStoreExists(storeName).pipe(
      switchMap(() => this.indexdb.getAll<T>(storeName)),
      catchError((err) => {
        console.error(`Error getting all entries from store ${storeName}:`, err);
        throw new Error(`Failed to retrieve entries from store ${storeName}`);
      })
    );
  }

  updateEntry<T>(storeName: string, data: T): Observable<T> {
    return this.ensureStoreExists(storeName).pipe(
      switchMap(() => this.indexdb.update(storeName, { ...data, date: new Date() })),
      catchError((err) => {
        console.error(`Error updating entry in store ${storeName}:`, err);
        throw new Error(`Failed to update entry in store ${storeName}`);
      })
    );
  }

  addEntry<T>(storeName: string, data: T): Observable<T> {
    return this.ensureStoreExists(storeName).pipe(
      switchMap(() => this.indexdb.add(storeName, { ...data, date: new Date() })),
      catchError((err) => {
        console.error(`Error adding entry to store ${storeName}:`, err);
        throw new Error(`Failed to add entry to store ${storeName}`);
      })
    );
  }
}
