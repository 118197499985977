
/* 
 *  This file global-search.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { MENU_CODES } from '../../global/constants/menu-codes.constant';

export type actionPageType = 'global' | 'team' | 'new_team_action';


export const searchRoutingMenu: { code: string, path?: string, teamPath?: string, newTeamPath?: string }[] = [
  {
    code: MENU_CODES.PROCESS.PROFILE,
    path: '/app/processes/search/employees'
  },
  {
    code: 'addupcomingjoin_732',
    path: '/app/processes/search/upcomingemployees'
  },
  {
    code: 'action_121',
    path: '/app/processes/search/employees'
  },
  {
    code: MENU_CODES.CORE.PAGE_ACCESS,
    path: '/app/processes/search/pageaccess'
  },
  {
    code: MENU_CODES.CORE.DATA_ACCESS,
    path: '/app/processes/search/dataaccess'
  },
  {
    code: MENU_CODES.CORE.ROLES,
    path: '/app/processes/search/roles'
  },
  {
    code: MENU_CODES.PROCESS.CONFIRM,
    path: '/app/processes/search/confirmation'
  },
  {
    code: MENU_CODES.PROCESS.ACCEPT_RESIGNATION,
    path: '/app/processes/search/resignationacceptanceletter'
  },
  {
    code: MENU_CODES.PROCESS.RESIGN,
    path: '/app/processes/search/separation',
    teamPath: '/app/team/myteam/separation',
    newTeamPath: '/app/team/team-people/separation'
  },
  {
    code: 'cancelresignation_156',
    path: '/app/processes/search/cancelseparation'
  },
  {
    code: MENU_CODES.PROCESS.CLEARANCE,
    path: '/app/processes/search/clearance'
  },
  {
    code: MENU_CODES.PROCESS.FINALCLEARANCE,
    path: '/app/processes/search/finalclearance'
  },
  {
    code: MENU_CODES.PROCESS.EXIT_INT,
    path: '/app/processes/search/exitinterview'
  },
  {
    code: MENU_CODES.PROCESS.SAL_STRUC,
    path: '/app/processes/search/salarystructure'
  },

  {
    code: MENU_CODES.PROCESS.EMP_LETTER,
    path: '/app/processes/search/employeeletter'
  },
  {
    code: MENU_CODES.PROCESS.CONFIRMATION_LETTER,
    path: '/app/processes/search/confirmationletter'
  },

  {
    code: MENU_CODES.PROCESS.TERMINATION_LETTER,
    path: '/app/processes/search/terminationletter'
  },
  {
    code: MENU_CODES.PROCESS.EXTENSION_LETTER,
    path: '/app/processes/search/extensionletter'
  },
  {
    code: MENU_CODES.PROCESS.OTHER_LETTER,
    path: '/app/processes/search/otherletter'
  },
  {
    code: MENU_CODES.PROCESS.RELIEVING_LETTER,
    path: '/app/processes/search/relievingletter'
  },
  {
    code: MENU_CODES.PROCESS.APPOINMENT_LETTER,
    path: '/app/processes/search/appointmentletter'
  },
  {
    code: MENU_CODES.PROCESS.REIMBURSEMENT,
    path: '/app/processes/search/reimbursement',
    teamPath: '/app/team/myteam/reimbursement',
    newTeamPath: '/app/team/team-people/reimbursement'
  },
  {
    code: MENU_CODES.PROCESS.LOAN,
    path: '/app/processes/search/loan',
    teamPath: '/app/team/myteam/loan',
    newTeamPath: '/app/team/team-people/loan'
  },
  {
    code: MENU_CODES.PROCESS.LEAVEENCASHMENT,
    path: '/app/processes/search/leaveencashment'
  },
  {
    code: MENU_CODES.PROCESS.SALATYYTD,
    path: '/app/processes/search/salaryytd'
  },

  {
    code: MENU_CODES.PROCESS.ATTENDANCE,
    path: '/app/processes/search/attendance'
  },
  {
    code: MENU_CODES.PROCESS.PROCESS_ATT,
    path: '/app/processes/search/processattendance'
  },
  {
    code: MENU_CODES.PROCESS.SHIFT,
    path: '/app/processes/search/shift'
  },
  {
    code: MENU_CODES.PROCESS.BONUS_PROCESS,
    path: '/app/processes/search/bonusprocess'
  },
  {
    code: 'purposedinvestmentdeclaration_114',
    path: '/app/processes/search/proinvestment',
    teamPath: '/app/team/myteam/proinvestment',
    newTeamPath: '/app/team/team-people/proinvestment'
  },
  {
    code: 'confirmedinvestmentdeclaration_181',
    path: '/app/processes/search/coninvestment',
    teamPath: '/app/team/myteam/coninvestment',
    newTeamPath: '/app/team/team-people/coninvestment'
  },
  {
    code: 'restrictedholiday_97',
    path: '/app/processes/search/restrictedholiday/',
    teamPath: '/app/team/myteam/restrictedholiday/',
    newTeamPath: '/app/team/team-people/restrictedholiday/'
  },
  {
    code: 'attendanceregularization_95',
    path: '/app/processes/search/attendanceregularization/',
    teamPath: '/app/team/myteam/attendanceregularization/',
    newTeamPath: '/app/team/team-people/attendanceregularization/'
  },
  {
    code: 'attendanceregularizationcancel_100',
    path: '/app/processes/search/attendanceregularizationcancel/'
  },
  {
    code: 'onduty_96',
    path: '/app/processes/search/onduty/',
    teamPath: '/app/team/myteam/onduty/',
    newTeamPath: '/app/team/team-people/onduty/'
  },
  {
    code: 'ondutycancel_101',
    path: '/app/processes/search/ondutycancel/'
  },
  {
    code: MENU_CODES.PROCESS.ANNOUNCEMENT,
    path: '/app/processes/search/announcement/'
  },
  {
    code: MENU_CODES.PROCESS.HR_POLICY,
    path: '/app/processes/search/policy/'
  },
  {
    code: 'generateletter_425',
    path: '/app/processes/search/otherlettergeneration'
  },
  {
    code: 'leave_98',
    path: '/app/processes/search/leave',
    teamPath: '/app/team/myteam/leave/',
    newTeamPath: '/app/team/team-people/leave/'
  },
  {
    code: 'leavecancel_102',
    path: '/app/processes/search/leavecancel'
  },
  {
    code: 'leavebalance_105',
    path: '/app/processes/search/leavebalance'
  },
  {
    code: MENU_CODES.PROCESS.BULK_PASS,
    path: '/app/processes/search/sendbulkpassword'
  },
  {
    code: MENU_CODES.PROCESS.LEAVE_CARRY_FORWARD,
    path: '/app/processes/search/leavecarryforward'
  },
  {
    code: MENU_CODES.SETUP.COMPENSATORYOFF,
    path: '/app/processes/search/compoff'
  },
  {
    code: MENU_CODES.SETUP.OVERTIME,
    path: '/app/processes/search/overtime'
  },
  {
    code: MENU_CODES.SETUP.SHORTLEAVE,
    path: '/app/processes/search/shortleave',
    teamPath: '/app/team/myteam/shortleave/',
    newTeamPath: '/app/team/team-people/shortleave/'
  },
  {
    code: MENU_CODES.SETUP.SHORTLEAVECANCEL,
    path: '/app/processes/search/shortleavecancel'
  },
  {
    code: MENU_CODES.PROCESS.ATT_YTD,
    path: '/app/processes/search/attendanceytd'
  },
  {
    code: 'settlement_117',
    path: '/app/processes/search/settlement'
  },
  {
    code: MENU_CODES.PROCESS.MARK_ATTENDANCE,
    path: '/app/processes/search/markattendance'
  },
  {
    code: MENU_CODES.PROCESS.SHIFT_CHANGE,
    path: '/app/processes/search/shiftchange'
  },
  {
    code: MENU_CODES.PROCESS.CHANGE_MANAGER,
    path: '/app/processes/search/changemanager'
  },
  {
    code: MENU_CODES.TEAM.MARK_ATTENDANCE_TEAM,
    teamPath: '/app/team/myteam/attendancechange',
    newTeamPath: '/app/team/team-people/attendancechange'
  },
  {
    code: MENU_CODES.TEAM.BULK_SHIFT_CHANGE,
    teamPath: '/app/team/myteam/bulkshiftchange',
    newTeamPath: '/app/team/team-people/bulkshiftchange'
  },
  {
    code: MENU_CODES.SETUP.MARKATTENDNACE,
    path: '/app/processes/search/markattendancesearch'
  },
  {
    code: MENU_CODES.SETUP.LOCATIONAPPROVAL,
    path: '/app/processes/search/locationapproval'
  },
  {
    code: MENU_CODES.SETUP.LOCATIONAPPROVAL,
    path: '/app/processes/search/locationapproval'
  },
  {
    code: MENU_CODES.SETUP.HELP_DESK_TICKET,
    path: '/app/processes/search/helpdesk',
    teamPath: '/app/team/myteam/helpdesk/',
    newTeamPath: '/app/team/team-people/helpdesk/'
  },
  {
    code: 'helpdesk_560',
    path: '/app/processes/search/helpdesk',
  },
  {
    code: MENU_CODES.SETUP.NOMINATION,
    path: '/app/processes/search/nomination360',
  },
  {
    code: '360feedback_606',
    path: '/app/processes/search/feedback360',
  },
  {
    code: 'badges_434',
    path: '/app/processes/search/badges'
  },
  {
    code: 'rewardpoints_435',
    path: '/app/processes/search/points'
  },
  {
    code: 'oneonon_635',
    path: '/app/processes/search/oneonone'
  },
  {
    code: 'goals_680',
    path: '/app/processes/search/goal'
  },
  {
    code: 'performancereview_432',
    path: '/app/processes/search/review'
  },
  {
    code: MENU_CODES.PROCESS.TRANSFER_REWARD_POINT,
    path: '/app/processes/search/transferrewardpoints'
  },
  {
    code: 'expenseadvance_496',
    path: '/app/processes/search/expenseadvance',
    teamPath: '/app/team/myteam/expenseadvance/',
    newTeamPath: '/app/team/team-people/expenseadvance/'
  },
  {
    code: MENU_CODES.PROCESS.DISPENSEADVANCE,
    path: '/app/processes/search/dispenseadvance'
  },

  {
    code: MENU_CODES.PROCESS.EXTENDGOAL,
    path: '/app/processes/search/extendgoal'
  },
  {
    code: MENU_CODES.PROCESS.EXTENDREVIEW,
    path: '/app/processes/search/extendreview'
  },
  {
    code: MENU_CODES.PROCESS.EXTENDTHREESIXTY,
    path: '/app/processes/search/extendthreesixty'
  },
  {
    code: MENU_CODES.PROCESS.ONBOARDINGSEARCH,
    path: '/app/processes/search/onboarding'
  },
  {
    code: MENU_CODES.PROCESS.PREBOARDING,
    path: '/app/processes/search/preboarding'
  },
  {
    code: MENU_CODES.PROCESS.EXPENSE_RECEIPT,
    path: '/app/processes/search/expensereceipt',
    teamPath: '/app/team/myteam/expensereceipt',
    newTeamPath: '/app/team/team-people/expensereceipt'
  },
  {
    code: MENU_CODES.PROCESS.ADD_CANDIDATE,
    path: '/app/processes/search/addcandidate'
  },
  {
    code: 'expensereport_812',
    path: '/app/processes/search/expensereceipt'
  },
  {
    code: MENU_CODES.PROCESS.JOB_OPENING,
    path: '/app/processes/search/jobopening',
    teamPath: '/app/team/myteam/jobopening',
    newTeamPath: '/app/team/team-people/jobopening'
  },
  {
    code: MENU_CODES.PROCESS.EXPENSE_ADVANCE_RECOVERY,
    path: '/app/processes/search/expenseadvancerecovery'
  },
  {
    code: MENU_CODES.PROCESS.EXPENSE_PAYMENT,
    path: '/app/processes/search/expensepayment'
  },
  {
    code: MENU_CODES.PROCESS.ATTENDANCE_CALENDAR,
    path: '/app/processes/search/attendancecalendar'
  },
  {
    code: MENU_CODES.PROCESS.PULSE_SURVEY,
    path: '/app/processes/search/pulsesurvey'
  },

  {
    code: MENU_CODES.PROCESS.CHANGE_GOAL,
    path: '/app/processes/search/changegoal',
    teamPath: '/app/team/myteam/changegoal',
    newTeamPath: '/app/team/team-people/changegoal'
  },
  {
    code: MENU_CODES.PROCESS.TIMESHEET,
    path: '/app/processes/search/timesheet',
  },
  {
    code: MENU_CODES.PROCESS.TIMESHEET_REQUEST,
    teamPath: '/app/team/myteam/timesheet',
    newTeamPath: '/app/team/team-people/timesheet'
  },
  {
    code: MENU_CODES.PROCESS.EXCEPTIONAL_LEAVE,
    path: '/app/processes/search/exceptionalleave',
  },
  {
    code: MENU_CODES.PROCESS.POSITION_ASSIGNED,
    path: '/app/processes/search/positionassigned',
  },
  {
    code: MENU_CODES.PROCESS.GEO_LOCATION,
    path: '/app/processes/search/geolocation',
  },
  {
    code: MENU_CODES.PROCESS.TRANSFER,
    path: '/app/processes/search/transfer',
    teamPath: '/app/team/myteam/transfer',
    newTeamPath: '/app/team/team-people/transfer'
  },
  {
    code: MENU_CODES.PROCESS.DO_TRANSFER,
    path: '/app/processes/search/dotransfer',
  },
  {
    code: MENU_CODES.PROCESS.PERFORMANCE_LETTER,
    path: '/app/processes/search/performanceletter',
  },
  {
    code: MENU_CODES.PROCESS.FBP_HISTORY,
    path: '/app/processes/search/fbphistory',
  },
  {
    code: MENU_CODES.PROCESS.PUBLISH_LETTER,
    path: '/app/processes/search/publishletter'
  },
  {
    code: MENU_CODES.PROCESS.WELCOME_NOTES,
    path: '/app/processes/search/welcomenotes'
  },
  {
    code: MENU_CODES.PROCESS.SALARY_REVISION_LETTER,
    path: '/app/processes/search/salaryrevisionletter'
  },
  {
    code: MENU_CODES.PROCESS.REIMBURSHMENT_CANCEL,
    path: '/app/processes/search/cancelreimburshment'
  },
  {
    code: MENU_CODES.PROCESS.FUNDRAISER_CAMPAIGN,
    path: '/app/processes/search/fundraisercampaign'
  },
  {
    code: MENU_CODES.PROCESS.FEATURE_REQUEST,
    path: '/app/processes/search/featurerequest'
  },

  {
    code: MENU_CODES.PROCESS.PIP,
    path: '/app/processes/search/pip',
    teamPath: '/app/team/myteam/pip',
    newTeamPath: '/app/team/team-people/pip'
  },
  {
    code: MENU_CODES.PROCESS.CANDIDATEENGAGE,
    path: '/app/processes/search/candidateengagement'
  },
  {
    code: MENU_CODES.PROCESS.INITIATEGOALCYCLE,
    path: '/app/processes/search/initiategoalcycle'
  },
  {
    code: MENU_CODES.PROCESS.INITIATEREVIEWCYCLE,
    path: '/app/processes/search/initiatereviewcycle'
  },
  {
    code: MENU_CODES.PROCESS.VARIABLE,
    path: '/app/processes/search/variable',
    teamPath: '/app/team/myteam/variable',
    newTeamPath: '/app/team/team-people/variable'
  },
  {
    code: MENU_CODES.PROCESS.ADD_HARDWARE_INVENTORY,
    path: '/app/processes/search/addhardwareinventory'
  },
  {
    code: 'inventory_986',
    path: '/app/processes/search/addhardwareinventory'
  },
  {
    code: MENU_CODES.PROCESS.FEATURE_REQUEST,
    path: '/app/processes/search/featurerequest'
  },
  {
    code: MENU_CODES.PROCESS.CREATECOMPANYGOAL,
    path: '/app/processes/search/createcompanygoal'
  },
  {
    code: MENU_CODES.PROCESS.PIP_EVALUATION,
    path: '/app/processes/search/pipevaluation',
  },
  {
    code: MENU_CODES.PROCESS.BOOKTRAVEL,
    path: '/app/processes/search/booktravel',
  },
  {
    code: MENU_CODES.PROCESS.TRAVEL,
    path: '/app/processes/search/travel',
    teamPath: '/app/team/myteam/travel',
    newTeamPath: '/app/team/team-people/travel'
  },
  {
    code: MENU_CODES.PROCESS.ASSETREQUEST,
    path: '/app/processes/search/assetrequest',
    teamPath: '/app/team/myteam/assetrequest',
    newTeamPath: '/app/team/team-people/assetrequest'
  },
  {
    code: MENU_CODES.PROCESS.ASSET,
    path: '/app/processes/search/asset'
  },
  {
    code: MENU_CODES.PROCESS.NORMALIZERATING,
    path: '/app/processes/search/normalizerating',
    teamPath: '/app/team/myteam/normalizerating',
    newTeamPath: '/app/team/team-people/normalizerating'
  },
  {
    code: MENU_CODES.PROCESS.NORMALIZEPERFORMANCERATING,
    path: '/app/processes/search/normalizeperformancerating',
    teamPath: '/app/team/myteam/normalizeperformancerating',
    newTeamPath: '/app/team/team-people/normalizeperformancerating'
  },
  {
    code: MENU_CODES.PROCESS.PUBLISHPERFORMANCERATING,
    path: '/app/processes/search/publishperformancerating',
    teamPath: '/app/team/myteam/publishperformancerating',
    newTeamPath: '/app/team/team-people/publishperformancerating'
  },
  {
    code: MENU_CODES.PROCESS.CREATE_VIRTUAL_TEAM,
    path: '/app/processes/search/createvirtualteam',
    teamPath: '/app/team/myteam/createvirtualteam',
    newTeamPath: '/app/team/team-people/createvirtualteam'
  },
  {
    code: MENU_CODES.PROCESS.PIP_EVALUATION_LETTER,
    path: '/app/processes/search/pipevaluationletter',
  },
  {
    code: MENU_CODES.PROCESS.INITIATE360_FEEDBACK,
    path: '/app/processes/search/initiate360feedback',
  },
  {
    code: MENU_CODES.PROCESS.OFFER_LETTER,
    path: '/app/processes/search/offerLetter',
  },
  {
    code: MENU_CODES.PROCESS.EXTERNAL_CONSULTANT,
    path: '/app/processes/search/externalconsultant',
  },
  {
    code: MENU_CODES.PROCESS.SHARED_LETTER,
    path: '/app/processes/search/sharedLetter',
  },
  {
    code: MENU_CODES.PROCESS.POSTTRANSFERSEARCH,
    path: '/app/processes/search/posttransfersearch',
  },
  {
    code: MENU_CODES.PROCESS.PRETRANSFERSEARCH,
    path: '/app/processes/search/pretransfersearch',
  },
  {
    code: MENU_CODES.PROCESS.INDUCTION,
    path: '/app/processes/search/induction',
  },
  {
    code: MENU_CODES.PROCESS.INDUCTION_EVALUATION,
    path: '/app/processes/search/inductionevaluation',
  },
  {
    code: MENU_CODES.PROCESS.TRANSFER_LETTER,
    path: '/app/processes/search/transferletter',
  },
  {
    code: MENU_CODES.PROCESS.CONTRACT_LETTER,
    path: '/app/processes/search/contractletter',
  },
  {
    code: MENU_CODES.PROCESS.ACKNOWLEDGEMENT_LETTER,
    path: '/app/processes/search/acknowledgementletter',
  },
  {
    code: MENU_CODES.PROCESS.PIP_LETTER,
    path: '/app/processes/search/pipletter',
  },
  {
    code: MENU_CODES.PROCESS.RESTRICTED_HOLIDAY_CANCEL,
    path: '/app/processes/search/restrictedholidaycancel/',
  },
  {
    code: MENU_CODES.TEAM.LEAVE_CANCEL,
    teamPath: '/app/team/myteam/leavecancel',
    newTeamPath: '/app/team/team-people/leavecancel'
  },
  {
    code: MENU_CODES.PROCESS.WORK_FROM_HOME_SEARCH,
    path: '/app/processes/search/workfromhome',
  },
  {
    code: MENU_CODES.PROCESS.DELETEREVIEW,
    path: '/app/processes/search/deletereviewcycle'
  },
  {
    code: MENU_CODES.PROCESS.DELETEGOAL,
    path: '/app/processes/search/deletegoalcycle'
  },
  {
    code: MENU_CODES.PROCESS.ASSIGNHOLIDAY,
    path: '/app/processes/search/assignholiday'
  },
  {
    code: 'workplan_2199',
    path: '/app/processes/search/workplanrequest'
  },
  {
    code: 'workplan_2200',
    teamPath: '/app/team/myteam/workplanRequest',
    newTeamPath: '/app/team/team-people/workplanRequest'
  },
  {
    code: MENU_CODES.PROCESS.INITIATIVES,
    path: '/app/processes/search/initiative'
  },
  {
    code: MENU_CODES.PROCESS.PUBLISHED_LETTER_DOCUMENT,
    path: '/app/processes/search/publishedLetterdocument',
  },
  {
    code: MENU_CODES.TEAM.ASSIGN_HOLIDAY_TEAM,
    teamPath: '/app/team/myteam/assignholidayteam',
    newTeamPath: '/app/team/team-people/assignholidayteam'
  },
  {
    code: MENU_CODES.PROCESS.ASSET_ACKNOWLEDGEMENT,
    path: '/app/processes/search/assetAcknowledgement',
  },
  {
    code: MENU_CODES.PROCESS.BGV_VERIFICATION,
    path: '/app/processes/search/bgv_verification'
  },
  {
    code: MENU_CODES.PROCESS.TRANSFER_INTIMATION_LETTER,
    path: '/app/processes/search/transferintimationletter',
  },
  {
    code: MENU_CODES.PROCESS.EVENTS_SEARCH,
    path: '/app/processes/search/events'
  },
  {
    code: MENU_CODES.PROCESS.EVENTS,
    path: '/app/processes/search/eventsmanagements'
  },
  {
    code: MENU_CODES.PROCESS.TALENTPOOL,
    path: '/app/processes/search/talentpool'
  }
];


export const historyNavCodeRoutingMenu: { code: string, path: string }[] = [
  {
    code: 'salarystructurehistory_152',
    path: '/app/processes/search/salarystructurehistory'
  },
  {
    code: 'salarystructurehistory_152',
    path: '/app/processes/search/salarystructurehistory'
  },
  {
    code: MENU_CODES.PROCESS.REIMBURSEMENTHISTORY,
    path: '/app/processes/search/reimbursementhistory'
  },
  {
    code: MENU_CODES.PROCESS.LOANHISTORY,
    path: '/app/processes/search/loanhistory'
  },
  {
    code: MENU_CODES.PROCESS.PROFILE_HISTORY,
    path: '/app/processes/search/employeeProfileHistory'
  },
  {
    code: 'purposedinvestmenthistory_201',
    path: '/app/processes/search/proinvestment'
  },
  {
    code: 'confirmedinvestmenthistory_202',
    path: '/app/processes/search/coninvestment'
  },
  {
    code: 'settlementhistory_276',
    path: '/app/processes/search/settlement'
  },
  {
    code: 'goalhistory_640',
    path: '/app/processes/search/goal'
  },
  {
    code: 'performancehistory_464',
    path: '/app/processes/search/review'
  },

];

export const action_wo_onbehalf = [
  MENU_CODES.PROCESS.PROFILE,
  MENU_CODES.PROCESS.EMP_LETTER,
  MENU_CODES.PROCESS.BONUS_PROCESS,
  MENU_CODES.PROCESS.UPCOMING_EMP,
  MENU_CODES.PROCESS.LEAVEENCASHMENT,
  MENU_CODES.PROCESS.HR_POLICY,
  MENU_CODES.PROCESS.ANNOUNCEMENT,
  MENU_CODES.PROCESS.GEN_LETTER,
  MENU_CODES.PROCESS.BULK_PASS,
  MENU_CODES.PROCESS.LEAVE_CARRY_FORWARD,
  MENU_CODES.PROCESS.MARK_ATTENDANCE,
  MENU_CODES.PROCESS.SHIFT_CHANGE,
  MENU_CODES.TEAM.BULK_SHIFT_CHANGE,
  MENU_CODES.TEAM.MARK_ATTENDANCE_TEAM,
  MENU_CODES.PROCESS.TRANSFER_REWARD_POINT,
  MENU_CODES.PROCESS.EXTENDGOAL,
  MENU_CODES.PROCESS.EXTENDREVIEW,
  MENU_CODES.PROCESS.ADD_CANDIDATE,
  MENU_CODES.PROCESS.EXPENSE_ADVANCE_RECOVERY,
  MENU_CODES.PROCESS.EXPENSE_PAYMENT,
  MENU_CODES.PROCESS.EXTENDTHREESIXTY,
  MENU_CODES.PROCESS.PULSE_SURVEY,
  MENU_CODES.PROCESS.PUBLISH_LETTER,
  MENU_CODES.PROCESS.CANDIDATEENGAGE,
  MENU_CODES.PROCESS.INITIATEGOALCYCLE,
  MENU_CODES.PROCESS.INITIATEREVIEWCYCLE,
  MENU_CODES.PROCESS.VARIABLE,
  MENU_CODES.PROCESS.ADD_HARDWARE_INVENTORY,
  MENU_CODES.PROCESS.FUNDRAISER_CAMPAIGN,
  MENU_CODES.PROCESS.FEATURE_REQUEST,
  MENU_CODES.PROCESS.CREATECOMPANYGOAL,
  MENU_CODES.PROCESS.NORMALIZERATING,
  MENU_CODES.PROCESS.NORMALIZEPERFORMANCERATING,
  MENU_CODES.PROCESS.PUBLISHPERFORMANCERATING,
  MENU_CODES.PROCESS.CREATE_VIRTUAL_TEAM,
  MENU_CODES.PROCESS.INITIATE360_FEEDBACK,
  MENU_CODES.PROCESS.EXTERNAL_CONSULTANT,
  MENU_CODES.TEAM.LEAVE_CANCEL,
  MENU_CODES.PROCESS.DELETEREVIEW,
  MENU_CODES.PROCESS.DELETEGOAL,
  MENU_CODES.PROCESS.ASSIGNHOLIDAY,
  MENU_CODES.TEAM.ASSIGN_HOLIDAY_TEAM,
  MENU_CODES.PROCESS.EVENTS
];

export const UpEmpNavCodes = [MENU_CODES.PROCESS.UPCOMING_EMP];

export type MenuTypeGlobal = 'action' | 'search' | 'history' | 'manager';

export const labelNameByMenu = {
  622: {
    name: 'Job opening',
    label: 'Search by hiring manager'
  },
  624: {
    name: 'Position Assigned',
    label: 'Search by hiring manager'
  },
  1355: {
    name: 'Talent pool',
    label: 'Search candidate'
  },
  // {
  //     name: 'Assign Recruiter',
  //     label: 'Search by hiring manager'
  // },
  188: {
    name: 'Announcement',
    label: 'Search by published user'
  },
  434: {
    name: 'Badges',
    label: 'Search by received employee'
  },
  435: {
    name: 'Reward',
    label: 'Search by received employee'
  },
  190: {
    name: 'Survey',
    label: 'Search by received employee'
  },
  189: {
    name: 'HR Handbook',
    label: 'Search by published user'
  },
  388: {
    name: '1-on-1',
    label: 'Search by 1-on-1 scheduled by'
  },
  405: {
    name: '360 feedback',
    label: 'Search by feedback given to'
  },
  401: {
    name: '360 nomination',
    label: 'Search by 360 nominated by'
  },
  737: {
    name: 'Asset inventory',
    label: 'Search by managed by'
  },
  770: {
    name: 'Company goal',
    label: 'Search by owner'
  },
};

export const TipsAndHacksData = [
  {
    name: 'Essentials',
    subName: 'Must know features you will love',
    icon: 'assets/images/quickhacks/efficiency-icon.png',
    innerData: [{
      header: 'Get answers to any query with Google like search',
      description: `Search empowers HRs to search any kind of data for one or more employees. Like view employee profiles, salary structure, generated letters, confirmation forms, clearance done in system etc. It's a very user friendly search. You can simply type or select from the available search what you are looking for.`,
      image: 'assets/images/quickhacks/essential/Essential-1.png'
    },
    {
      header: 'Initiate new actions straight from HR Desk',
      description: 'From actions, you can do all the HR actions you have been given permission to like do announcement, publish HR handbook, process attendance or salary, assign shift. You can also raise all types of requests on behalf of an employee that an employee can raise for himself like resignation, investment declaration, loan etc.',
      image: 'assets/images/quickhacks/essential/Essential-2.png'
    },
    {
      header: 'Never miss deadlines',
      description: `It gives you a bird's eye view of all the pending and open requests or tasks in the system. From here you can track open tasks, send a reminder to the person, who has been assigned the task and it is pending. From here you can take any action like approve, reject or complete a task on behalf of the assigned person, on whom, the task is pending. `,
      image: 'assets/images/quickhacks/essential/Essential-3.png'
    },
    {
      header: 'Bulk upload employee data in minutes',
      description: 'Easily import bulk employee data using more than 35 types of built-in import templates. Work uninterrupted and get notified when upload is completed.You can use these imports for the first time implementation or whenever required. ',
      image: 'assets/images/quickhacks/essential/Essential-4.png'
    },
    {
      header: 'Get all your reports at one place',
      description: 'It helps you download any bulk information that you are looking for like the list of employees who joined the organization in the last month. You can also schedule reports to be sent to you, over the mail, at a specific time. ',
      image: 'assets/images/quickhacks/essential/Essential-5.png'
    },
    {
      header: 'No chance of mistakes',
      description: 'A dedicated place to review all the processes which are not completed due to any errors. HR can view the reason for error and manually re-run the process to avoid any chance of mistake.',
      image: 'assets/images/quickhacks/essential/Essential-6.png'
    }
    ]
  },
  {
    name: 'Productivity',
    subName: 'Productivity tips from our experts',
    icon: 'assets/images/quickhacks/productivity-icon.png',
    innerData: [{
      header: 'Do you know that you can schedule & auto-share reports with different stakeholders',
      description: 'Be it headcount, mood analysis, or attrition, access all cross module reports from one common window. It helps you to cut through the noise and direct you towards insight that truly matters. You can apply smart filters and schedule reports to be sent to you, over the mail, with frequency of your choice. You can also auto-share reports with the different stakeholders for tracking.',
      image: 'assets/images/quickhacks/productivity/Productivity-1.png'
    },
    {
      header: 'Do you know that you can save multiple report views',
      description: `You always view data after applying some specific filters or selecting any particular columns. To ease this, you can save a report view with the combination of smart filters and columns you usually apply. Henceforth, you need not apply filters or select a particular column each time you come to view the report. You can simply select the particular view of report and download the required data in a jiffy.
      Multiple views for a single report can also be created.`,
      image: 'assets/images/quickhacks/productivity/Productivity-2.png'
    },
    {
      header: 'Do you know that you can pin frequently used items',
      description: 'Pinned items in HROne allows you to always keep your favorite searches, reports, actions and imports just a click away. The pinned item icon is available at the top of the product so that you can easily access pinned items throughout the product. In case you want to pin/ unpin an item,  you can go to that specific item and click on the pin icon to pin/unpin that item.',
      image: 'assets/images/quickhacks/productivity/Productivity-3.png'
    },
    {
      header: 'Do you know that you can manage recurring announcements, pulse & surveys',
      description: 'Tired of sending the emails and marking cc, Bcc to so many individuals every now and then? Do now worry as this can be solved with HROne recurring features. Mention the dates for periodic announcements, pulse & surveys and watch them automagically go to the inbox of all the employees or the selected employee group.',
      image: 'assets/images/quickhacks/productivity/Productivity-4.png'
    }
    ]
  },
  {
    name: 'Communication',
    subName: 'Amplify human interactions with HROne',
    icon: 'assets/images/quickhacks/communication-icon.png',
    innerData: [
      {
        header: 'Boost communication by replacing the concept of emails with announcements.',
        description: `Announcement is used in order to inform groups department wise or profile wise to send announcements directly to the inbox of only those who need to know the same. You can track who all have not read the announcements.HR who has got rights to Engage module can go to HR Desk-> Actions and select Announcement. In subject you can specify the subject of the announcement visible in Inbox. You can design the announcement content or can use sample announcements. You can also share a video announcement or may share an attachment. We suggest you to use Youtube or Vimeo for video announcements.`,
        image: 'assets/images/quickhacks/communication/Communication-1.png'
      },
      {
        header: 'Never miss policy adherence with HRHandbook',
        description: `HR can share their company policies and procedures that your employee can refer to anytime through the HR handbook. Policies shared through HR handbook are visible to employees under their profile under HR Handbook section in web and mobile app.HR can map that the created policy would be visible to all users. In case HR may want to un-publish the published HR handbook due to change in company policies then he can go to HR Desk and search the specified HR handbook to get it unpublished in a go.`,
        image: 'assets/images/quickhacks/communication/Communication-2.png'
      },
      {
        header: 'Gauge the happiness quotient of your employees',
        description: `A business is only as happy as its people. Get feedback on what made them feel good or bad on a particular day while knowing which efforts are turning out to be fruitful and which have a scope for improvement with moodbot.
        You can ascertain an employee's mood by enabling Mood-bot from Setup >>Engage>>Happiness Index. This feature is only available if you have subscribed for the Engage module. You can select Enable mood bot as Yes. Once you select Yes, you can define the frequency of mood-bot that is how frequently do you want to show the pop-up to an employee who logs into HROne. You can configure it as Daily or Weekly.Moodbot may be enabled/ disabled at any point or the frequency of mood-bot may be changed later as well.
        `,
        image: 'assets/images/quickhacks/communication/Communication-3.png'
      },
      {
        header: 'No more follow ups emails between departments',
        description: `On mails follow ups is a thing of the past. Layout a comprehensive checklist for Onboarding and Clearance. Choose from existing templates or customize your own. Define the task owners. Depending on the setting, the checklist will be auto initiated and tasks will be assigned to respective task owners with due days to complete the tasks. `,
        image: 'assets/images/quickhacks/communication/Communication-4.png'
      },
      {
        header: 'Generate and share letters in a click',
        description: `Simplify your workload, select from our repository of letter templates or draft your own for Offer, Probation, Probation extension, Confirmation, Resignation and Relieving letter etc. With misc letters you have the freedom to draft and publish letters as per your requirements, not restricting to any predefined template.`,
        image: 'assets/images/quickhacks/communication/Communication-5.png'
      },
      {
        header: 'Build trust and create stronger connections with team',
        description: `A 1:1 is a chance for the manager and HR to connect with the team on a regular basis. This keeps you and your team engaged, informed and motivated. You need not to wait for the quarter end or year end for reviews and feedback. Now managers and HR can raise a request in HROne and schedule one on one meetings with the team. Record the MOMs in the Plan, Progress, Problem model, clearly stating takeaways and actions.`,
        image: 'assets/images/quickhacks/communication/Communication-6.png'
      },
      {
        header: 'Frequent employee feedback helps organizations respond on the fly',
        description: `Show employees their feedback matters with frequent surveys on topics relevant to you and your people. Make engagement a constant priority. Gather honest perspectives with short surveys and surface specific issues with open-text responses.
        Pulse feedback is strictly anonymous and not always in-depth, but recurring themes will make underlying problems painstakingly clear.
        `,
        image: 'assets/images/quickhacks/communication/Communication-7.png'
      }
    ]
  }

];


export const radomMessage = [

  '�When people go to work, they shouldn�t have to leave their hearts at home.� � Betty Bender',
  'To handle yourself, use your head; to handle others, use your heart.� � Eleanor Roosevelt ',

  '�Train people well enough so they can leave. Treat them well enough so they don�t want to.� � Sir Richard Branson',

  '�Hire character. Train skill.� � Peter Schutz ',

  '�Every good conversation starts with listening.� � Tom Haak',

  '�When people go to work, they shouldn�t have to leave their hearts at home.� � Betty Bender',

  '�To win the marketplace, you must first win the workplace.� ',

  '�If you want creative workers, give them enough time to play.�',

  'When people are financially invested, they want a return. When people are emotionally invested, they want to contribute.',


];

export enum NotificationProcessName {
  announcement_188 = 'New announcement',
  badges_434 = 'Badges',
  policy_189 = 'HR handbook',
  rewardpoints_435 = 'Reward Points',
  pulsesurvey_583 = 'Survey',
  clearance_89 = 'Clearance',
  confirmation_51 = 'Confirmation request',
  confirmationletter_97 = 'Confirmation letter',
  dotransfer_929 = 'Do transfer',
  exitinterview_88 = 'Exit interview',
  extensionletter_356 = 'Extension letter',
  finalclearance_157 = 'Final clearance',
  induction_1086 = 'Induction',
  otherletter_438 = 'Generation of miscellaneous letter',
  onboarding_776 = 'Onboarding Task',
  posttransfer_1103 = 'Post transfer Task',
  pretransfer_1104 = 'Pre transfer Task',
  preboarding_779 = 'Preboarding Task',
  transferrequest_919 = 'Transfer',
  welcomenotesearch_704 = 'Welcome Note from Manager',
  attendance_103 = 'Mark attendance request',
  attendanceregularization_95 = 'Regularization request',
  attendanceregularizationcancel_100 = 'Regularization cancellation request',
  compensatoryoff_123 = 'Compensatory off request',
  geolocations_661 = 'Location approval request',
  leave_98 = 'New leave request',
  offerletter_832 = 'Offer letter request',
  jobopening_815 = 'Job opening request',
  assetrequest_1028 = 'Asset request',
  assetsearch_1029 = 'Manage Asset',
  travelbooking = 'Travel booking',
  travel_1016 = 'Travel',
  expensereport_812 = 'Expense Report',
  expenseadvance_496 = 'Expense advance request',
  dispenseadvance_773 = 'Dispense Expense Advance',
  pipletter_955 = 'PIP letter',
  pipletter_803 = 'PIP evaluation letter',
  pip_975 = 'Pip evaluation request',
  pip_955 = 'Pip request',
  performanceletter_696 = 'Performance letter',
  performancehistory_464 = 'Performance review request',
  performancereview_432 = 'Performance review request',
  goals_680 = 'Goal',
  goalhistory_640 = 'Goal',
  variable_739 = 'Variable request',
  settlementhistory_276 = 'Settlement request',
  settlement_117 = 'Settlement request',
  salaryytd_178 = 'Assign salary structure request',
  salarystructurehistory_152 = 'Assign salary structure request',
  salarystructure_113 = 'Assign salary structure request',
  salaryrevisionletter_709 = 'Salary revision letter',
  reimbursementhistory_170 = 'Reimbursement request',
  reimbursement_cancel = 'Reimbursement cancellation request',
  purposedinvestmenthistory_201 = 'Proposed investment request',
  purposedinvestmentdeclaration_114 = 'Proposed investment request',
  loan_235 = 'Loan request',
  fbprequest_684 = 'FBP Approval',
  loan_112 = 'Dispense loan',
  confirmedinvestmenthistory_202 = 'Confirmed investment request',
  confirmedinvestmentdeclaration_181 = 'Confirmed investment request',
  appointmentletter_272 = 'Appointment letter',
  shortleavecancel_104 = 'Short leave cancellation request',
  shortleave_99 = 'Short leave request',
  shift_108 = 'Shift change',
  restrictedholiday_97 = 'Restricted holiday request',
  overtime_241 = 'Overtime request',
  ondutycancel_101 = 'On duty cancellation request',
  onduty_96 = 'On duty request',
  markattendance_346 = 'Mark attendance request',
  locationapproval_347 = 'Location approval request',
  leavecancel_102 = 'Leave cancellation request',
  leavebalance_105 = 'New leave request'
}

export enum WorkflowsFlowId {
  confirmation_51 = '1',
  induction_1086 = '46',
  resignation_91 = '2',
  cancelresignation_156 = '2',
  transferrequest_919 = '39',
  attendance_103 = '13',
  attendanceregularization_95 = '6',
  attendanceregularizationcancel_100 = '6',
  compensatoryoff_123 = '9',
  geolocations_661 = '29',
  leave_98 = '5',
  offerletter_832 = '38',
  jobopening_815 = '34',
  assetrequest_1028 = '45',
  travel_1016 = '41',
  expensereport_812 = '36',
  expenseadvance_496 = '33',
  pip_975 = '43',
  pip_955 = '42',
  performancehistory_464 = '32',
  performancereview_432 = '32',
  goals_680 = '31',
  goalhistory_640 = '31',
  createcompanygoal_998 = '31',
  nomination_606 = '30',
  variable_739 = '44',
  settlementhistory_276 = '25',
  settlement_117 = '25',
  salaryytd_178 = '14',
  salarystructurehistory_152 = '14',
  salarystructure_113 = '14',
  reimbursementhistory_170 = '22',
  reimbursement_cancel = '22',
  purposedinvestmenthistory_201 = '26',
  purposedinvestmentdeclaration_114 = '26',
  loan_235 = '23',
  fbprequest_684 = '40',
  loan_112 = '23',
  confirmedinvestmenthistory_202 = '28',
  confirmedinvestmentdeclaration_181 = '28',
  shortleavecancel_104 = '11',
  shortleave_99 = '11',
  restrictedholiday_97 = '10',
  overtime_241 = '12',
  ondutycancel_101 = '7',
  onduty_96 = '7',
  markattendance_346 = '13',
  locationapproval_347 = '29',
  leavecancel_102 = '5',
  leavebalance_105 = '5'
}

export enum SettingsLabelName {
  confirmationletter_97 = 'Task owner',
  dotransfer_929 = 'Task owner',
  extensionletter_356 = 'Task owner',
  finalclearance_157 = 'Task owner',
  induction_1086 = 'Task owner',
  relievingletter_256 = 'Task owner',
  terminationletter_358 = 'Task owner',
  addupcomingjoin_732 = 'Task owner',
  compensatoryoff_123 = 'Task owner'
}

export interface IProcessAutomateSummary {
  totalAutomatedProcess: number,
  totalCompletedProcess: number,
  processesAutomated: string
}

export enum SearchLabelName {
  clearance_89 = 'Clearance',
  confirmation_51 = 'Confirmation',
  exitinterview_88 = 'Exit interview',
  profile_50 = 'Profile',
  resignation_91 = 'Resignation',
  confirmedinvestmentdeclaration_181 = 'Confirmed investment declaration',
  confirmedinvestmenthistory_202 = 'Confirmed investment history',
  loan_112 = 'Dispense loan',
  loan_235 = 'Loan history',
  purposedinvestmenthistory_201 = 'Proposed investment history',
  purposedinvestmentdeclaration_114 = 'Proposed investment declaration',
  salarystructure_113 = 'Salary structure',
  salarystructurehistory_152 = 'Salary structure history',
  salaryytd_178 = 'Salary ytd',
  settlement_117 = 'Settlement',
  settlementhistory_276 = 'Settlement history',
  travel_1016 = 'Travel request',
  travelbooking = 'Book Travel',
  assetsearch_1029 = 'Asset'
}

export interface IWorkForceGeneralSetting {
  multipleUpdate: boolean,
  settingId: number,
  settingName: string,
  status: string,
  isActive: boolean,
  controlType: string
}