/*
 *  This file routing-constant.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { concat } from 'lodash';
import { MENU_CODES } from './menu-codes.constant';

export interface NavObject {
  code: string;
  path: string;
}



export const dashboardRouting: NavObject[] = [
  {
    code: MENU_CODES.DASHBOARD.HOME,
    path: '/app/'
  },
];

export const productManagementDashboardRouting: NavObject[] = [
  {
    code: MENU_CODES.DASHBOARD.HOME,
    path: '/product-management/home'
  },
];

export const processRouting: NavObject[] = [
  {
    code: MENU_CODES.PROCESS.PROFILE,
    path: '/app/processes/workforce/allemployees'
  },
  {
    code: MENU_CODES.PROCESS.SAL_STRUC,
    path: '/app/processes/workforce/salarystructure'
  },
  {
    code: MENU_CODES.PROCESS.LOAN,
    path: '/app/processes/workforce/loanhistory'
  },
  {
    code: MENU_CODES.PROCESS.SALATYYTD,
    path: '/app/processes/workforce/salaryytd'
  },
  {
    code: MENU_CODES.PROCESS.REIMBURSEMENT,
    path: '/app/processes/workforce/reimbursement'
  },
  {
    code: MENU_CODES.PROCESS.REIMBURSEMENTHISTORY,
    path: '/app/processes/workforce/reimbursementhistory'
  },
  {
    code: MENU_CODES.PROCESS.CONFIRM,
    path: '/app/processes/workforce/confirmation'
  },
  {
    code: MENU_CODES.PROCESS.RESIGN,
    path: '/app/processes/workforce/separation'
  },
  {
    code: MENU_CODES.PROCESS.CANCELRESIGN,
    path: '/app/processes/workforce/cancelseparation'
  },
  {
    code: MENU_CODES.PROCESS.CLEARANCE,
    path: '/app/processes/workforce/clearance'
  },
  {
    code: MENU_CODES.PROCESS.FINALCLEARANCE,
    path: '/app/processes/workforce/finalclearance'
  },
  {
    code: MENU_CODES.PROCESS.EXIT_INT,
    path: '/app/processes/workforce/exitinterview'
  },
  {
    code: MENU_CODES.PROCESS.SEARCH,
    path: '/app/processes/search'
  },
  {
    code: MENU_CODES.PROCESS.REFER_EARN,
    path: '/app/processes/referral-program'
  },
  {
    code: MENU_CODES.PROCESS.HRDASHBOARD,
    path: '/app/processes/dashboards'
  },
  {
    code: MENU_CODES.PROCESS.REPORT,
    path: '/app/processes/reports'
  },
  {
    code: MENU_CODES.PROCESS.DASHBOARD,
    path: '/app/processes/workforce/dashboard'
  },
  {
    code: MENU_CODES.PROCESS.INSIGHT,
    path: '/app/processes/insights'
  },
  {
    code: MENU_CODES.PROCESS.SAL_STRUC,
    path: '/app/processes/payroll/salarystructure'
  },
  {
    code: MENU_CODES.PROCESS.SALARY_PROCESS,
    path: '/app/processes/payroll/salaryprocess'
  },
  {
    code: MENU_CODES.PROCESS.BONUS_PROCESS,
    path: '/app/processes/payroll/bonusprocess'
  },
  {
    code: MENU_CODES.PROCESS.EMP_LETTER,
    path: '/app/processes/workforce/employeeletter'
  },
  {
    code: MENU_CODES.PROCESS.ATTENDANCE,
    path: '/app/processes/timeoffice/attendance'
  },
  {
    code: MENU_CODES.PROCESS.PROCESS_ATT,
    path: '/app/processes/timeoffice/processattendance'
  },
  {
    code: MENU_CODES.PROCESS.SHIFT,
    path: '/app/processes/timeoffice/shift'
  },
  {
    code: MENU_CODES.PROCESS.ANNOUNCEMENT,
    path: '/app/processes/engage/announcement'
  },
  {
    code: MENU_CODES.PROCESS.HR_POLICY,
    path: '/app/processes/engage/policy'
  },
  {
    code: MENU_CODES.PROCESS.BULK_PASS,
    path: '/app/processes/workforce/sendbulkpassword'
  },
  {
    code: MENU_CODES.PROCESS.ATT_YTD,
    path: '/app/processes/timeoffice/attendanceytd'
  },
];

export const requestRouting: NavObject[] = [
  {
    code: MENU_CODES.REQUEST.RESIGN,
    path: '/app/request/initiate-separation'
  },
  {
    code: MENU_CODES.REQUEST.REIMBURSEMENT,
    path: '/app/request/initiate-reimbursement'
  },
  {
    code: MENU_CODES.REQUEST.LOAN,
    path: '/app/request/initiate-loan'
  },
  {
    code: MENU_CODES.REQUEST.PENDING,
    path: '/app/request/initiatedrequest'
  },
  {
    code: MENU_CODES.REQUEST.APPROVED,
    path: '/app/request/initiatedrequest'
  },
  {
    code: MENU_CODES.REQUEST.REJECTED,
    path: '/app/request/initiatedrequest'
  },
  {
    code: MENU_CODES.REQUEST.DRAFT,
    path: '/app/request/initiatedrequest'
  },
  {
    code: MENU_CODES.REQUEST.UNDO,
    path: '/app/request/initiatedrequest'
  },
  {
    code: MENU_CODES.REQUEST.RESTRICTED_HOLIDAY,
    path: '/app/request/initiate-restricted-holiday'
  },
  {
    code: MENU_CODES.REQUEST.ON_DUTY,
    path: '/app/request/initiate-on-duty'
  },
  {
    code: MENU_CODES.REQUEST.PUR_INVEST,
    path: '/app/request/proposedinvestment'
  },
  {
    code: MENU_CODES.REQUEST.CON_INVEST,
    path: '/app/request/confirmedinvestment'
  },
  {
    code: MENU_CODES.REQUEST.ATTENDANCE_REGULARIZATION,
    path: '/app/request/initiate-attendance-regularization'
  },
  {
    code: MENU_CODES.REQUEST.LEAVE,
    path: '/app/request/initiate-leave'
  },
  {
    code: MENU_CODES.REQUEST.SHORT_LEAVE,
    path: '/app/request/initiate-short-leave'
  },
  {
    code: MENU_CODES.REQUEST.HELP_DESK_TICKET,
    path: '/app/request/initiate-help-desk-ticket'
  },

  {
    code: MENU_CODES.REQUEST.EXPENSE_ADVANCE,
    path: '/app/request/initiate-advance-request'
  },

  {
    code: MENU_CODES.REQUEST.EXPENSE_RECEIPT,
    path: '/app/request/initiate-expense-receipt-request'
  },
  {
    code: MENU_CODES.REQUEST.JOB_OPENING,
    path: '/app/request/initiate-job-opening'
  },
  {
    code: MENU_CODES.REQUEST.CHANGE_GOAL,
    path: '/app/request/initiate-change-goal'
  },
  {
    code: MENU_CODES.REQUEST.VARIABLE,
    path: '/app/request/initiate-variable'
  },
  {
    code: MENU_CODES.REQUEST.TRAVEL,
    path: '/app/request/initiate-travel'
  },
  {
    code: MENU_CODES.REQUEST.ASSET,
    path: '/app/request/initiate-asset-request'
  },
  {
    code: MENU_CODES.REQUEST.WFH_WORK_PLAN,
    path: '/app/request/initiate-work-plan'
  },
];

export const teamRouting: NavObject[] = [
  {
    code: MENU_CODES.TEAM.TEAM_OVERVIEW,
    path: '/app/team/myteam'
  },
  {
    code: MENU_CODES.TEAM.TEAM_REPORT,
    path: '/app/team/team-reports'
  },

  {
    code: MENU_CODES.TEAM.TEAM_PEOPLE,
    path: '/app/team/team-people'
  },
  {
    code: MENU_CODES.TEAM.TEAM_DASHBOARD,
    path: '/app/team/dashboard'
  }
];

export const coreRouting: NavObject[] = [
  {
    code: 'organizationstructure_11',
    path: '/app/core/organization/organizationstructure'
  },
  {
    code: 'enterprise_21',
    path: '/app/core/organization/organizationstructure/enterprise'
  },
  {
    code: 'dashboard_893',
    path: '/app/core/onboarding-dashboard'
  },
  {
    code: 'company_22',
    path: '/app/core/organization/organizationstructure/company'
  },
  {
    code: 'businessunit_23',
    path: '/app/core/organization/organizationstructure/businessunit'
  },
  {
    code: 'department_24',
    path: '/app/core/organization/organizationstructure/department'
  },
  {
    code: 'subdepartment_25',
    path: '/app/core/organization/organizationstructure/subdepartment'
  },
  {
    code: 'designation_26',
    path: '/app/core/organization/organizationstructure/designation'
  },
  {
    code: 'grade_27',
    path: '/app/core/organization/organizationstructure/grade'
  },
  {
    code: 'level_28',
    path: '/app/core/organization/organizationstructure/level'
  },
  {
    code: 'location_12',
    path: '/app/core/organization/location'
  },
  {
    code: 'country_29',
    path: '/app/core/organization/location/country'
  },
  {
    code: 'state_30',
    path: '/app/core/organization/location/state'
  },
  {
    code: 'city_31',
    path: '/app/core/organization/location/city'
  },
  {
    code: 'region_32',
    path: '/app/core/organization/location/region'
  },
  {
    code: 'branch_33',
    path: '/app/core/organization/location/branch'
  },
  {
    code: 'subbranch_34',
    path: '/app/core/organization/location/subbranch'
  },
  {
    code: 'roles_13',
    path: '/app/core/organization/roles'
  },
  {
    code: 'statutory_56',
    path: '/app/core/payroll/statutory'
  },
  {
    code: 'tax_57',
    path: '/app/core/payroll/tax'
  },
  {
    code: 'challan_58',
    path: '/app/core/payroll/challan'
  },
  {
    code: 'slabs_57',
    path: '/app/core/payroll/slabs'
  },
  {
    code: 'paygroup_59',
    path: '/app/core/payroll/paygroup/paygroup'
  },
  {
    code: 'workflow_61',
    path: '/app/core/payroll/workflow'
  },
  {
    code: 'confirmationpolicy_40',
    path: '/app/core/workforce/confirmationpolicy'
  },
  {
    code: 'separationpolicy_41',
    path: '/app/core/workforce/separationpolicy'
  },
  {
    code: MENU_CODES.CORE.SEP_POLICY,
    path: '/app/core/workforce/separationpolicy/policy'
  },
  {
    code: MENU_CODES.CORE.SEP_REASON,
    path: '/app/core/organization/employee/reason'
  },
  {
    code: MENU_CODES.CORE.PRE_JOIN,
    path: '/app/core/organization/employee/documents'
  },
  {
    code: 'workflow_43',
    path: '/app/core/workforce/workflow'
  },
  {
    code: MENU_CODES.CORE.CHECK_LIST,
    path: '/app/core/workforce/checklist'
  },
  {
    code: 'lettertemplate_44',
    path: '/app/core/workforce/lettertemplate'
  },
  {
    code: MENU_CODES.CORE.LETTER_TEMPLATE_425,
    path: '/app/core/performance/lettertemplate'
  },
  {
    code: MENU_CODES.CORE.FEEDBACK_TEMPLATE,
    path: '/app/core/general/feedback/workforce'
  },
  {
    code: 'allfeedback_62',
    path: '/app/core/workforce/feedbacktemplate/allfeedback'
  },
  {
    code: 'questionbank_63',
    path: '/app/core/workforce/feedbacktemplate/questionbank'
  },
  {
    code: MENU_CODES.CORE.WF_ALERT_262,
    path: '/app/core/workforce/alerts'
  },
  {
    code: MENU_CODES.CORE.PM_NOTIFICATION,
    path: '/app/core/project-management/notification'
  },
  {
    code: MENU_CODES.CORE.PM_PROJECT_TEMPLATE,
    path: '/app/core/project-management/project-template'
  },
  {
    code: MENU_CODES.CORE.TO_ALERT_265,
    path: '/app/core/timeoffice/alerts'
  },
  {
    code: MENU_CODES.CORE.PR_ALERT_60,
    path: '/app/core/payroll/alerts'
  },
  {
    code: MENU_CODES.CORE.EN_ALERT_352,
    path: '/app/core/engagesetting/alerts'
  },
  {
    code: MENU_CODES.CORE.ENGAGE_FEEDBACKTEMPLATE,
    path: '/app/core/general/feedback/engage'
  },
  {
    code: MENU_CODES.CORE.ENGAGE_FEEDBACK,
    path: '/app/core/engagesetting/feedback/engage-feedback'
  },
  {
    code: MENU_CODES.CORE.ENGAGE_QUESTIONBANK,
    path: '/app/core/engagesetting/feedback/engage-question-bank'
  },
  {
    code: MENU_CODES.CORE.LETTER_TEMPLATE_ENGAGE,
    path: '/app/core/engagesetting/engageLetterTemplate'
  },
  {
    code: MENU_CODES.CORE.PROJECT_MANAGEMENT_TEMPLATE,
    path: '/app/core/projects/projectManagementTemplate'
  },
  {
    code: MENU_CODES.CORE.HD_ALERT_377,
    path: '/app/core/helpdesk/alerts'
  },
  {
    code: MENU_CODES.CORE.PMS_ALERT_457,
    path: '/app/core/performance/alerts'
  },
  {
    code: MENU_CODES.CORE.EXPENSE_ALERT_519,
    path: '/app/core/expense/alerts'
  },
  {
    code: MENU_CODES.CORE.TIMESHEET_POLICY,
    path: '/app/core/project-management/timesheet-policy'
  },
  {
    code: MENU_CODES.REQUEST.TIMESHEET_REQUEST,
    path: '/app/request/timesheet-request'
  },
  {
    code: MENU_CODES.CORE.QUALIFICATION,
    path: '/app/core/organization/employee/qualification'
  },
  {
    code: 'workflow_1007',
    path: '/app/core/project-management/workflow'
  },
  {
    code: 'codeseries_14',
    path: '/app/core/general/codeseries'
  },
  {
    code: 'emailsmsconfiguration_15',
    path: '/app/core/general/emailsmsconfiguration'
  },
  {
    code: 'email_35',
    path: '/app/core/general/emailsmsconfiguration/email'
  },
  {
    code: 'sms_36',
    path: '/app/core/general/emailsmsconfiguration/sms'
  },
  {
    code: 'forms_16',
    path: '/app/core/general/forms'
  },
  {
    code: 'services_17',
    path: '/app/core/general/services'
  },

  {
    code: 'pageaccess_18',
    path: '/app/core/accessrights/pageaccess'
  },
  {
    code: 'dataaccess_19',
    path: '/app/core/accessrights/dataaccess'
  },
  {
    code: 'widgetaccess_20',
    path: '/app/core/accessrights/widgetaccess'
  },
  {
    code: MENU_CODES.CORE.SOCIAL_PROFILE_ACCESS,
    path: '/app/core/organization/employee/socialprofileaccess'
  },
  {
    code: MENU_CODES.CORE.GEO_ACCESS,
    path: '/app/core/accessrights/geoaccess'
  },
  {
    code: 'audittrail_37',
    path: '/app/core/audittrail'
  },
  {
    code: MENU_CODES.CORE.PAY_BANK,
    path: '/app/core/organization/paymentBank'
  },
  {
    code: 'shift_141',
    path: '/app/core/timeoffice/shift'
  },
  {
    code: 'weeklyoff_142',
    path: '/app/core/timeoffice/weeklyoff'
  },
  {
    code: 'attendance_143',
    path: '/app/core/timeoffice/attendance'
  },
  {
    code: 'leave_144',
    path: '/app/core/timeoffice/leave'
  },
  {
    code: 'holiday_145',
    path: '/app/core/timeoffice/holiday'
  },
  {
    code: 'setting_146',
    path: '/app/core/timeoffice/setting'
  },
  {
    code: 'shortleave_150',
    path: '/app/core/timeoffice/shortleave'
  },
  {
    code: 'checkinpolicy_148',
    path: '/app/core/timeoffice/checkIn'
  },
  {
    code: 'shiftrotation_155',
    path: '/app/core/timeoffice/shiftrotation'
  },
  {
    code: 'workflow_149',
    path: '/app/core/timeoffice/workflow'
  },
  {
    code: MENU_CODES.CORE.SETTING_PAYROLL,
    path: '/app/core/payroll/setting'
  },
  {
    code: MENU_CODES.CORE.PAYSLIP_TEMPLATE,
    path: '/app/core/payroll/paysliptemplate'
  },
  {
    code: MENU_CODES.CORE.BIOMETRIC,
    path: '/app/core/integaration/biometric'
  },
  {
    code: MENU_CODES.CORE.DEVELOPER,
    path: '/app/core/integaration/developer'
  },
  {
    code: MENU_CODES.CORE.EMPLOYEE_OFFER,
    path: '/app/core/integaration/employeeoffer'
  },
  {
    code: MENU_CODES.CORE.HAPPINESS_INDEX_SETTING,
    path: '/app/core/engagesetting/happinessindexsetting'
  },
  {
    code: MENU_CODES.CORE.ANALYTICSACCESS,
    path: '/app/core/accessrights/analyticsaccess'
  },
  {
    code: MENU_CODES.CORE.WIDGET_ACCESS,
    path: '/app/core/accessrights/widgetaccess'
  },
  {
    code: MENU_CODES.CORE.SERVICES,
    path: '/app/core/general/services'
  },
  {
    code: MENU_CODES.CORE.AUDITTRAILSETTING,
    path: '/app/core/general/audittrailsetting'
  },
  {
    code: MENU_CODES.CORE.HELPDESKPOLICY,
    path: '/app/core/helpdesk/helpdeskpolicy'
  },
  {
    code: MENU_CODES.CORE.WORKFLOW_PERFORMANCE,
    path: '/app/core/performance/workflow'
  },
  {
    code: MENU_CODES.CORE.GOAL_BANK,
    path: '/app/core/performance/goal/goal-bank'
  },
  {
    code: MENU_CODES.CORE.PLAN,
    path: '/app/core/subscription/plan'
  },
  {
    code: MENU_CODES.CORE.POLICY_360,
    path: '/app/core/performance/policy360'
  },
  {
    code: MENU_CODES.CORE.POLICY_360_LAYOUT,
    path: '/app/core/performance/policy360/policy'
  },
  {
    code: MENU_CODES.CORE.POLICY_360_FEEDBACK,
    path: '/app/core/general/feedback/performance'
  },
  {
    code: MENU_CODES.CORE.POLICY_360_QUESTION_BANK,
    path: '/app/core/performance/feedback/question-bank-360'
  },
  {
    code: MENU_CODES.CORE.GOAL_POLICY,
    path: '/app/core/performance/performance/goal-policy'
  },
  {
    code: MENU_CODES.CORE.GOAL_TEMPLATE,
    path: '/app/core/performance/goal/goal-template'
  },
  {
    code: MENU_CODES.CORE.GOAL_SHEET,
    path: '/app/core/performance/goal/goal-sheet'
  },
  {
    code: MENU_CODES.CORE.BADGE_SETTING,
    path: '/app/core/engagesetting/badgesetting'
  },
  {
    code: MENU_CODES.CORE.POINTS_POLICT,
    path: '/app/core/engagesetting/rewardpointspolicy'
  },
  {
    code: MENU_CODES.CORE.EN_SETTING,
    path: '/app/core/engagesetting/settings'
  },
  {
    code: MENU_CODES.CORE.ONBOARDING,
    path: '/app/core/workforce/onboarding'
  },
  {
    code: MENU_CODES.CORE.ADVANCE_POLICY,
    path: '/app/core/expense/advancepolicy'
  },
  {
    code: MENU_CODES.CORE.LOCATION_GROUP,
    path: '/app/core/expense/locationgroup'
  },
  {
    code: MENU_CODES.CORE.EXPENSE_POLICY,
    path: '/app/core/expense/expensepolicy'
  },

  {
    code: MENU_CODES.CORE.WORKFLOW_EXPENSE,
    path: '/app/core/expense/workflow'
  },

  {
    code: MENU_CODES.CORE.EXPENSE_TAX,
    path: '/app/core/expense/expensetax'
  },
  {
    code: MENU_CODES.CORE.CUSTOMER,
    path: '/app/core/organization/customer'
  },
  {
    code: MENU_CODES.CORE.PROJECT,
    path: '/app/core/organization/project/project'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_SETTING,
    path: '/app/core/recruitment/recruitmentsetting'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_LETTERTEMPLATE,
    path: '/app/core/recruitment/recruitmentlettertemplate'
  },
  {
    code: MENU_CODES.CORE.WORKFLOW_RECRUITMENT,
    path: '/app/core/recruitment/workflow'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_TEMPLATE,
    path: '/app/core/general/feedback/recruitment'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_FEEDBACK,
    path: '/app/core/recruitment/recruitment-template/recruitment-feedback'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_QUESTION_BANK,
    path: '/app/core/recruitment/recruitment-template/recruitment-question-bank'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_SOURCE_SUBMENUE,
    path: '/app/core/recruitment/recruitment-source'
  },

  {
    code: MENU_CODES.CORE.EXPENSE_CURRENCY,
    path: '/app/core/expense/expensecurrency'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_SOURCE_CATEGORY,
    path: '/app/core/recruitment/category'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_ALERT_586,
    path: '/app/core/recruitment/alerts'
  },
  {
    code: MENU_CODES.CORE.TRANSFER,
    path: '/app/core/workforce/transferpolicy'
  },
  {
    code: MENU_CODES.CORE.TRANSFER_POLICY,
    path: '/app/core/workforce/transferpolicy/policy'
  },
  {
    code: MENU_CODES.CORE.TRANSFER_REASON,
    path: '/app/core/workforce/transferpolicy/reason'
  },
  {
    code: MENU_CODES.CORE.PIP_POLICY,
    path: '/app/core/performance/improvement-plan/pip-policy'
  },
  {
    code: MENU_CODES.CORE.ASSET_INTERNAL_TYPE,
    path: '/app/core/asset/type'
  },
  {
    code: MENU_CODES.CORE.ASSET_INTERNAL_SUB_TYPE,
    path: '/app/core/asset/sub-type'
  },
  {
    code: MENU_CODES.CORE.ASSET_PRODUCTS,
    path: '/app/core/asset/products'
  },
  {
    code: MENU_CODES.CORE.VENDOR,
    path: '/app/core/organization/vendor'
  },
  {
    code: MENU_CODES.CORE.WORKFLOW_ASSET,
    path: '/app/core/asset/workflow'
  },
  {
    code: MENU_CODES.CORE.ASSET_ALERT_810,
    path: '/app/core/asset/alerts'
  },
  {
    code: MENU_CODES.CORE.EXPENSE_LETTER_TEMPLATE,
    path: '/app/core/expense/expenselettertemplate'
  },
  {
    code: MENU_CODES.CORE.DOWNLOAD_INVOICES,
    path: '/app/core/general/downloadinvoices'
  },
  {
    code: MENU_CODES.CORE.INDUCTION_POLICY,
    path: '/app/core/workforce/inductionpolicy'
  },
  {
    code: MENU_CODES.CORE.REVIEW_RATING_FORMULA,
    path: '/app/core/performance/review/kpi-rating-formula'
  },
  {
    code: MENU_CODES.CORE.WORK_FROM_HOME,
    path: '/app/core/work-from-home/setting'
  },
  {
    code: MENU_CODES.CORE.OFFICE_MASTER,
    path: '/app/core/work-from-home/office-master'
  },
  {
    code: MENU_CODES.CORE.WFH_POLICY,
    path: '/app/core/work-from-home/policy'
  },
  {
    code: MENU_CODES.CORE.WORK_FROM_HOME_ALERTS,
    path: '/app/core/work-from-home/alerts'
  },
  {
    code: MENU_CODES.CORE.WORKFLOW_WORK_FROM_HOME,
    path: '/app/core/work-from-home/workflow'
  },
  {
    code: MENU_CODES.CORE.EMPLOYEE_ACCESS,
    path: '/app/core/organization/employee/profile-access'
  },
  {
    code: MENU_CODES.CORE.CANDIDATE_ACCESS,
    path: '/app/core/accessrights/candidateaccess'
  },
  {
    code: MENU_CODES.CORE.CALENDAR_ACCESS,
    path: '/app/core/accessrights/calendaraccess'
  },
  {
    code: MENU_CODES.CORE.CORE_VALUES,
    path: '/app/core/engagesetting/core-value'
  },
  {
    code: MENU_CODES.CORE.PMS_FEEDBACK_TEMPLATE,
    path: '/app/core/performance/feedback/feedback'
  },
  {
    code: MENU_CODES.CORE.BLACKLIST_REASON,
    path: '/app/core/organization/employee/blacklist-reason'
  },
  {
    code: MENU_CODES.CORE.JOB_FUNCTION,
    path: '/app/core/organization/employee/job-function'
  },
  {
    code: MENU_CODES.CORE.SKILL,
    path: '/app/core/organization/employee/skill'
  },
  {
    code: MENU_CODES.CORE.SKILL_PROFICIENCY,
    path: '/app/core/organization/employee/skill-proficiency'
  },
  {
    code: MENU_CODES.CORE.PROJECT_FIELD,
    path: '/app/core/organization/project/project-field'
  },
  {
    code: MENU_CODES.CORE.PROJECT_SUB_STATUS,
    path: '/app/core/organization/project/project-sub-status'
  },
  {
    code: MENU_CODES.CORE.VENDOR_FIELD,
    path: '/app/core/organization/vendor-field'
  },
  {
    code: MENU_CODES.CORE.TASK_AUTOMATION_WORKFORCE,
    path: '/app/core/general/task-automation/workforce'
  },
  {
    code: MENU_CODES.CORE.TASK_AUTOMATION_TIMEOFFICE,
    path: '/app/core/general/task-automation/timeoffice'
  },
  {
    code: MENU_CODES.CORE.TASK_AUTOMATION_WORKPLAN,
    path: '/app/core/general/task-automation/work-from-home'
  },
  {
    code: MENU_CODES.CORE.TASK_AUTOMATION_PAYROLL,
    path: '/app/core/general/task-automation/payroll'
  },
  {
    code: MENU_CODES.CORE.TASK_AUTOMATION_EXPENSE,
    path: '/app/core/general/task-automation/expense'
  },
  {
    code: MENU_CODES.CORE.TASK_AUTOMATION_PERFORMANCE,
    path: '/app/core/general/task-automation/performance'
  },
  {
    code: MENU_CODES.CORE.TASK_AUTOMATION_RECRUITMENT,
    path: '/app/core/general/task-automation/recruitment'
  },
  {
    code: MENU_CODES.CORE.TASK_AUTOMATION_ASSET,
    path: '/app/core/general/task-automation/asset'
  },
  {
    code: MENU_CODES.CORE.GENERAL_PASSWORD_POLICY,
    path: '/app/core/general/security/password-policy'
  },
  {
    code: MENU_CODES.CORE.DISABLE_LOGIN,
    path: '/app/core/general/security/disable-login'
  },
  {
    code: MENU_CODES.CORE.LETTER_ACCEPTANCE,
    path: '/app/core/general/letter-acceptance'
  },
  {
    code: MENU_CODES.CORE.INBOX_ACTIONS,
    path: '/app/core/general/general-inbox/inbox-actions'
  },
  {
    code: MENU_CODES.CORE.ARCHIVE_INBOX_TASK,
    path: '/app/core/general/general-inbox/archive-inbox-task'
  },
  {
    code: MENU_CODES.CORE.EMPLOYEE_REQUEST_LOCK,
    path: '/app/core/general/general-inbox/employee-request-lock'
  },
  {
    code: MENU_CODES.CORE.TRANSFER_ALLOWANCES,
    path: '/app/core/workforce/transferpolicy/allowances'
  },
  {
    code: MENU_CODES.CORE.EKYC,
    path: '/app/core/integaration/ekyc'
  },
  {
    code: MENU_CODES.CORE.CTC_FORMULA_TAGS,
    path: '/app/core/payroll/paygroup/ctc-formula'
  },
  {
    code: MENU_CODES.CORE.OUT_OF_CTC_ALLOWANCES,
    path: '/app/core/payroll/paygroup/ctc-allowances'
  },
  {
    code: MENU_CODES.CORE.PERFORMANCE_METHODOLOGY,
    path: '/app/core/performance/goal/performance-methodology'
  },
  {
    code: MENU_CODES.CORE.UOM,
    path: '/app/core/performance/goal/uom'
  },
  {
    code: MENU_CODES.CORE.PERSPECTIVE,
    path: '/app/core/performance/goal/perspective'
  },
  {
    code: MENU_CODES.CORE.REVIEW_RATING,
    path: '/app/core/performance/review/review-rating'
  },
  {
    code: MENU_CODES.CORE.COMPETENCY_RATING,
    path: '/app/core/performance/review/competency-rating'
  },
  {
    code: MENU_CODES.CORE.PIP_REASON,
    path: '/app/core/performance/improvement-plan/pip-reason'
  },
  {
    code: MENU_CODES.CORE.INVENTORY_FIELDS,
    path: '/app/core/asset/inventory'
  },
  {
    code: MENU_CODES.CORE.PROJECT_HEALTH,
    path: '/app/core/project-management/project-health'
  },
  {
    code: MENU_CODES.CORE.PROJECT_MILESTONE,
    path: '/app/core/project-management/milestone'
  },
  {
    code: MENU_CODES.CORE.INVOICE_DYNAMIC_FIELDS,
    path: '/app/core/project-management/invoice-dynamic-fields'
  },
  {
    code: MENU_CODES.CORE.TASK_TYPE,
    path: '/app/core/project-management/task-type'
  },
  {
    code: MENU_CODES.CORE.TASK_FIELDS,
    path: '/app/core/project-management/task-fields'
  },
  {
    code: MENU_CODES.CORE.RATE_CARD,
    path: '/app/core/project-management/rate-card'
  },
  {
    code: MENU_CODES.CORE.JOB_OPENING_FORM,
    path: '/app/core/recruitment/job-opening-form'
  },
  {
    code: MENU_CODES.CORE.JOB_OPENING_TAT,
    path: '/app/core/recruitment/job-opening-tat'
  },
  {
    code: MENU_CODES.CORE.CARRER_PORTAL,
    path: '/app/core/recruitment/carrer-portal'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_BUDGET,
    path: '/app/core/recruitment/recruitment-budget'
  },
  {
    code: MENU_CODES.CORE.LINKEDIN_INTEGRATION,
    path: '/app/core/recruitment/linkedin-integration'
  },
  {
    code: MENU_CODES.CORE.EXPENSE_FORM,
    path: '/app/core/expense/expense-form'
  },
  {
    code: MENU_CODES.CORE.TRAVEL_PLAN_FORM,
    path: '/app/core/expense/travel-plan-form'
  },
  {
    code: MENU_CODES.CORE.ASSET_POLICY,
    path: '/app/core/asset/asset-policy'
  },
  {
    code: MENU_CODES.CORE.CELEBRATION_SETUP,
    path: '/app/core/engagesetting/celebrationsetup'
  },
  {
    code: MENU_CODES.CORE.ENGAGE_APP_VIEW,
    path: '/app/processes/search?moduleId=20&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.WORKFORCE_APP_VIEW,
    path: '/app/processes/search?moduleId=1&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.TIMEOFFICE_APP_VIEW,
    path: '/app/processes/search?moduleId=2&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.PAYROLL_APP_VIEW,
    path: '/app/processes/search?moduleId=3&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.PERFORMANCE_APP_VIEW,
    path: '/app/processes/search?moduleId=4&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.EXPENSE_APP_VIEW,
    path: '/app/processes/search?moduleId=5&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.HELPDESK_APP_VIEW,
    path: '/app/processes/search?moduleId=6&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.RECRUITMENT_APP_VIEW,
    path: '/app/processes/search?moduleId=9&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.WORKPLAN_APP_VIEW,
    path: '/app/processes/search?moduleId=23&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.ASSET_APP_VIEW,
    path: '/app/processes/search?moduleId=7&redirectTo=0&openedFrom=pin'
  },
  {
    code: MENU_CODES.CORE.STATUTORY_TEMPLATE,
    path: '/app/core/payroll/statutorytemplate'
  },
];


export const analyticsRouting: NavObject[] = [
  {
    code: MENU_CODES.ANALYTICS.REPORTS,
    path: '/app/processes/reports'
  },
  {
    code: MENU_CODES.ANALYTICS.HAPPINESSINDEX,
    path: '/app/analytics/happinessindex'
  },
  {
    code: MENU_CODES.ANALYTICS.ROI,
    path: '/app/analytics/returnoninvestment'
  },
  {
    code: MENU_CODES.ANALYTICS.EMPLOYEEMOVEMENT,
    path: '/app/analytics/employeemovement'
  },
  {
    code: MENU_CODES.ANALYTICS.EMPLOYEEEFFICIENCY,
    path: '/app/analytics/employeeefficiency'
  },
  {
    code: MENU_CODES.ANALYTICS.PAYROLL,
    path: '/app/analytics/payroll'
  },
  {
    code: MENU_CODES.ANALYTICS.HELPDESK,
    path: '/app/analytics/helpdesk'
  },
  {
    code: MENU_CODES.ANALYTICS.ENGAGE,
    path: '/app/analytics/engage'
  },
  {
    code: MENU_CODES.ANALYTICS.PERFORMANCE,
    path: '/app/analytics/performance'
  },
  {
    code: MENU_CODES.ANALYTICS.NINE_BOX,
    path: '/app/analytics/ninebox'
  },
  {
    code: MENU_CODES.ANALYTICS.PULSE,
    path: '/app/analytics/pulse'
  },
  {
    code: MENU_CODES.ANALYTICS.SURVEY,
    path: '/app/analytics/survey'
  },
  {
    code: MENU_CODES.ANALYTICS.EXPENSE,
    path: '/app/analytics/expense'
  },
  {
    code: MENU_CODES.ANALYTICS.TIMEOFFICE,
    path: '/app/analytics/cxo-timeoffice'
  },
  {
    code: MENU_CODES.ANALYTICS.RECRUITMENT,
    path: '/app/analytics/recruitment'
  },
  {
    code: MENU_CODES.CORE.BGV_INTEGRATION,
    path: '/app/core/integaration/bgv'
  },
  {
    code: MENU_CODES.CORE.CANDIDATE_ASSESSMENT,
    path: '/app/core/integaration/candidate_assessment'
  },
  {
    code: MENU_CODES.CORE.DIGITAL_SIGNATURE,
    path: '/app/core/integaration/digitalsignature'
  },
  {
    code: MENU_CODES.CORE.SCIM_INTEGARATION,
    path: '/app/core/integaration/scim_integaration'
  },
  {
    code: MENU_CODES.CORE.CREATE_JV,
    path: '/app/core/integaration/journal-voucher/create-journal-voucher'
  },
  {
    code: MENU_CODES.CORE.JV_CONFIGURE_OU,
    path: '/app/core/integaration/journal-voucher/journal-voucher-ou'
  },
  {
    code: MENU_CODES.CORE.JV_CONFIGURE_EMPLOYEE,
    path: '/app/core/integaration/journal-voucher/journal-voucher-employee'
  },
  {
    code: MENU_CODES.CORE.BELL_CURVE,
    path: '/app/core/performance/performance/bell-curve'
  },
  {
    code: MENU_CODES.CORE.NINE_BOX,
    path: '/app/core/performance/performance/nine-box'
  },
  {
    code: MENU_CODES.CORE.INITIATIVE_TYPE,
    path: '/app/core/performance/initiative/initiative-type'
  },
  {
    code: MENU_CODES.CORE.API_KEY_MANAGEMENT,
    path: '/app/core/integaration/api-key-management'
  },
];

export const profileRouting: NavObject[] = [
  {
    code: MENU_CODES.PROFILE.PROFILE,
    path: '/app/myprofile/profile'
  },
  {
    code: MENU_CODES.PROFILE.LETTER,
    path: '/app/myprofile/letters'
  },
  {
    code: MENU_CODES.PROFILE.POLICY,
    path: '/app/myprofile/policies'
  },
  {
    code: MENU_CODES.PROFILE.PAYSLIP,
    path: '/app/myprofile/payslip'
  },
  {
    code: MENU_CODES.PROFILE.BADGESANDREWARDS,
    path: '/app/myprofile/my-badges-and-rewards'
  },
  {
    code: MENU_CODES.PROFILE.GOALSANDINITIATIVES,
    path: '/app/myprofile/my-goals-and-initiatives'
  },
  {
    code: MENU_CODES.PROFILE.CALENDAR,
    path: '/app/myprofile/calendar'
  },
  {
    code: MENU_CODES.PROFILE.LEAVEBALANCE,
    path: '/app/myprofile/myleave-balance'
  },
  {
    code: MENU_CODES.PROFILE.PERFORMANCE,
    path: '/app/myprofile/my-performance'
  },
  {
    code: MENU_CODES.PROFILE.HOLIDAY,
    path: '/app/myprofile/my-holiday'
  },
  {
    code: MENU_CODES.PROFILE.CTC,
    path: '/app/myprofile/my-ctc'
  },
  {
    code: MENU_CODES.CORE.SINGLE_SIGN_ON,
    path: '/app/core/workforce/single-sign-on'
  },
  {
    code: MENU_CODES.PROFILE.clearance,
    path: '/app/myprofile/clearance'
  },
  {
    code: MENU_CODES.PROFILE.GEO_LOCATION,
    path: '/app/myprofile/geo-location'
  },
  {
    code: MENU_CODES.CORE.PASSWORD_POLICY,
    path: '/app/core/general/security/password-protected'
  },

  {
    code: MENU_CODES.PROFILE.JOB_OPENINGS,
    path: '/app/myprofile/jobopening'
  },
  {
    code: MENU_CODES.CORE.PRODUCTROAD_MAP,
    path: '/app/core/general/productroadmap'
  },
  {
    code: MENU_CODES.PROFILE.TRAVEL_TICKETS,
    path: '/app/myprofile/tickets'
  },
  {
    code: MENU_CODES.PROFILE.ASSETS,
    path: '/app/myprofile/asset'
  },
  // NEW MENUS FOR PROFILE
  {
    code: MENU_CODES.PROFILE.GOAL_HISTORY,
    path: '/app/myprofile/goal/history',
  },
  {
    code: MENU_CODES.PROFILE.EXPENSE_ADVANCE,
    path: '/app/myprofile/expenseadvance/search',
  },
  {
    code: MENU_CODES.PROFILE.EXPENSE_REPORT,
    path: '/app/myprofile/expensereceipt/search',
  },
  {
    code: MENU_CODES.PROFILE.TRAVEL,
    path: '/app/myprofile/travel/search',
  },
  {
    code: MENU_CODES.PROFILE.CONFIRM_INVESTMENT_HISTORY,
    path: '/app/myprofile/coninvestment/history',
  },
  {
    code: MENU_CODES.PROFILE.DISPENSE_LOAN,
    path: '/app/myprofile/loan/search',
  },
  {
    code: MENU_CODES.PROFILE.FBP_APPROVAL_HISTORY,
    path: '/app/myprofile/fbphistory/search',
  },
  {
    code: MENU_CODES.PROFILE.LOAN_HISTROY,
    path: '/app/myprofile/loanhistory/history',
  },
  {
    code: MENU_CODES.PROFILE.CONFIRMATION,
    path: '/app/myprofile/confirmation/search',
  },
  {
    code: MENU_CODES.PROFILE.EXIT_INTERVIEW,
    path: '/app/myprofile/exitinterview/search',
  },
  {
    code: MENU_CODES.PROFILE.AR,
    path: '/app/myprofile/attendanceregularization/search',
  },
  {
    code: MENU_CODES.PROFILE.ATTENDANCE_YTD,
    path: '/app/myprofile/attendanceytd/user',
  },
  {
    code: MENU_CODES.PROFILE.OD,
    path: '/app/myprofile/onduty/search',
  },
  {
    code: MENU_CODES.PROFILE.LEAVE,
    path: '/app/myprofile/leave/search',
  },
  {
    code: MENU_CODES.PROFILE.SHORT_LEAVE,
    path: '/app/myprofile/shortleave/search',
  },
  {
    code: MENU_CODES.PROFILE.CO,
    path: '/app/myprofile/compoff/search',
  },
  {
    code: MENU_CODES.PROFILE.LOCATION_APPROVAL,
    path: '/app/myprofile/locationapproval/search',
  },
  {
    code: MENU_CODES.PROFILE.MARK_ATTENDANCE,
    path: '/app/myprofile/markattendancesearch/search',
  },
  {
    code: MENU_CODES.PROFILE.OVERTIME,
    path: '/app/myprofile/overtime/search',
  },
  {
    code: MENU_CODES.PROFILE.SHIFT,
    path: '/app/myprofile/shift/user',
  },
  {
    code: MENU_CODES.PROFILE.ASSEST_REQUEST,
    path: '/app/myprofile/assetrequest/search',
  },
  {
    code: MENU_CODES.PROFILE.PIP,
    path: '/app/myprofile/pip/search',
  },
  {
    code: MENU_CODES.PROFILE.PIP_EVALUATION,
    path: '/app/myprofile/pipevaluation/search',
  },
  {
    code: MENU_CODES.PROFILE.HELPDESK,
    path: '/app/myprofile/helpdesk/search',
  },
  {
    code: MENU_CODES.PROFILE.POSTION_ASSIGEND,
    path: '/app/myprofile/positionassigned/search',
  },
  {
    code: MENU_CODES.PROFILE.REIMBURSEMENT_HISTORY,
    path: '/app/myprofile/reimbursementhistory/history',
  },
  {
    code: MENU_CODES.PROFILE.SALARY_YTD,
    path: '/app/myprofile/salaryytd/search',
  },
  {
    code: MENU_CODES.PROFILE.VARIABLE_HISTORY,
    path: '/app/myprofile/variable/search',
  },
  {
    code: MENU_CODES.PROFILE.PROPOSED_INVESTMENT_HISTORY,
    path: '/app/myprofile/proinvestment/history',
  },
  {
    code: MENU_CODES.PROFILE.PROPOSED_INVESTMENT_HISTORY,
    path: '/app/myprofile/proinvestment/history',
  },
  {
    code: MENU_CODES.PROFILE.RESTRICTED_HOLIDAY,
    path: '/app/myprofile/restrictedholiday/search',
  },
  {
    code: MENU_CODES.PROFILE.SOCIAL_PROFILE,
    path: '/app/myprofile/social-profile',
  },
  {
    code: MENU_CODES.PROFILE.BENEFITS,
    path: '/app/myprofile/benefits',
  },
  {
    code: MENU_CODES.PROFILE.EMPLOYEE_OFFER,
    path: '/app/myprofile/employeeoffer'
  }
];
export const onBoardingProcessRouting: NavObject[] = [
  {
    code: MENU_CODES.CORE.ONBOARDING_PROCESS_CUSTOMER,
    path: "/app/onboarding"
  }
]
export const setUpRouting: NavObject[] = [
  {
    code: MENU_CODES.DASHBOARD.SETUP,
    path: '/product-management/setup'
  },
  {
    code: MENU_CODES.SETUP.USER,
    path: '/product-management/setup/user'
  },
  {
    code: MENU_CODES.SETUP.ANNOUNCEMENT,
    path: '/product-management/setup/announcement'
  },
  {
    code: MENU_CODES.SETUP.PAGE_ACCESS_RIGHTS,
    path: '/product-management/setup/page-access'
  },
  {
    code: MENU_CODES.SETUP.EVENT,
    path: '/product-management/setup/event'
  },
  {
    code: MENU_CODES.SETUP.HR_NPS,
    path: '/product-management/setup/hr-nps'
  },
  {
    code: MENU_CODES.SETUP.EMPLOYEE_NPS,
    path: '/product-management/setup/employee-nps'
  },
]

export const menuNavigationModel = concat(onBoardingProcessRouting, processRouting, dashboardRouting, coreRouting,
  teamRouting, requestRouting, analyticsRouting, profileRouting);

export const superUserMenuNavigationModel = concat(productManagementDashboardRouting, setUpRouting);
